import './index.css';
import '../../App.css';
import Grid from "@mui/material/Grid2";
import { Box} from "@mui/material";
import PrimaryFooter from '../../component/X1kFooter';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { faChevronDown, faChevronRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react';
import { useState } from 'react';

const Support = () => {
    const data = [
        // { id: 1, show: false, title: 'Nếu in hết số token ra thì sẽ lấy đâu ra token để trả thưởng?1', content: 'iCloud là dịch vụ do Apple cung cấp nhằm lưu trữ ảnh, tệp, ghi chú, mật khẩu và các dữ liệu khác một cách an toàn trên đám mây. Dịch vụ này sẽ tự động cập nhật trên tất cả các thiết bị của bạn. Ngoài ra, iCloud cũng giúp bạn dễ dàng chia sẻ ảnh, tệp, ghi chú, v.v. với bạn bè và gia đình. Bạn cũng có thể dùng iCloud để sao lưu iPhone, iPad hoặc iPod touch của mình1' },
        // { id: 2, show: false, title: 'Nếu in hết số token ra thì sẽ lấy đâu ra token để trả thưởng?2', content: 'iCloud là dịch vụ do Apple cung cấp nhằm lưu trữ ảnh, tệp, ghi chú, mật khẩu và các dữ liệu khác một cách an toàn trên đám mây. Dịch vụ này sẽ tự động cập nhật trên tất cả các thiết bị của bạn. Ngoài ra, iCloud cũng giúp bạn dễ dàng chia sẻ ảnh, tệp, ghi chú, v.v. với bạn bè và gia đình. Bạn cũng có thể dùng iCloud để sao lưu iPhone, iPad hoặc iPod touch của mình2' },
        // { id: 3, show: false, title: 'Nếu in hết số token ra thì sẽ lấy đâu ra token để trả thưởng?3', content: 'iCloud là dịch vụ do Apple cung cấp nhằm lưu trữ ảnh, tệp, ghi chú, mật khẩu và các dữ liệu khác một cách an toàn trên đám mây. Dịch vụ này sẽ tự động cập nhật trên tất cả các thiết bị của bạn. Ngoài ra, iCloud cũng giúp bạn dễ dàng chia sẻ ảnh, tệp, ghi chú, v.v. với bạn bè và gia đình. Bạn cũng có thể dùng iCloud để sao lưu iPhone, iPad hoặc iPod touch của mình3' },
        // { id: 4, show: false, title: 'Nếu in hết số token ra thì sẽ lấy đâu ra token để trả thưởng?4', content: 'iCloud là dịch vụ do Apple cung cấp nhằm lưu trữ ảnh, tệp, ghi chú, mật khẩu và các dữ liệu khác một cách an toàn trên đám mây. Dịch vụ này sẽ tự động cập nhật trên tất cả các thiết bị của bạn. Ngoài ra, iCloud cũng giúp bạn dễ dàng chia sẻ ảnh, tệp, ghi chú, v.v. với bạn bè và gia đình. Bạn cũng có thể dùng iCloud để sao lưu iPhone, iPad hoặc iPod touch của mình4' },
        // { id: 5, show: false, title: 'Nếu in hết số token ra thì sẽ lấy đâu ra token để trả thưởng?5', content: 'iCloud là dịch vụ do Apple cung cấp nhằm lưu trữ ảnh, tệp, ghi chú, mật khẩu và các dữ liệu khác một cách an toàn trên đám mây. Dịch vụ này sẽ tự động cập nhật trên tất cả các thiết bị của bạn. Ngoài ra, iCloud cũng giúp bạn dễ dàng chia sẻ ảnh, tệp, ghi chú, v.v. với bạn bè và gia đình. Bạn cũng có thể dùng iCloud để sao lưu iPhone, iPad hoặc iPod touch của mình5' },

    ];

    const [shapes, setShapes] = useState(data);
    const showContent = (step, active) => () => {
        const nextShapes = shapes.map(shape => {
            if (shape.id === step) {
                shape.show = active === 1 ? true : false;
                return shape;
            } else {
                shape.show = false
                return shape;
            }
        });
        // Re-render with the new array
        setShapes(nextShapes);
    };

    const searchContent = (event) => {
        const search = event.target.value;
        let nextShapes = data;
        setShapes(nextShapes);
        if (search) {
            nextShapes = shapes.filter(x => x.title.includes(search) || x.content.includes(search));
        }
        setShapes(nextShapes); // Update state with the current input value
    };
    const [inputValue, setInputValue] = useState(null);


    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', color: '#fff', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-sup">
                <Box className="content-sup">
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" >
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                <Box sx={{ fontSize: '55px', fontWeight: '500' }}>Chào bạn,</Box>
                                <Box sx={{ fontSize: '55px', fontWeight: '500' }}>Chúng tôi có thể giúp gì cho bạn?</Box>
                                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                    <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" size={8}>
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', backgroundColor: '#101012', borderRadius: '50px', color: '#fff' }}
                                        >
                                            <IconButton type="button" sx={{ p: '10px', color: '#fff' }} aria-label="search" >
                                                <SearchIcon />
                                            </IconButton>
                                            <InputBase
                                                sx={{ ml: 1, flex: 1, color: '#fff' }}
                                                placeholder="Tìm kiếm vấn đề"
                                                inputProps={{ 'aria-label': 'Tìm kiếm vấn đề' }}
                                                value={inputValue}
                                                onChange={searchContent}
                                            />

                                        </Paper>
                                    </Grid>
                                    <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                        </Grid>
                        <Grid display="flex" justifyContent="left" alignItems="left" size={8}>
                            <Box sx={{ padding: '40px', width: '100%' }}>
                                <Box sx={{ fontSize: '35px', fontWeight: '400' }}>Câu hỏi thường gặp</Box>
                                <Box sx={{ marginTop:'20px' }}>
                                    {shapes.map(item => (
                                        <Box key={item.id} className="item-support" >
                                            <Grid container spacing={2}>
                                                <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                                    {item.title}

                                                </Grid>
                                                <Grid display="flex" justifyContent="left" alignItems="left"></Grid>
                                                <Grid display="flex" justifyContent="right" alignItems="right" size="grow">
                                                    {item.show === true ? (
                                                        <Box sx={{ cursor: 'pointer' }} onClick={showContent(item.id, 0)}><FontAwesomeIcon icon={faChevronDown} /></Box>
                                                    ) : (
                                                        <Box sx={{ cursor: 'pointer' }} onClick={showContent(item.id, 1)}><FontAwesomeIcon icon={faChevronRight} /></Box>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {item.show === true ? (
                                                <Box className='text-dark-gray' sx={{ marginTop: '10px', fontSize: '15px' }}>{item.content}</Box>
                                            ) : (
                                                <Box></Box>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '75%', backgroundColor: '#01122c', borderRadius: '15px' }}>
                            <Grid container spacing={2} sx={{ padding: '30px' }}>
                                <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                    <Box >
                                        <Box sx={{ fontSize: '30px', marginTop: '30px', width: '100%' }}>Can’t find what you’re looking for?</Box>
                                        <Box display="flex" justifyContent="left" alignItems="center">
                                        <Box sx={{ fontSize: '20px', width: '37%', backgroundColor: '#0091ff', borderRadius: '15px', padding:'10px 20px', marginTop:'20px'}}>
                                            Contact us  
                                            <FontAwesomeIcon icon={faArrowRight} style={{marginLeft:'10px',fontSize: '25px'}}/>
                                        </Box>
                                        </Box>
                                       
                                    </Box>
                                </Grid>
                                <Grid display="flex" justifyContent="center" alignItems="center">
                                </Grid>
                                <Grid display="flex" justifyContent="right" alignItems="right" size="grow">
                                    <Box component="img" alt='mail icon' src={`${process.env.PUBLIC_URL}/images/mail-icon.png`} />

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            <PrimaryFooter />
        </Box>
    )
}

export default Support