import {
  Box, Toolbar, Stepper, Step, TextField, FormControlLabel, FormGroup, Checkbox, Stack, Button, StepButton,
  Typography, ButtonGroup
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faArrowRight, faClone } from "@fortawesome/free-solid-svg-icons";
import "./deposit.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import CountdownTimer from './CountdownTimer'
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { ethers, JsonRpcProvider, BrowserProvider } from "ethers";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { faX } from "@fortawesome/free-solid-svg-icons";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { format } from 'date-fns';
import api_key from '../../instance_api_key';
import api_no_auth from '../../instance_api_no_authentication';
import Modal from '@mui/material/Modal';
import { useSnackbar } from '../SnackbarProvider';

const styleModel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const steps = [
  'Information',
  'Payment',
];

const currency = [
  { id: 1, name: 'ETH', img_url: '/images/Ethereum-logo.png', isChoose: true, isDisabled: 0 },
  { id: 2, name: 'USDC', img_url: '/images/usdc-logo.png', isChoose: false, isDisabled: 1 },
  { id: 3, name: 'USDT', img_url: '/images/usdt-logo.png', isChoose: false, isDisabled: 1 },
  { id: 4, name: 'X1K', img_url: '/images/flag-Europe.png', isChoose: false, isDisabled: 1 },
]

const wallet_address_ss = localStorage.getItem('wallet_address');

const Deposit = () => {
  const showSnackbar = useSnackbar();

  const [activeStep, setActiveStep] = React.useState(0);
  const [activeBalance, setActiveBalance] = React.useState([]);
  const [checkbox1, setCheckbox1] = React.useState(false);
  const [checkbox2, setCheckbox2] = React.useState(false);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);

  const [userNameInput, setUserNameInput] = useState("");
  const [gmailInput, setGmailInput] = useState("");
  const [linkXInput, setLinkXInput] = useState("");
  const [farcasterInput, setFarcasterInput] = useState("");

  const [fee, setFee] = useState(0);
  const [oldFee, setOldFee] = useState(0);
  const [sale, setSale] = useState(0);
  const [balance, setBalance] = useState(0);
  const [ethPrice, setEthPrice] = useState(null);
  const [usdtToEth, setUsdtToEth] = useState(0);
  const [isEndTime, setIsEndTime] = useState(false);

  const [activeCurrency, setActiveCurrency] = React.useState(currency);

  const [txHash, setTxHash] = useState(null);


  const handleuserNameInputChange = (event) => {
    setUserNameInput(event.target.value);
  };
  const handlegmailInputChange = (event) => {
    setGmailInput(event.target.value);
  };
  const handlelinkXInputChange = (event) => {
    setLinkXInput(event.target.value);
  };
  const handlefarcasterInputChange = (event) => {
    setFarcasterInput(event.target.value);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleBalance = (balance) => {
    const data = activeBalance.map((item) => {
      if (item.id == balance.id) {
        item.isChoose = !item.isChoose;
        if (item.isChoose == true) {
          setBalance(item.balance);
          setFee(item.fee_amt);
          setOldFee(item.old_fee_amt);
          setSale(item.sale);
          setUsdtToEth((item.fee_amt / Number(ethPrice)).toFixed(5));

        } else {
          setBalance(0);
          setFee(0);
          setOldFee(0);
          setSale(0);
          setUsdtToEth(0);
        }
      } else {
        item.isChoose = false;
      }
      return item;
    });
    setActiveBalance(data);
  }


  const handleCurrency = (id) => {
    const data = activeCurrency.map((item) => {
      if (item.id == id) {
        item.isChoose = !item.isChoose;
      } else {
        item.isChoose = false;
      }
      return item;
    });
    setActiveCurrency(data);
  }

  // Get the location object
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);




  const fetchEthPrice = async (fee) => {
    try {
      // Fetch ETH price from CoinGecko API
      const responseBinance = await axios.get(
        'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
      );
      const ethPriceBinance = Number(responseBinance.data.price).toFixed(2);

      const responseOkx = await axios.get(
        'https://www.okx.com/api/v5/market/ticker?instId=ETH-USDT'
      );
      const ethPriceOkx = Number(responseOkx.data.data[0].last).toFixed(2);
      const everage_price = ((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2)
      setEthPrice(everage_price);
      if (fee && fee > 0) {
        setUsdtToEth((fee / Number(everage_price)).toFixed(5));
      }
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };

  useEffect(() => {
    setFee(queryParams.get('fee'));
    setBalance(queryParams.get('balance'));
    fetchEthPrice(null);

    api_no_auth.get(`/api/balance/get_v1_balance/1`)
      .then(response => {
        if (response && response.data.length > 0) {
          response.data.forEach(element => {
            element.isChoose = false;
            if (element.sale > 0) {
              // element.old_balance = element.balance;
              // element.balance = element.balance - (element.balance * element.sale / 100);
              element.old_fee_amt = element.fee_amt;
              element.fee_amt = element.fee_amt - (element.fee_amt * element.sale / 100);
            }
            if (queryParams.get('balance') && queryParams.get('balance') == element.balance) {
              element.isChoose = true;
              setOldFee(element.old_fee_amt);
              setFee(element.fee_amt);
              setSale(element.sale);
              fetchEthPrice(element.fee_amt); 
            }
          });
        }
        setActiveBalance(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  const [tx_dt, setTxDt] = useState(null);

  // Function to send Sepolia ETH
  const sendTransaction = async () => {
    if (!window.ethereum) {
      alert('MetaMask is required!');
      return;
    }
    const tempProvider = new BrowserProvider(window.ethereum);
    const network = tempProvider && await tempProvider.getNetwork();
    const chainid = network.chainId.toString()
    if (network.name != 'base-sepolia' && chainid != "1301") {
      showSnackbar('Please switch to Base Sepolia or Unichain Sepolia', 'warning');
      return;
    }

    try {
      setStatus(2); //pending

      // Connect to MetaMask
      const provider = new ethers.BrowserProvider(window.ethereum);
      await provider.send('eth_requestAccounts', []); // Request account access
      const signer1 = await provider.getSigner();

      // Create transaction object
      const tx = {
        to: '0x16b303859738cfade46b00ff6457b923de75ce53',
        value: ethers.parseEther(usdtToEth), // Convert ETH to wei
      };

      // Send the transaction
      const transactionResponse = await signer1.sendTransaction(tx);
      setTxHash(transactionResponse.hash); // Store the transaction hash
      console.log('Transaction sent:', transactionResponse);

      setTxDt(format(new Date(), 'dd/MM/yyyy'));
      api_key.post('/api/transaction/post_v1_transaction',
        {
          tx_id: transactionResponse.hash,
          user_adddress: wallet_address_ss,
          balance: parseInt(balance),
          currency: 'ETH',
          fee: parseInt(fee),
          user_name: userNameInput,
          gmail: gmailInput,
          link_x: linkXInput,
          link_farcaster: farcasterInput,
          type: 1,
          tx_dt: new Date(),
          status: 0
        })
        .then(response => {
          // Optionally, redirect or update the UI
        })
        .catch(error => console.error(error));

      // Wait for transaction confirmation
      const receipt = await transactionResponse.wait();
      api_key.post('/api/transaction/post_v1_transaction_update_status',
        {
          tx_id: transactionResponse.hash,
          status: receipt.status
        })
        .then(response => {
          if (response && response.data && response.data.valid == true) {
            //Tạo tài khoản cấp vốn
            api_key.post('/api/account/post_v1_account',
              {
                balance: balance,
                status: 1,
                account_type: 1,
                wallet_address: wallet_address_ss
              })
              .then(response => {

              })
              .catch(error => console.error(error));
          }
        })
        .catch(error => console.error(error));
      if (receipt.status === 1) {
        setStatus(1);
      } else {
        setStatus(0);
      }
      console.log('Transaction confirmed:', receipt);
    } catch (error) {
      console.error('Error sending transaction:', error);

      if (error.action == 'estimateGas') {
        showSnackbar('The fee balance is insufficient to execute the order.', 'warning');
        setStatus(0);
        return;
      }
    }
  };

  const handleChangeCheckbox1 = (event) => {
    setCheckbox1(event.target.checked)
  };
  const handleChangeCheckbox2 = (event) => {
    setCheckbox2(event.target.checked)
  };

  const handleTimeChange = (Childdata) => {
    setIsEndTime(Childdata);
    if (Childdata == true) {
      fetchEthPrice(fee);
      setIsEndTime(false);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
  };

  const provider = new JsonRpcProvider('https://eth-sepolia.g.alchemy.com/v2/DsV1HF92uDr_nVZqSnhhQGdM3o6pTIGG'); // Replace with your Infura or RPC URL
  // const getTransactionStatus = async () => {
  //   console.log("checking tx...");
  //   if (txHash != null && txHash != "") {
  //     // setLoading(true);
  //     try {
  //       const tx = await provider.getTransactionReceipt(txHash);

  //       if (tx) {
  //         if (tx.status === 1) {
  //           console.log('Success');
  //           setStatus(1);
  //         } else {
  //           console.log('Failure');
  //           setStatus(0);
  //         }
  //       } else {
  //         console.log('Transaction not found or still pending');
  //         setStatus(0);
  //       }
  //     } catch (error) {
  //       console.log('Error fetching transaction');
  //       setStatus(0);
  //     }
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (loading == true) {
  //     const intervalId = setInterval(() => {
  //       getTransactionStatus()
  //     }, 10000);
  //     return () => clearInterval(intervalId);
  //   }
  // }, [loading]);

  //--->model
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //<---

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 7, lg: 9 }}>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
            <Box sx={{ width: '100%' }}>
              <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton color="inherit">
                      {label}
                    </StepButton>
                    {/* <StepLabel>{label}</StepLabel> */}
                  </Step>
                ))}
              </Stepper>
            </Box>
            {activeStep == 0 ? (
              <Box sx={{ width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                  <h3>Account Balance</h3>
                  <div style={{ display: 'flex' }}>
                    {
                      activeBalance.map((balance, index) => (
                        <Box position="relative" display="inline-block" key={balance.id}>
                          {
                            balance.is_popular && <Typography
                              variant="caption"
                              sx={{
                                zIndex: 1,
                                position: 'absolute',
                                top: '-15px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                background: 'linear-gradient(90deg, orange, yellow, green, blue)',
                                color: 'white',
                                padding: '3px 8px',
                                borderRadius: '15px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                letterSpacing: '0.5px',
                              }}
                            >
                              POPULAR
                            </Typography>
                          }
                          <Button
                            onClick={() => {
                              handleBalance(balance);
                            }}
                            className={`${balance.isChoose ? 'balance-item-choose' : 'balance-item'}`}
                            key={balance.id} variant="outlined"
                          >
                            {/* {balance.sale > 0 ?
                              <Box>
                                <del style={{fontSize:'12px'}}>{balance.old_balance}</del>
                                <Box>{balance.balance}</Box>
                              </Box>
                              : 
                              <span>{balance.balance}</span>
                            } */}
                            <span>{formatCurrency(balance.balance)}</span>
                          </Button>
                          {/* {balance.sale > 0  && <Box className="imgSale" component="img" alt='img sale' src={`${process.env.PUBLIC_URL}/images/sale.png`} />} */}
                        </Box>
                      ))
                    }
                  </div>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <h3>Payment Curency</h3>
                  <p>The Account Curency can't be change later</p>
                  <div style={{ display: 'flex' }}>
                    {activeCurrency.map(item => (
                      <Button
                        key={item.id}
                        disabled={item.isDisabled === 1}
                        onClick={() => {
                          handleCurrency(item.id);
                        }}
                        className={`${item.isChoose ? 'payment-currency-item-choose' : 'payment-currency-item'}`}
                        variant="outlined">
                        <img style={{ width: '20px', height: '20px', marginRight: '5px' }} src={`${process.env.PUBLIC_URL + item.img_url}`} />
                        <strong>{item.name}</strong>
                      </Button>
                    ))}
                    <TextField
                      label="Discount code"
                      id="outlined-size-small"
                      defaultValue=""
                      size="small"
                      disabled
                    />
                    {/* <div className="payment-currency-item" style={{ border: '1px dotted #3E63DD' }}>
                      <strong style={{ color: '#3E63DD' }}>MÃ GIẢM GIÁ</strong>
                    </div> */}

                  </div>
                  <br />
                  <div style={{ display: 'flex' }}>
                    <div className="payment-currency-item">
                      <img style={{ width: '20px', height: '20px' }} src={`${process.env.PUBLIC_URL}/images/metamask-logo.png`} />
                      <p style={{ margin: '0px 5px' }}>Metamask</p>
                      <strong>{wallet_address_ss}</strong>
                    </div>
                    <div className="payment-currency-item">
                      <p style={{ margin: '0px 5px' }}>PLatform</p>
                      <strong>WEB3TRADE</strong>
                    </div>
                    <div className="payment-currency-item">
                      <p style={{ margin: '0px 5px' }}>Account Type (Leverage 1:100)</p>
                      <strong>X1K</strong>
                    </div>
                  </div>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <h3>Billing infomation</h3>
                  {/* <p>Before you start tradding for us, we need to know some basic information about you</p>
                  <p>(Thông tin này sẽ được xác minh ở vòng live)</p>
                  <Box
                    component="form"
                    sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField id="outlined-basic" label="Tên người dùng" variant="outlined" value={userNameInput} onChange={handleuserNameInputChange} />
                    <TextField id="outlined-basic" label="Gmail" variant="outlined" value={gmailInput} onChange={handlegmailInputChange} />
                    <TextField id="outlined-basic" label="Link X" variant="outlined" value={linkXInput} onChange={handlelinkXInputChange} />
                    <TextField id="outlined-basic" label="Link farcaster" variant="outlined" value={farcasterInput} onChange={handlefarcasterInputChange} />
                  </Box> */}
                </Box>
                <Box sx={{ width: '100%' }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={checkbox1} onChange={handleChangeCheckbox1} name="checkbox1" />} label={<span>I declare that I have read and agree with <span className="underline-text" onClick={handleOpen}>Terms & Condittions</span></span>} />
                    <FormControlLabel control={<Checkbox checked={checkbox2} onChange={handleChangeCheckbox2} name="checkbox2" />} label="I declare that I have read and agree with Cancellation & Refund Policy" />
                  </FormGroup>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModel}>
                      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                        <Box >
                          <Grid container spacing={2}>
                            <Grid display="flex" justifyContent="center" alignItems="center" size={11.5} sx={{ padding: '20px 0', fontSize: '30px' }}>
                              X1K GENERAL TERMS AND CONDITIONS
                            </Grid>
                            <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                              <span className="close-icon-terms" onClick={handleClose}>
                                &times;
                              </span>
                            </Grid>
                          </Grid>
                        </Box>
                      </Typography>
                      <Typography id="modal-modal-description" className="popup-content-terms" >
                        <Box sx={{ p: 2 }}>
                          <Box >
                            <Box className='title-terms'>
                              #1 INTRODUCTION
                            </Box>
                            <Box>
                              1.1. These terms govern your rights and obligations (“You,” “Customer”) concerning your use of the services provided by X1Kfunded.finance.
                            </Box>
                            <Box>
                              1.2. By registering on our website, or in cases where registration is not required, no later than your first use of the services, you are entering into a contract with the provider to access and utilize the selected services. These terms are an inseparable part of that contract, and by entering into the contract, you indicate your agreement to these terms.
                            </Box>
                            <Box>
                              1.3. Services are only available to individuals over the age of 18 who reside in countries where our services are permitted. By registering on the website, you confirm that you are at least 18 years old. If you are under 18, you are not permitted to use the services. You agree to access the services only from a country where they are available. You acknowledge that accessing and using the services may be restricted or prohibited under the laws of certain countries, and you agree to comply with all applicable laws in your use of the services.
                            </Box>
                            <Box>
                              1.4. The provider will not offer services to any customer who: (i) holds citizenship or resides in restricted regions; (ii) is incorporated or registered, or has a registered office in restricted regions; (iii) is subject to applicable international sanctions; or (iv) has a criminal record related to financial crimes or terrorism. Restricted regions are defined by the provider and published on the website. The provider reserves the right to refuse, restrict, or terminate services under this provision, and such customers are prohibited from using the services, including the customer portal and/or trading platform.
                            </Box>
                            <Box>
                              1.5. The services include tools for simulated trading in the crypto market or simulated trading with other instruments on financial markets, analytical tools, training and educational materials, access to the customer portal, and other support services, accessible particularly through the customer portal or applications provided by the provider or third parties. The financial market information used in simulated trading is for reference; however, you acknowledge that any trading conducted through the services is not real. You also acknowledge that the funds provided for simulated trading are virtual, and you do not own these virtual funds beyond their use within the services. Specifically, they are not to be used for any real transactions, and you have no claim to cash out these funds. Unless otherwise agreed, you will not receive any compensation or profits based on your simulated trading results, nor are you responsible for any losses.
                            </Box>
                            <Box>
                              1.6. None of the services provided to you by the provider shall be construed as investment services under applicable law. The provider does not offer advice or guidance on how to conduct transactions or provide information about any traded investment instruments. No employee, staff member, or representative of the provider is authorized to give investment advice or recommendations. If any statements by provider personnel are interpreted as investment advice or recommendations, the provider explicitly disclaims that interpretation and assumes no liability for it.
                            </Box>
                            <Box>
                              1.7. Your personal data is processed in accordance with our Privacy Policy.
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box>
                              <Box className='title-terms'>
                                #2 REGISTRATION PROCESS
                              </Box>
                              <Box>
                                2.1. To access the platform and use our services, you must create an account and provide accurate, valid, and up-to-date information.
                              </Box>
                              <Box>
                                2.2. User Verification Process: After completing the assessment phase and to become a funded user, you must verify your account (the “User Verification Process”). We may initiate this process at any time at our discretion. The timing, steps, and requirements of the verification process are determined by security requirements, particularly anti-money laundering regulations, which may vary depending on legal frameworks and technological developments. During verification, we may request a video interview, identification documents, additional information, and any necessary documents to complete the verification, including checks to ensure you have not abused the system or passed the assessment phase fraudulently or in violation of trading rules.
                              </Box>
                              <Box>
                                2.3. Accurate and Complete Information: You confirm that any documents and information you provide to us are valid, up-to-date, accurate, and complete and that you are not withholding any information that could impact the verification process (e.g., you confirm that you are not withholding information that could trigger further due diligence). If there are any changes to this information, you are responsible for providing the updated information to us as soon as possible after the change.
                              </Box>
                              <Box>
                                2.4. We may request specific information from you during the process of becoming a funded user and from the moment you become a funded user, as required by law, regulations, or regulatory guidance (e.g., anti-money laundering or sanction measures).
                              </Box>
                              <Box>
                                2.5. After successfully registering an account, you may log in to the platform and access the services.
                              </Box>
                              <Box>
                                2.6. There is no guarantee that any individual will successfully complete the verification process and thereby become a funded user, even if that individual has provided all information requested by us. Reasons for this may include but are not limited to legal restrictions or commercial factors. We reserve the right to decline further steps in the verification process, in which case the individual cannot complete verification successfully.
                              </Box>
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #3 PAYMENT TERMS
                            </Box>
                            <Box>
                              <Box>
                                3.1. Service Fees
                              </Box>
                              <Box sx={{ paddingLeft: '20px' }}>
                                The fees for funded account options are calculated in cryptocurrency as specified on our website. If you choose a currency other than the primary currency, the fee will be converted to the primary currency at the exchange rate provided by your payment service provider.
                                Service fees include all applicable taxes. Customers are responsible for fulfilling their tax obligations related to the use of our services as required by applicable laws. If there are any tax obligations, customers are responsible for paying taxes or any other fees on time.
                              </Box>
                            </Box>
                            <Box>
                              <Box>
                                3.2. Payment Methods
                              </Box>
                              <Box sx={{ paddingLeft: '20px' }}>
                                You may pay the fee for your selected funded account option in cryptocurrency or using other payment methods currently available on our website.
                              </Box>
                            </Box>
                            <Box>
                              3.3. If paying by cryptocurrency, we will issue you an electronic provisional invoice with the fee amount for the funded account option you selected on the website. You commit to paying this amount within the specified period in the provisional invoice. The fee is considered paid once the full amount is credited to our account. If you fail to pay on time, we reserve the right to cancel your order.
                            </Box>
                            <Box>
                              3.4. Users are responsible for any fees charged by their selected payment service provider (as per the provider’s current fee schedule) related to the transaction, and it is the user's responsibility to ensure that the appropriate fee for the selected funded account option is fully paid.
                            </Box>
                            <Box>
                              <Box>
                                3.5. Links to Third-Party Websites
                              </Box>
                              <Box sx={{ paddingLeft: '20px' }}>
                                If you are redirected to a third-party website to complete a transaction, your purchase will be governed by the terms of that third-party website. If you choose to provide credit card or debit card information, personal data, or financial information to any third-party provider or linked site, you do so at your own risk and discretion.
                                We recommend that you carefully review the terms and privacy policies of any third-party provider before providing your information. We are not responsible for the information you provide to any third party or for how that third party uses the information.
                              </Box>
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box>
                              <Box className='title-terms'>
                                #4 RULES OF DEMO TRADING
                              </Box>
                            </Box>
                            <Box>
                              4.1. During demo trading on the Trading Platform, you may execute any trades unless they involve strategies or methods prohibited under clause 4.4. You also agree to follow the standard rules and best practices in financial market trading (e.g., risk management rules). Additional restrictions may apply depending on the Trading Platform’s trading conditions you have chosen.
                            </Box>
                            <Box>
                              4.2. You acknowledge that the Provider has access to information about your demo trades on the Trading Platform. You consent to the Provider sharing this information with individuals/entities within the Provider’s group or with related organizations, granting the Provider and these individuals/entities authorization to process this information at their discretion. You agree that these activities may occur automatically without further consent, reference, or approval from you, and you have no right to claim any compensation or revenue regarding the Provider’s use of the data. The Provider acknowledges that you do not provide any investment advice or recommendations through your demo trades. You confirm that you may pause your demo trading on the Trading Platform at any time.
                            </Box>
                            <Box>
                              4.3. The Provider is not responsible for information displayed on the Trading Platform or for any interruptions, delays, or inaccuracies in the market information shown via your Customer Area.
                            </Box>
                            <Box>
                              4.4. PROHIBITED TRADING PRACTICES
                              <Box sx={{ paddingLeft: '20px' }}>
                                4.4.1. During demo trading, it is prohibited to:
                                <ul style={{ margin: '0' }}>
                                  <li>Use any trading strategy that exploits errors in services, such as price display errors or update delays</li>
                                  <li>Execute trades using external or delayed data sources</li>
                                  <li>Engage in transactions, either alone or in collaboration with others, including between linked accounts or accounts belonging to other X1K entities, with the intent to manipulate trading (e.g., taking opposite positions simultaneously)</li>
                                  <li>Conduct trades in violation of the Provider’s or Trading Platform’s terms and conditions</li>
                                  <li>Use any software, artificial intelligence, high-speed tools, or mass data input that could manipulate, abuse, or give you an unfair advantage in using our system or services</li>
                                  <li>
                                    Engage in gap trading by opening trades:
                                    <Box sx={{ paddingLeft: '20px' }}>
                                      <Box>
                                        (I) During significant global news, macro events, or financial or earnings reports (“EVENTS”) that may affect relevant financial markets (i.e., markets where affected financial instruments are traded);
                                      </Box>
                                      <Box>
                                        (II) 2 hours or less before a relevant financial market closes for 2 hours or longer; or
                                      </Box>
                                    </Box>
                                  </li>
                                  <li>Execute trades in ways that deviate from actual trading practices on the foreign exchange or other financial markets or in ways that cause reasonable concerns that the Provider could suffer financial or other losses due to the Customer’s activities (e.g., excessive leverage, high frequency, one-directional bets, account rolling).</li>

                                </ul>

                              </Box>
                              <Box sx={{ paddingLeft: '20px' }}>
                                4.4.2. As our Customer, you must understand and agree that all our services are for your personal use only, meaning only you personally may access your X1K Challenge and Verification accounts and execute trades. Therefore, you may not, and agree not to:
                                <ul style={{ margin: '0' }}>
                                  <li>Allow any third party to access and trade on your X1K Challenge and Verification account or cooperate with any third party to allow them to trade for you, regardless of whether the third party is a private or professional entity;</li>
                                  <li>Access any third-party X1K Challenge and Verification account, trade on behalf of any third party, or perform any account management or similar services where you agree to trade, operate, or manage X1K Challenge and Verification accounts on behalf of another user, whether as a professional or not.</li>
                                </ul>
                                Please note that if you act or behave contrary to these regulations, we will consider such action/behavior a Prohibited Trading Practice under clause 4.4 with corresponding consequences.
                                <Box></Box>
                              </Box>
                              <Box sx={{ paddingLeft: '20px' }}>
                                4.4.3. Furthermore, Customers may not exploit services by conducting trades without applying standard market risk management rules in financial market trading, including, but not limited to:
                                <ul style={{ margin: '0' }}>
                                  <li>Opening positions that are significantly larger than the Customer’s other trades, whether on this account or any of the Customer’s other accounts,</li>
                                  <li>Opening position sizes that are significantly smaller or larger than the Customer’s other trades, whether on this account or any other account of the Customer.</li>
                                </ul>
                                <Box>
                                  The Provider has sole discretion in determining whether specific trades, practices, strategies, or situations constitute Prohibited Trading Practices.
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              4.5. If the Customer engages in any Prohibited Trading Practices described in clause 4.4:
                              <ul style={{ margin: '0' }}>
                                <li>The Provider may deem it a failure to meet the conditions of the specific X1K Challenge or Verification</li>
                                <li>The Provider may remove violating trades from the Customer’s trade history and/or exclude their results from profit and/or loss achieved in demo trading</li>
                                <li>Immediately cease providing all services to the Customer and subsequently terminate this contract</li>
                                <li>Reduce leverage provided on products to 1:5 on any or all Customer accounts</li>
                              </ul>
                            </Box>
                            <Box>
                              4.6. If one or more Prohibited Trading Practices occur on one or more of a Customer’s X1K Challenge and Verification accounts, or across multiple Customers’ accounts, or by combining trading across X1K Challenge and Verification accounts and X1K Trader accounts, the Provider reserves the right to cancel all services and terminate all contracts related to any and all of the Customer’s X1K Challenge and Verification accounts and/or apply other measures under clause 4.5. The Provider may take any and all actions specified in clauses 4.5 and 4.6 at its discretion.
                            </Box>
                            <Box>
                              4.7. If any X1K Trader account is used for or associated with Prohibited Trading Practices, it may and will violate the respective terms and conditions for the X1K Trader account with the third-party provider and may result in the cancellation of all accounts of that user and termination of corresponding agreements by the third-party provider.
                            </Box>
                            <Box>
                              4.8. If the Customer engages in any practice described in clause 4.4 multiple times and the Provider has previously notified the Customer, the Provider may prevent the Customer from accessing all or part of the services, including access to the Customer Area and Trading Platform, without any compensation. In such cases, the Customer is not entitled to a refund of any fees paid.
                            </Box>
                            <Box>
                              4.9. The Provider is not responsible for any other trading or investment activities.
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #5 REFUND
                            </Box>
                            <Box>
                              The evaluation fee is non-refundable once you begin the above-mentioned Assessment trading activity. As long as no trading activity has occurred and you have not violated these Terms, you may request a refund within 7 days from the purchase date.
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #6 RESTRICTED TERRITORIES
                            </Box>
                            6.1 RESTRICTED TERRITORIES:
                            <Box sx={{ paddingLeft: '20px' }}>
                              The funding program does not offer services to residents of the following countries:
                              <ul style={{ margin: '0' }}>
                                <li>United States</li>
                                <li>Canada</li>
                                <li>Australia</li>
                                <li>Iran</li>
                                <li>North Korea</li>
                                <li>Other countries with strict financial regulations or that are restricted by the fund’s policies.</li>
                              </ul>
                            </Box>
                            <Box>
                              6.2 Residency Confirmation Requirement:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Users must confirm that they are not residing in any restricted territory when registering for an account.
                                <Box>
                                  The fund reserves the right to request information and documentation verifying a user’s residence, which may include, but is not limited to:
                                </Box>
                                <ul style={{ margin: '0' }}>
                                  <li>Utility bills</li>
                                  <li>Bank statements</li>
                                  <li>Government-issued identification with a residential address</li>
                                </ul>
                              </Box>
                            </Box>
                            <Box>
                              6.3 Compliance Check and Violation Reporting Policy:
                              <Box sx={{ paddingLeft: '20px' }}>
                                The fund reserves the right to conduct checks and verify user residency both before and after account registration.
                                <Box>
                                  If it is determined that a user resides in a restricted territory, the fund reserves the right to:
                                </Box>
                                <ul style={{ margin: '0' }}>
                                  <li>Terminate the user’s account without a refund of any fees.</li>
                                  <li>Notify the user of the account cancellation and provide specific reasons for this action.</li>
                                </ul>
                              </Box>
                            </Box>
                            <Box>
                              6.4 Violation Sanctions:
                              <Box sx={{ paddingLeft: '20px' }}>
                                In cases of violation of this clause, the fund reserves the right to impose the following measures:
                                <ul style={{ margin: '0' }}>
                                  <li>Temporarily suspend the account until verification is completed.</li>
                                  <li>Cancel any transactions and profits generated from the violating account.</li>
                                </ul>
                              </Box>
                            </Box>
                            <Box>
                              6.5 Risk Warning:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Users should understand that participating in financial trading activities from restricted territories may result in serious legal and financial risks.
                              </Box>
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #7 DATA SOURCES
                            </Box>
                            <Box>
                              All pricing indicators and data provided for this demo trading service are sourced from reputable exchanges, including Binance, OKX, Gate, and dYdX. While we strive to provide accurate and timely information on the website, this information may not always be precise, complete, up-to-date, or free from technical inaccuracies.
                            </Box>
                            <Box>
                              To continue providing comprehensive and accurate information, updates or changes may be made periodically without notice. You are responsible for verifying all information prior to reliance, and any decisions made based on information on the website or app are your responsibility. We shall not be liable for such decisions.
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #8 Disclaimers from Us
                            </Box>
                            <Box>
                              8.1 Service Availability:
                              <ul style={{ margin: '0' }}>
                                <li>
                                  Our service is provided “as is” without any warranties, including the assurance of continuous, error-free, or secure service. You assume full responsibility for using the service.
                                </li>
                              </ul>
                            </Box>
                            <Box>
                              8.2 Disclaimer of Warranties:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We and our affiliates make no representations or warranties regarding the accuracy, completeness, timeliness, safety, or reliability of the service or content. We disclaim any liability for:
                                <ul style={{ margin: '0' }}>
                                  <li>Damages to your computer system or loss of data resulting from the use of the service.</li>
                                  <li>The failure to store or transmit any content successfully.</li>
                                  <li>Any security vulnerabilities arising from malware or other flaws.</li>
                                </ul>
                              </Box>
                            </Box>
                            <Box>
                              8.3 Advice and Information:
                              <Box sx={{ paddingLeft: '20px' }}>
                                No information, whether oral or written, from us or our affiliates creates any warranty. You acknowledge that you rely solely on the warranties expressly stated in these terms.
                              </Box>
                            </Box>
                            <Box>
                              8.4 Legal Compliance:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We make no representation that using the service is lawful in any jurisdiction, and you are solely responsible for ensuring compliance with local laws.
                              </Box>
                            </Box>
                            <Box>
                              8.5 Non-Broker Statement:
                              <Box sx={{ paddingLeft: '20px' }}>
                                You agree that we do not act as a broker or conduct securities transactions on your behalf, nor do we provide any financial advice.
                              </Box>
                            </Box>
                            <Box>
                              8.6 Investment Disclaimer:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Information provided is not intended as investment advice and may not align with your financial objectives. You should consult a financial advisor before making any investment decisions.
                              </Box>
                            </Box>
                            <Box>
                              8.7 Indemnity and Liability Limitation:
                              <Box sx={{ paddingLeft: '20px' }}>
                                In cases where our liability is established, the maximum indemnity will not exceed the amount you paid for the related service.
                              </Box>
                            </Box>
                            <Box>
                              8.8 Modification Rights:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We reserve the right to alter or modify any aspect of the service without prior notice and without liability.
                              </Box>
                            </Box>
                            <Box>
                              8.9 Force Majeure:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We shall not be liable for service disruption due to events beyond our control, including but not limited to natural disasters, war, pandemics, and other emergencies.
                              </Box>
                            </Box>
                          </Box>
                          <br></br>
                          <Box>
                            <Box className='title-terms'>
                              #9 Limitation of Liability
                            </Box>
                            <Box>
                              9.1. Company Liability:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Under no circumstances shall the Company, including its parent companies, subsidiaries, partners, affiliates, employees, agents, officers, and directors, be liable to you for any indirect, incidental, special, punitive, exemplary, or consequential damages, including but not limited to lost profits, lost or corrupted data, lost opportunities, or financial or property losses, even if you or anyone else has advised us of the possibility of such damages.
                              </Box>
                            </Box>
                            <Box>
                              9.2. Third-Party Claims:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We are not liable for any claims from third parties regarding access to, use of, or the content on our website or services, or any linked sites, or reliance on any tools, features, information, or content related to our website or services, regardless of any legal or equitable theory under which such claims are made.
                              </Box>
                            </Box>
                            <Box>
                              9.3. Limitation of Liability:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Unless otherwise required by law, our maximum liability for any claim, whether based on contract, warranty, legal statute, or any other form, for any damages or losses arising from, relating to, or resulting from these terms, shall not exceed the amount you paid to us in the prior month for our services or $39, whichever is greater.
                              </Box>
                            </Box>
                            <Box>
                              9.4. Exemption from Liability:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We are not responsible if the website or services are unavailable due to technical or operational reasons beyond our control, including but not limited to force majeure events or legal obligations imposed by government authorities.
                              </Box>
                            </Box>
                            <Box>
                              9.5. Governing Law:
                              <Box sx={{ paddingLeft: '20px' }}>
                                Because certain jurisdictions do not permit exclusions or limitations of liability for indirect or incidental damages, the above liability limitations may not apply to you. In such jurisdictions, our liability will be limited to the maximum extent permitted by law.
                              </Box>
                            </Box>
                            <Box>
                              9.6. Right to Modify:
                              <Box sx={{ paddingLeft: '20px' }}>
                                We reserve the right to change, modify, or remove any portion of the website and/or services at any time without compensation.
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Typography>
                    </Box>
                  </Modal>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack justifyContent="center" alignItems="center">
                    <Button
                      onClick={handleStep(1)}
                      disabled={!checkbox1 || !checkbox2}
                      variant="contained" sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}>
                      Confirm & Proceed to payment &nbsp;
                      <FontAwesomeIcon icon={faArrowRight} />

                    </Button>
                  </Stack>
                </Box>
              </Box>
            ) :
              (<Box sx={{ width: '100%' }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <CountdownTimer onTimeChange={handleTimeChange} />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <img src={`${process.env.PUBLIC_URL}/images/qr-payment.png`} />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <p>{usdtToEth} ETH <FontAwesomeIcon icon={faClone} /></p>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Stack justifyContent="center" alignItems="center">
                    <ButtonGroup variant="contained" aria-label="Basic button group">
                      <Button variant="contained"
                        sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}
                        onClick={handleStep(0)}>
                        <ArrowBackIcon />&nbsp;Back</Button>
                      <Button variant="contained"
                        sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}
                        onClick={sendTransaction} disabled={status == 2}>
                        <img src={`${process.env.PUBLIC_URL}/images/Ethereum-logo.png`} /> Pay with ETH</Button>
                      {/* <Button variant="contained"
                        sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}
                        onClick={getTransactionStatus}>
                        <PublishedWithChangesIcon />&nbsp;Kiểm tra</Button> */}
                    </ButtonGroup>

                  </Stack>
                </Box>
              </Box>)
            }
          </Box>
        </Grid>
        <Grid item size={{ xs: 12, md: 5, lg: 3 }}>
          {activeStep == 0 ? (
            <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
              <div className="circle-box">
                <FontAwesomeIcon icon={faMoneyBill} size="2xl" />
              </div>
              <h3>Estimated Total amount</h3>
              <p style={{ color: ' #6F6F6F' }}>
                The total amount will change when you make a selection
              </p>
              <div>
                <div className="total_money">
                  {fee != null &&
                    // <h1>${fee}</h1>
                    <Box>
                      {sale > 0 ?
                        <Box>
                          <Box className="imgSale" component="img" alt='img sale' src={`${process.env.PUBLIC_URL}/images/sale.png`} />
                          <del style={{ fontSize: '16px', color: 'rgba(0, 0, 0, .26)' }}>${oldFee}</del>
                          <h1 style={{ margin: 0 }}>${fee}</h1>
                        </Box>

                        :
                        <h1>${fee}</h1>
                      }
                    </Box>
                  }

                </div>
              </div>
            </Box>
          ) : (
            <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
              <Box sx={{ width: '100%' }}>
                <img src={`${process.env.PUBLIC_URL}/images/Circle.png`} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <h3>Order details</h3>
                <p>{tx_dt}</p>
              </Box>
              {/* <Box sx={{ width: '100%' }}>
                txHash: {txHash}
              </Box> */}
              <Box sx={{ width: '100%' }}>
                {status == 0 && <div className="ord-status-fail">
                  Unpaid
                </div>}
                {status == 1 && <div className="ord-status-success">
                  Paid
                </div>}
                {status == 2 && <div className="ord-status-pending">
                  Pending ...
                </div>}
              </Box>
              <Box sx={{ width: '100%' }}>
                <div>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Order number</Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>20213022</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Account Size</Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>USD {balance}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Account Type</Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>X1K</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Platform</Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>WEB3</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Trading Account Currency</Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>USD</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}><h3>TOTAL</h3></Grid>
                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}><h3>USD {fee}</h3></Grid>
                  </Grid>
                </div>
              </Box>
            </Box>
          )
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default Deposit;
