import { useState, useEffect } from 'react';
import "./popupnoti.css"; // Optional: Create a CSS file for styling the popup
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ethers, BrowserProvider } from 'ethers';
import { useSnackbar } from '../SnackbarProvider';




const PopupNoti = ({ id, title, content, date, handleClose }) => {




    return (
        <div className="popup-box-noti">
            <div className="box-noti">
                <Box sx={{ padding: '20px' }}>
                    <Grid container spacing={2} sx={{ borderBottom: '#a0a0a0 solid 1px', fontSize: '20px', paddingBottom: '10px' }}>
                        <Grid display="flex" justifyContent="left" alignItems="center" size="auto">
                            <Box >
                                {title}
                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center">
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <Box >
                                {date}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='popup-content' sx={{ padding: '20px' }}>
                    <Box sx={{ borderBottom: '#a0a0a0 solid 1px', fontSize: '20px', paddingBottom: '40px' }}>
                        <Box>{content}</Box>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" size={9}>
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="center" size="grow" sx={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#3e63dd', color: '#fff', fontSize: '20px', borderRadius: '10px', cursor: 'pointer' }} onClick={handleClose}>
                            <Box>
                                Close
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
};

export default PopupNoti;
