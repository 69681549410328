import './index.css';
import '../../App.css';
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMinimize, faCheck, faInfoCircle, faMessage } from "@fortawesome/free-solid-svg-icons";
import PrimaryFooter from '../X1kFooter';
import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";
import api_no_auth from '../../instance_api_no_authentication';
import api from '../../instance_api';

const EvaluationProcess = () => {
    const navigate = useNavigate();
    const Support = (step) => () => {
        navigate("/support");
    };
    let isEffectExecuted = false;
    useEffect(() => {
        if (!isEffectExecuted) {
            isEffectExecuted = true;
            getEvaluation();
        }

    }, []);
    let dataEvalua = []
    const [evalua, setShowEvalua] = useState(dataEvalua);
    const getEvaluation = () => {
        api_no_auth.get(`/api/evaluationProcess/get_v1_evaluationProcess/evaluation`)
            .then(response => {
                if (response && response.data.length > 0) {
                    let index = 1;
                    response.data.forEach(element => {
                        element.title = "Round " + index;
                        index++;
                    });
                    setShowEvalua(response.data);
                }
            })
            .catch(error => console.error(error));
    }
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };

    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
      setIsVisible(!isVisible); // Đổi trạng thái giữa true và false
    };
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', color: '#fff', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-upgrade">
                <Box className="content-upgrade">
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="left" alignItems="left" size="grow">
                            <Box className="content-upgrade-img" component="img" alt='bitcoin' src={`${process.env.PUBLIC_URL}/images/bitcoin.png`} />
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" sx={{ zIndex: '10' }}>
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                {/* <Box sx={{ fontSize: '55px', fontWeight: '500' }}>Để nhận được tài khoản 2-step</Box>
                                <Box sx={{ fontSize: '55px', fontWeight: '500' }}>bạn cần trải qua 2 vòng đánh giá</Box> */}
                                <Box className="choose-upgrade-title" sx={{ fontSize: '55px', fontWeight: '500' }}>Two Rounds Evaluation Phase</Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box className="trading-days">Trading days reset based on GMT+8 timezone</Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="right" size="grow">
                            <Box className="content-upgrade-img" component="img" alt='etherium' src={`${process.env.PUBLIC_URL}/images/etherium.png`} />
                        </Grid>

                    </Grid>

                    {/* <Box className="support-btn" onClick={Support()}>
                        <FontAwesomeIcon icon={faMessage} />
                    </Box> */}
                    <Box className='main-content' sx={{ padding: '60px' }}>
                        <Grid sx={{ padding: '40px 0' }}>
                            <Grid container spacing={3} columns={12}>
                                {evalua.map(item => (
                                    <Grid key={item.id} size={{ xs: 12, md: 6 }} sx={{ border: '#5b5b5b solid 1px', borderRadius: '20px', backgroundColor: '#000' }}>
                                        <Grid container spacing={2} columns={16} sx={{ padding: '20px' }}>
                                            <Grid size={10}>
                                                <Box className="content-process-title" sx={{ fontSize: '30px', color: '#fff', marginTop: '20px' }}>{item.title}</Box>
                                            </Grid>
                                            <Grid size={6} display="flex" justifyContent="right" alignItems="right">
                                                <Box className='content-process-img' component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/${item.img}`} />
                                            </Grid>
                                            <Grid size={10}>
                                                <Box className='content-process-target' sx={{ fontSize: '20px', color: '#fff', marginTop: '10px' }}>Profit target</Box>
                                            </Grid>
                                            <Grid size={6} display="flex" justifyContent="right" alignItems="right">
                                                <Box className='content-process-rate' sx={{ padding: '10px', backgroundColor: '#0091ff', borderRadius: '15px', color: '#fff', fontWeight: 'Bold', fontSize: '20px' }}>{item.profit_target}</Box>
                                            </Grid>
                                            <Grid size={16} sx={{ borderTop: '#5b5b5b solid 1px' }}>
                                                <Box>
                                                    <div className='content-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Minimum 3 days with <span className="text-blue">{item.minimum_profit}%</span> profit on total funded account</div>
                                                </Box>
                                                <Box>
                                                    <div className='content-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Daily drawdown limit <span className="text-blue">{item.max_loss_per_day}%</span></div>
                                                </Box>
                                                <Box>
                                                    <div className='content-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Overall funded account drawdown limit: <span className="text-blue">{item.limit}%</span></div>
                                                </Box>
                                                <Box>
                                                    <div className='content-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> No limit on trading days</div>
                                                </Box>
                                                <Box>
                                                    <div className='content-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Single profitable trade should account for no more than <span className="text-blue" >{item.total_profit}%</span> of total profit</div>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Box>
                            <Box className="payout-container-evaluation">
                                <Box ><h2 style={{ margin: '0' }}>Example: </h2></Box>
                                <Grid container spacing={1}>
                                    <Grid size={10}>
                                        <div className="payout-text text-dark-gray ">
                                            <p>If you register for the $100K funding package, the minimum profit target of 0.5% equates to $500. Drawdown limits are based on the starting balance at the beginning of the day, reset daily to GMT+8.</p>
                                            <Box>Daily 5% Drawdown Fixed Limits:  </Box>
                                            <Box>- 5% of a $100K package is $5,000</Box>
                                            <Box>- 5% of a $20K package is $1,000</Box>
                                            <p>Note: When the day’s Equity = Beginning Balance, it means no positions were carried overnight.</p>
                                        </div>
                                    </Grid>
                                    <Grid size={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="payout-image">
                                            <Box component="img" alt='Verify Level Image' src={`${process.env.PUBLIC_URL}/images/VerifyLevel.png`} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="payout-container-evaluation">
                                <Box ><h2 style={{ margin: '0' }}>Scenarios: </h2></Box>
                                <Grid container spacing={1}>
                                    <Grid size={10}>
                                        <div className="payout-text text-dark-gray ">
                                            <Box sx={{ marginBottom: '15px' }}>
                                                <Box>1. Equity = Balance = $100K:  </Box>
                                                <Box sx={{ marginLeft: '15px' }}>Starting with $100K, Equity cannot drop below $95K.</Box>
                                            </Box>
                                            <Box sx={{ marginBottom: '15px' }}>
                                                <Box>2. Equity = Balance = $105K: </Box>
                                                <Box sx={{ marginLeft: '15px' }}>With $5K profit, Equity cannot drop below $100K.</Box>
                                            </Box>
                                            <Box sx={{ marginBottom: '15px' }}>
                                                <Box>3. Equity = Balance = $98K:  </Box>
                                                <Box sx={{ marginLeft: '15px' }}>With a $2K loss, Equity cannot drop below $93K.</Box>
                                            </Box>
                                            <Box sx={{ marginBottom: '15px' }}>
                                                <Box>4. Equity = $106K, Balance = $100K: </Box>
                                                <Box sx={{ marginLeft: '15px' }}>Carrying an overnight gain of $6K, the day’s drawdown limit is $95K. You cannot incur further losses that drop Equity below $95K.</Box>
                                            </Box>
                                            <Box sx={{ marginBottom: '15px' }}>
                                                <Box>5. Equity = $98K, Balance = $100K: </Box>
                                                <Box sx={{ marginLeft: '15px' }}>Carrying an overnight loss of $2K, the day’s drawdown limit is $95K, meaning further losses cannot drop Equity below $95K.</Box>
                                            </Box>
                                            <Box className={` ${isVisible ? 'scenarios-sub' : 'scenarios-sub-show'} `} >
                                                <p>Inactive accounts will be closed if unused for over 30 days.  </p>
                                                <Box>A 14-day cycle resets upon account expansion. A minimum of $100 in the LIVE account qualifies for payout, refunding participation fees.  </Box>
                                                <Box>LIVE Account:  </Box>
                                                <Box>- After 14 days with a LIVE account, users can request a payout.  </Box>
                                                <Box>- A minimum $100 in trading qualifies for a payout and a refund of participation fees.  </Box>
                                                <Box>- Single profitable trade should account for no more than 40% of total profit </Box>
                                                <br></br>
                                                <Box>Additional Rules:  </Box>
                                                <Box>1. Accounts inactive for over 30 days will be closed.</Box>
                                                <Box>2. The 14-day cycle resets post-payout.</Box>
                                                <Box>3. Cheating behavior that harms the project will result in account closure with a 7-day appeal window.</Box>
                                                <Box>4. Users can trade news events, but high volatility during these times is discouraged.</Box>
                                                <br></br>
                                                <Box>- No all-in trades in LIVE accounts. </Box>
                                                <Box>- Individual trades are limited to a maximum of 40% of total user profits at payout.  </Box>
                                                <Box>- Martingale and hedging strategies are not allowed.</Box>
                                            </Box>
                                            <Box className="see-more"  >
                                                <Box  onClick={toggleVisibility}>
                                                   {isVisible ? "See more..." : "Close"} 
                                                </Box>
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid size={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className="payout-image">
                                            <Box component="img" alt='Verify Level Image' src={`${process.env.PUBLIC_URL}/images/VerifyEmail.png`} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <PrimaryFooter />
        </Box>
    );
}
export default EvaluationProcess
