import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./airdrop.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useState, useEffect } from 'react';

const Airdrop = () => {

    const columns = [
        { field: 'date', headerName: 'Date', minWidth: 200 },
        { field: 'withdraw', headerName: 'Total user withdrawals / total system withdrawals x300,000	', minWidth: 400 },
        { field: 'volume', headerName: 'Total user transaction volume / total system transaction volume x400,000 (2)	', minWidth: 500 },
        { field: 'ref', headerName: 'Total user referrals / total system referrals x300,000 (3)', minWidth: 400 },
    ];

    const rows = [
        {
            id: 1,
            date: '09/07/2024-16/07/2024',
            withdraw: '$90/$10000 x 300,000 = 2700',
            volume: '$10,000/$8,000,000,000 x 400,000 = 500	',
            ref: '3/100 x 400,000 = 9,000',
        },
        {
            id: 2,
            date: '09/07/2024-16/07/2024',
            withdraw: '$90/$10000 x 300,000 = 2700',
            volume: '$10,000/$8,000,000,000 x 400,000 = 500	',
            ref: '3/100 x 400,000 = 9,000',
        },
        {
            id: 3,
            date: '09/07/2024-16/07/2024',
            withdraw: '$90/$10000 x 300,000 = 2700',
            volume: '$10,000/$8,000,000,000 x 400,000 = 500	',
            ref: '3/100 x 400,000 = 9,000',
        },
        {
            id: 4,
            date: '09/07/2024-16/07/2024',
            withdraw: '$90/$10000 x 300,000 = 2700',
            volume: '$10,000/$8,000,000,000 x 400,000 = 500	',
            ref: '3/100 x 400,000 = 9,000',
        },
        {
            id: 5,
            date: '09/07/2024-16/07/2024',
            withdraw: '$90/$10000 x 300,000 = 2700',
            volume: '$10,000/$8,000,000,000 x 400,000 = 500	',
            ref: '3/100 x 400,000 = 9,000',
        },

    ];
    const paginationModel = { page: 0, pageSize: 5 };
    const [walletAddress, setWalletAddress] = useState("");
    useEffect(() => {
        const fullData = rows;
        setWalletAddress(localStorage.getItem('wallet_address'));
    }, []);
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Airdrop
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <div style={{ display: 'flex' }}>
                        <div className="airdrop-item1">
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/images/airdrop-icon1.png`} />
                            </div>
                            <div>Total weekly points</div>
                            <div style={{ fontWeight: 'bold', fontSize: '36px' }}>1,000,0000</div>
                            <div style={{ color: '#46A758' }}>= 100%</div>
                        </div>
                        <div className="airdrop-item1">
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/images/airdrop-icon2.png`} />
                            </div>
                            <div>Total weekly user points withdrawn</div>
                            <div style={{ fontWeight: 'bold', fontSize: '36px' }}>300,0000</div>
                            <div style={{ color: '#46A758' }}>~ 30%</div>
                        </div>
                        <div className="airdrop-item1">
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/images/airdrop-icon3.png`} />
                            </div>
                            <div>User points with transaction volume for the week</div>
                            <div style={{ fontWeight: 'bold', fontSize: '36px' }}>400,0000</div>
                            <div style={{ color: '#46A758' }}>~ 40%</div>
                        </div>
                        <div className="airdrop-item1">
                            <div>
                                <img src={`${process.env.PUBLIC_URL}/images/airdrop-icon4.png`} />
                            </div>
                            <div>User points with referrals for the week</div>
                            <div style={{ fontWeight: 'bold', fontSize: '36px' }}>300,0000</div>
                            <div style={{ color: '#46A758' }}>~ 30%</div>
                        </div>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <span>Total user points. = </span>
                        <span style={{ fontWeight: 'bold' }}>(2,700 + 500 + 9,000) =  12,200</span>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <div style={{ display: 'flex', border: '2px solid #ececec', padding: '14px', borderRadius: '17px' }}>
                        <div style={{ width: '50%', display: 'flex', padding: '9px 12px', background: '#ececec', borderRadius: '8px', alignItems: 'center' }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                                    <img style={{ width: '20px', height: '20px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/metamask-logo.png`} />
                                   MetaMask wallet address
                                </div>
                                <div style={{ fontWeight: 'bold', width: '50%', display: 'flex', justifyContent: 'flex-end' }}>{walletAddress}</div>
                            </div>
                        </div>
                        <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div style={{ fontSize: '18px', marginRight: '10px' }}>Total point = </div>
                            <div style={{ fontWeight: 'bold', fontSize: '20px' }}>(12,200 + 10,000) =  22,200</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Airdrop
