import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/Paid';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import RotateLeft from '@mui/icons-material/RotateLeft';

export const treeData = [
  {
    id: "0",
    label: "Deposit",
    route: "/profile/deposit",
    icon: <FolderIcon sx={{ color: '#6F6F6F' }}/>,
    children: []
  },
  {
    id: "1",
    label: "Accounts",
    route: "/profile/accounts",
    icon: <HomeIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  // {
  //   id: "2",
  //   label: "Account Overview",
  //   route: "/profile/account-overview",
  //   icon: <AccountCircleIcon  sx={{ color: '#6F6F6F' }}/>,
  //   children: [],
  // },
  {
    id: "3",
    label: "Billing",
    route: "/profile/billing",
    icon: <CreditCardIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "4",
    label: "Payout",
    route: "/profile/payout",
    icon: <PaidIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "5",
    label: "REF",
    route: "/profile/ref",
    icon: <TrendingUpIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "6",
    label: "Competition",
    route: "/profile/competition",
    icon: <EmojiEventsIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "7",
    label: "Leaderboard",
    route: "/profile/leaderboard",
    icon: <WorkspacePremiumIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "8",
    label: "Airdrop",
    route: "/profile/airdrop",
    icon: <WorkspacePremiumIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "9",
    label: "Announcements",
    route: "/profile/announcements",
    icon: <NotificationsIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "10",
    label: "Settings",
    route: "/profile/settings",
    icon: <SettingsIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [
        {
          id: "101",
          label: "Settings 1",
          route: "/profile/settings/settings-1",
          icon: <FolderIcon  sx={{ color: '#6F6F6F' }}/>,
          children: [],
        },
      ],
  },
  {
    id: "11",
    label: "Contact Us",
    route: "/profile/contact-us",
    icon: <InfoIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "12",
    label: "Profile",
    route: "/profile/profile",
    icon: <PersonIcon  sx={{ color: '#6F6F6F' }}/>,
    children: [],
  },
  {
    id: "13",
    label: "Request Payout",
    route: "/profile/request-payout",
    icon: <PaidIcon  sx={{ color: '#6F6F6F' }}/>,
  },
  {
    id: "14",
    label: "Ref fee",
    route: "/profile/ref-fee",
    icon: <TrendingUpIcon  sx={{ color: '#6F6F6F' }}/>,
  },
  {
    id: "15",
    label: "Refund fee round 1",
    route: "/profile/refund-fee",
    icon: <RotateLeft  sx={{ color: '#6F6F6F' }}/>,
  },
];

