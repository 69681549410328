import {
  Box, Toolbar, Avatar, Typography, Badge, Paper
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faCalendar, faEyeSlash, faCircleQuestion, faInfo } from "@fortawesome/free-solid-svg-icons";
import './account-overview.style.css'
import { styled } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import { dataset } from './constants'
import { DataGrid } from '@mui/x-data-grid';
import BreadcrumbsNav from "../Breadcrumbs"
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import api from '../../instance_api'
import api_key from '../../instance_api_key'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  },
];

const paginationModel = { page: 0, pageSize: 5 };

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];



const AccountOverview = () => {

  const [acccountId, setAccountId] = useState("");
  const [account, setAccount] = useState(null);
  const navigate = useNavigate();

  // Get the location object
  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let id = queryParams.get('id');
    if(!id) {
      id = sessionStorage.getItem('account');
    }
    GetAccountById(id);
   
  }, []);

  const GetAccountById = async (id) => {
    await api_key.get(`/api/account/get_v1_account_by_id_profile/${id}`)
    .then(response => {
      setAccount(response.data);
    })
    .catch(error => console.error(error));
  }

  const handlePayout = () => {
    navigate("/profile/payout?id=" + queryParams.get('id'));
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
      <Toolbar />
      <BreadcrumbsNav />
      <br></br>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 12, md: 5, lg: 3 }}>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item size={{ xs: 5, md: 3, lg: 3 }}>
                <Badge color="success" variant="dot"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Avatar alt="avatar" src={`${process.env.PUBLIC_URL}/images/avatar.png`} />
                </Badge>
              </Grid>
              <Grid item size={{ xs: 7, md: 9, lg: 9 }}>
                <Grid container spacing={3}>
                  <Grid item size={{ xs: 5, md: 5, lg: 5 }}>
                    <div><b style={{ fontSize: 'larger' }}>Hi, Sam</b></div>
                  </Grid>
                  <Grid item size={{ xs: 7, md: 7, lg: 7 }}>
                    <div className="account-status">{account?.account_type}</div>
                  </Grid>
                </Grid>
                <div>You have an Evaluation Account</div>
              </Grid>
            </Grid>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Initial Balance: $ {account?.balance}
              </div>
              <div>
                <img src={`${process.env.PUBLIC_URL}/images/Vector1.png`} />
                &nbsp;
                Plan Type: Free Trial | 6K
              </div>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Box sx={{ width: '100%' }}>
                <img src={`${process.env.PUBLIC_URL}/images/Vector2.png`} />
                &nbsp;
                Trading Cycle Details
              </Box>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Start date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.created}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>End date</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.end_date}&nbsp;<FontAwesomeIcon icon={faCalendar} /></div>
                </div>
              </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px', padding: '15px', }}>
              <Typography variant="h5" gutterBottom>
                Account Details
              </Typography>
              <Box sx={{ width: '100%' }}>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Login</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.user_name}&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Master Pass</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>{account?.password}&nbsp;<FontAwesomeIcon icon={faEyeSlash} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Metamask Add</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>hkeekhr...&nbsp;<FontAwesomeIcon icon={faClone} /></div>
                </div>
                <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Server</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>WEB3 TRADE</div>
                </div>
                {/* <div className="trading-cycle-item">
                  <div style={{ width: '50%' }}>Refund fee gas</div>
                  <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>30%</div>
                </div> */}
                <div>
                  <Button variant="outlined" onClick={handlePayout}>Payout</Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item size={{ xs: 12, md: 7, lg: 9 }}>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, width: '100%' }}>
            <Typography variant="h5" gutterBottom>
              Stats
            </Typography>
            <Box sx={{ width: '100%' }}>
              <div style={{ display: 'flex' }}>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle1.png`} />
                  </div>
                  <div>Balance</div>
                  <div><h2>$ {account?.balance}</h2></div>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle2.png`} />
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </div>
                  <div>Profit target</div>
                  <div><h2>{account?.profit_target}</h2></div>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle3.png`} />
                  </div>
                  <div>Daily loss left</div>
                  <div><h2>{account?.daily_loss}</h2></div>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle4.png`} />
                  </div>
                  <div>Payout > 10%</div>
                  <div><h2>{account?.payout}</h2></div>
                </div>
                <div className="start-item">
                  <div>
                    <img src={`${process.env.PUBLIC_URL}/images/Circle5.png`} />
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </div>
                  <div>Inactive Days</div>
                  <div><h2>{account?.inactive_days}</h2></div>
                </div>
              </div>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Trading Overview
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              Refreshing in 01:33
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Order number&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      $6,000
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Balance&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      $6,000
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Profitability
                    </div>
                    <div style={{ width: '50%' }}>
                      $0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. Winning Trade&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      $0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. Losing Trade&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      $0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Trades&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      0
                    </div>
                  </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Lots&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Avg. RRR&nbsp;<img src={`${process.env.PUBLIC_URL}/images/info.png`} />
                    </div>
                    <div style={{ width: '50%' }}>
                      0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Win Rate
                    </div>
                    <div style={{ width: '50%' }}>
                      0%
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Loss Rate
                    </div>
                    <div style={{ width: '50%' }}>
                      0%
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Profit Factor
                    </div>
                    <div style={{ width: '50%' }}>
                      0
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                      Best Trade
                    </div>
                    <div style={{ width: '50%' }}>
                      $0
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <LineChart
                dataset={dataset}
                xAxis={[{ dataKey: 'x' }]}
                series={[{ dataKey: 'y' }]}
                height={300}
                margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
                grid={{ vertical: true, horizontal: true }}
              />
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Trading history
              </Typography>
            </Box>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{ pagination: { paginationModel } }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  sx={{ border: 0 }}
                />
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountOverview;
