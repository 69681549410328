import './index.css';
import '../../App.css';
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiamond, faCheck, faInfoCircle, faMessage } from "@fortawesome/free-solid-svg-icons";
import PrimaryFooter from '../../component/X1kFooter';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import api from '../../instance_api';
import api_key from '../../instance_api_key';
import api_no_auth from '../../instance_api_no_authentication';
import { SpaOutlined } from '@mui/icons-material';



const DashBoard = () => {


  const navigate = useNavigate();

  const Support = (step) => () => {
    navigate("support");
  };

  const Home = (step) => () => {
    navigate("/");
  };

  const handleDeposit = (id) => () => {
    const dataRow = exam.find(e => e.id === id);
    let fee = dataRow.fee_amt;
    let balance = dataRow.balance;
    window.open("/profile/deposit?balance=" + balance, '_blank', 'noopener,noreferrer');
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
  };

  let dataExam = []
  const [exam, setShowExam] = useState(dataExam);
  const descriptionRef = useRef(null);
  useEffect(() => {
    getBalance();
    getEvaluation();

    const handleClickOutside = (event) => {
      if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
        setShowDescription(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getBalance = () => {
    api_no_auth.get(`/api/balance/get_v1_balance/1`)
      .then(response => {
        if (response && response.data.length > 0) {

          response.data.forEach(element => {
            element.balanceT = formatCurrency(element.balance);
            element.old_fee = element.fee_amt;
            if (element.sale > 0) {
              element.fee_amt = element.fee_amt - (element.fee_amt * element.sale / 100);
            }
          });
          setShowExam(response.data);
          setOptionTrading(response.data[0].id)
          mapingDataExam(response.data[0])
        }
      })
      .catch(error => console.error(error));
  }
  const [optionTrading, setOptionTrading] = useState("");
  const [bMaxHoleR1, setbMaxHoleR1] = useState("");
  const [bMaxHoleR2, setbMaxHoleR2] = useState("");
  const [bMaxHoleR3, setbMaxHoleR3] = useState("");
  const [bProfitTargetR1, setbProfitTargetR1] = useState("");
  const [bProfitTargetR2, setbProfitTargetR2] = useState("");
  const [bProfitTargetR3, setbProfitTargetR3] = useState("");
  const [bFee, setFee] = useState("");
  const [bSale, setSale] = useState("");
  const [bOldFee, setOldFee] = useState("");
  const changeTrading = (id) => () => {
    setOptionTrading(id);
    const dataRow = exam.find(e => e.id === id);
    mapingDataExam(dataRow);
  };

  const mapingDataExam = (dataRow) => {
    setbMaxHoleR1(formatCurrency(dataRow.max_hole_r1));
    setbMaxHoleR2(formatCurrency(dataRow.max_hole_r2));
    setbMaxHoleR3(formatCurrency(dataRow.max_hole_r3));
    setbProfitTargetR1(formatCurrency(dataRow.profit_target_r1));
    setbProfitTargetR2(formatCurrency(dataRow.profit_target_r2));
    setbProfitTargetR3(formatCurrency(dataRow.profit_target_r3));
    // setFee(formatCurrency(dataRow.fee_amt));
    setSale(dataRow.sale);
    setOldFee('$' + dataRow.old_fee);
    setFee('$' + dataRow.fee_amt);
  };

  let dataEvalua = []
  const [evalua, setShowEvalua] = useState(dataEvalua);
  const getEvaluation = () => {
    api_no_auth.get(`/api/evaluationProcess/get_v1_evaluationProcess/dashboard`)
      .then(response => {
        if (response && response.data.length > 0) {
          let index = 1;
          response.data.forEach(element => {
            element.title = "Round " + index;
            index++;
          });
          setShowEvalua(response.data);
        }
      })
      .catch(error => console.error(error));
  }

  const [showDescription, setShowDescription] = useState(false);
  const [descriptionIconText, setDescriptionIconText] = useState("");
  const [indexIconText, setIndexIconText] = useState(0);
  const toggleDescription = (value, index) => {
    setShowDescription(!showDescription);
    setDescriptionIconText(value);
    setIndexIconText(index);
  };

  const profileDeposit = () => {
    navigate("/profile/deposit");
  };
  return (

    <Box component="main" sx={{ flexGrow: 1, width: '100%', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }} >
      <Box className="background-container" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box className="banner">
          <Box className="content-db">
            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
              <Box className="web3">WEB 3 PROPFIRM. More transparency. More funding.</Box>
            </Grid>

            {/* <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
              <Box className="second-sidebar">Refund of fees upon passing two </Box>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
              <Box className="second-sidebar">challenge rounds</Box>
            </Grid> */}
            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
              <Box className="second-sidebar">From <span className='second-sidebar-sub'>$349</span> to <span className='second-sidebar-sub'>$500K</span> opportunity</Box>
            </Grid>
            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
              <Box className="second-sidebar">with <span className='second-sidebar-sub'>X1K</span>-funded prop trading firm</Box>
            </Grid>
            {/* <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '10px' }} >
              <Box className="third-sidebar text-dark-gray">We are seeking and funding talent in TRADING</Box>
            </Grid> */}

            <Grid container spacing={0} className='top-content' display="flex" justifyContent="space-between">
              <Grid size={2}>
              </Grid>
              <Grid size={14}>
                <Box className="text-trade-top">How it works:</Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} className='second-content'  display="flex" justifyContent="space-between">
              <Grid size={6}>
                <Grid container spacing={0} columns={16}>
                  <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid>
                  <Grid size={14}>
                    <Box className="text-trade-top">1.Register</Box>
                  </Grid>
                  <Grid size={2}>
                  </Grid>
                  <Grid size={14} sx={{ marginTop: '2vh' }} >
                    <Box className="text-trade-down">Starting $27</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={0} columns={16} sx={{ marginTop: '3vh' }}>
                  <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid>
                  <Grid size={14}>
                    <Box className="text-trade-top">2.Trade</Box>
                  </Grid>
                  <Grid size={2}>
                  </Grid>
                  <Grid size={14} sx={{ marginTop: '2vh' }}>
                    <Box className="text-trade-down">Prove your trading skills</Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid size={6}>
                <Grid container spacing={0} columns={16} textAlign="right" >
                  <Grid size={14} sx={{}}>
                    <Box className="text-trade-top">3.Get funded</Box>
                  </Grid>
                  <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid>
                  <Grid size={14} sx={{ marginTop: '2vh' }}>
                    <Box className="text-trade-down">From $5.000 to $500.000</Box>
                  </Grid>
                  <Grid size={2}>
                  </Grid>
                </Grid>
                <Grid container spacing={0} columns={16} textAlign="right" sx={{ marginTop: '3vh' }}>
                  <Grid size={14}>
                    <Box className="text-trade-top" sx={{}}>4.Profit</Box>
                  </Grid>
                  <Grid size={2}>
                    <div style={{ color: '#0091ff', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                  </Grid>
                  <Grid size={14} sx={{ marginTop: '2vh' }}>
                    <Box className="text-trade-down">Earn up to 90% your profit</Box>
                  </Grid>
                  <Grid size={2}>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: '1vh' }}>
              {/* <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                <Box className='left-train'>
                  <div style={{ color: '#0091ff', marginTop: '-10px', marginLeft: '-5px', textAlign: 'end', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                </Box>
              </Grid> */}
              <Button className='ChallengeX1K' onClick={profileDeposit}>
                Challenge x1K
              </Button>

              <Button className='FreeTrial'>
                <Box sx={{ color: '#fff' }}>Free trial</Box>
              </Button>
              {/* <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                <Box className='right-train'>
                  <div style={{ color: '#0091ff', marginTop: '-10px', fontSize: '1vw' }}><FontAwesomeIcon icon={faDiamond} /></div>
                </Box>
              </Grid> */}
            </Grid>
            <Box>
              {/* <Box className="support-btn" onClick={Support()}>
                <FontAwesomeIcon icon={faMessage} />
              </Box> */}
            </Box>

          </Box>
        </Box>

      </Box>

      <Box className="" sx={{ padding: '0 4.17vw' }}>
        <Box className="evaluation-process" sx={{ fontSize: '1.30vw', color: '#fff' }}>Evaluation process</Box>
        <Grid sx={{ padding: '2.08vh 0' }}>
          <Grid container spacing={3} columns={15}>
            {evalua.map(item => (
              <Grid key={item.id} size={{ xs: 15, md: 5 }} sx={{ border: '#5b5b5b solid 1px', borderRadius: '20px' }}>
                <Grid container spacing={2} columns={16} sx={{ padding: '20px' }}>
                  <Grid size={10}>
                    <Box className='evaluation-process-title'>{item.title}</Box>
                  </Grid>
                  <Grid size={6} display="flex" justifyContent="right" alignItems="right" >
                    <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Box className='evaluation-process-img' component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/${item.img}`} />
                    </Box>
                  </Grid>
                  <Grid size={10}>
                    <Box className='evaluation-process-target' sx={{ fontSize: '1.04vw', color: '#fff', marginTop: '10px' }}>Profit target to be achieved</Box>
                  </Grid>
                  <Grid size={6} display="flex" justifyContent="right" alignItems="right">
                    <Box className='evaluation-process-rate' sx={{ padding: '10px', backgroundColor: '#0091ff', borderRadius: '15px', color: '#fff', fontWeight: 'Bold', }}>
                      {
                        item.sale ?
                          <span>
                            <del className='evaluation-process-rate-sub' >{item.profit_target}</del>
                            <span>{item.sale}</span>
                          </span>
                          :
                          <span>{item.profit_target}</span>
                      }
                    </Box>
                  </Grid>
                  <Grid size={16} sx={{ borderTop: '#5b5b5b solid 1px' }}>
                    <Box>
                      <div className='evaluation-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Maximum daily loss
                        <span className="text-blue">{item.max_loss_per_day}%</span>
                      </div>
                    </Box>
                    <Box>
                      <div className='evaluation-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Maximum total loss <span className="text-blue">{item.max_loss}%</span></div>
                    </Box>
                    <Box>
                      <div className='evaluation-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Leverage <span className="text-blue">{item.leverage}</span></div>
                    </Box>
                    <Box>
                      <div className='evaluation-process-content text-dark-gray' style={{ marginTop: '10px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Registration fee paid <span className="text-blue" >{item.fee} time</span></div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Box className="evaluation-process" sx={{ fontSize: '40px', color: '#fff', marginTop: '50px' }}>Funding packages</Box>
        <Box sx={{ fontSize: '15px', color: '#ddd', marginTop: '20px' }}>Balance</Box>
        <Grid container spacing={1.5} sx={{ marginTop: '20px' }} >
          {exam.map(item => (
            <Box key={item.id} onClick={changeTrading(item.id)}>
              <Grid size="auto" className={`balance-btn ${item.id === optionTrading ? '' : 'active'} `} >
                {item.balanceT}
              </Grid>
            </Box>
          ))}
          <Grid size={6}>
          </Grid>
          {/* <Grid size="grow" className='compare-btn' >
            Quick comparison
          </Grid> */}
        </Grid>

        <Grid className="table-funding" container spacing={0} sx={{ paddingBottom: '100px', marginTop: '20px', display: 'flex', textAlign: 'center' }} >
          <Grid sx={{ width: '25%' }} className='step-fisrt'>
            <Box sx={{ height: '120px' }}></Box>
            <Grid container spacing={0} className='step-item-sub text-dark-gray height-step-first' sx={{ height: '120px !important' }}>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>Trading cycle</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-dark-gray pointer" onClick={() => toggleDescription("Trading cycle", 0)} ><FontAwesomeIcon icon={faInfoCircle} /></div>
                {showDescription && indexIconText === 0 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}
              </Grid>
            </Grid>
            <Grid container spacing={0} className='step-item text-dark-gray'>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>Minimum trading days with a profit of 0.5%</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleDescription("Minimum trading days with a profit of 0.5%", 1)} /></div>
                {showDescription && indexIconText === 1 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}

              </Grid>
            </Grid>
            <Grid container spacing={0} className='step-item-sub text-dark-gray'>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>Maximum loss</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleDescription("Maximum loss", 2)} /></div>
                {showDescription && indexIconText === 2 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}
              </Grid>
            </Grid>
            <Grid container spacing={0} className='step-item text-dark-gray'>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>Target profit</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleDescription("Target profit", 3)} /></div>
                {showDescription && indexIconText === 3 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}

              </Grid>
            </Grid>
            <Grid container spacing={0} className='step-item-sub text-dark-gray'>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>Refund the examination fee</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleDescription("Refund the examination fee", 4)} /></div>
                {showDescription && indexIconText === 4 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}

              </Grid>
            </Grid>
            <Grid container spacing={0} className='step-item text-dark-gray'>
              <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                <Box className='content-table'>BONUS for user step 1</Box>
              </Grid>
              <Grid display="flex" justifyContent="center" alignItems="center">
              </Grid>
              <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{ position: 'relative' }}>
                <div className="text-blue pointer"><FontAwesomeIcon icon={faInfoCircle} onClick={() => toggleDescription("BONUS for user step 1", 5)} /></div>
                {showDescription && indexIconText === 5 && (<Box className='description-icon' ref={descriptionRef}>{descriptionIconText} </Box>)}

              </Grid>
            </Grid>
          </Grid>
          <Box className='step-main'>
            <Grid className='step-btn' sx={{ width: '25%' }}>
              <Box className='step-header'>Step 1</Box>
              <Box className='step-item-sub text-white height-step-first' sx={{ height: '120px !important' }}>Unlimited trading days</Box>
              <Box className='step-item text-white'>3 Days</Box>
              <Box className='step-item-sub text-blue'>{bMaxHoleR1}</Box>
              <Box className='step-item text-blue'>{bProfitTargetR1}</Box>
              <Box className='step-item-sub text-blue feeFunding'>
                {bSale > 0 ?
                  <span >
                    <Box className="imgSaleDashboard" component="img" alt='img sale' src={`${process.env.PUBLIC_URL}/images/sale.png`} />
                    <del style={{ fontSize: '14px', marginRight: '5px', color: '#FF8C00' }}>{bOldFee}</del>
                    <span>{bFee}</span>
                  </span>
                  : <span>{bFee}</span>
                }

              </Box>
              <Box className='step-item text-dark-gray'>(Comming soon)</Box>
              <Box className='step-item'>
                <Button variant="contained"
                  onClick={handleDeposit(optionTrading)}>Let's start the X1K challenge</Button>
              </Box>
            </Grid>
            <Grid className='step-btn' sx={{ width: '25%' }}>
              <Box className='step-header' >Step 2</Box>
              <Box className='step-item-sub text-white height-step-first' sx={{ height: '120px !important' }}>Unlimited trading days</Box>
              <Box className='step-item text-white'>3 Days</Box>
              <Box className='step-item-sub text-blue'>{bMaxHoleR2}</Box>
              <Box className='step-item text-blue'>{bProfitTargetR2}</Box>
              <Box className='step-item-sub text-white'>Free</Box>
              <Box className='step-item text-dark-gray'>(Comming soon)</Box>
              <Box className='step-item'>
              </Box>
            </Grid>
            <Grid className='step-btn' sx={{ width: '50%' }}>
              <Box className='step-header' >Step 3</Box>
              <Box className='step-item-sub text-white height-step-first' sx={{ height: '120px !important' }}>After 14 days from when the LIVE user account is granted, you only need to achieve a minimum of $100. The profit will be paid out plus the refund of the examination fee</Box>
              <Box className='step-item text-dark-gray'>X</Box>
              <Box className='step-item-sub text-blue'>{bMaxHoleR3}</Box>
              <Box className='step-item text-white'>10% for up next level</Box>
              <Box className='step-item-sub text-white'>Refund the examination fee</Box>
              <Box className='step-item text-white'>80%-90%</Box>
              <Box className='step-item'>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <PrimaryFooter />

    </Box>

  );

};

export default DashBoard