import "./index.css"; // Optional: Create a CSS file for styling the popup
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from '../SnackbarProvider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalVariable } from '../../globalVariableSlice';
import api from '../../instance_api';
import api_no_auth from "../../instance_api_no_authentication";


const PopupLogin = ({ handleClose, reload }) => {

    const globalVariable = useSelector((state) => state.globalVariable.value);
    const dispatch = useDispatch();

    const showSnackbar = useSnackbar();
    const [server, setServer] = React.useState(0);

    useEffect(() => {
        getAccount();
    }, []);

    const AccountAction = (action, accountId)  => {
        api_no_auth.post(`/api/account/post_v1_account_action`, { status: action, account_id: accountId })
        .then(response => {
           
        })
        .catch(error => console.error(error));
      };

    const getAccount = async () => {
        const wallet = localStorage.getItem('wallet_address');
        if (wallet) {
            await api_no_auth.get(`/api/account/get_v1_account_by_wallet/${wallet}/${server}`)
                .then(response => {
                    response.data.forEach((element,i) => {
                        element.id = i+1;
                    });
                    setDataUser(response.data)
                })
                .catch(error => console.error(error));
        } else {
            showSnackbar("You haven't connected your wallet", 'warning');
        }

    }

    const [dataUser, setDataUser] = useState([]);
    const [user, setUser] = useState(null);

    const [selectedId, setSelectedId] = useState("");
    const handlePackageSelect = (id) => {
        setSelectedId(id);
    };
    const Login = () => {
        const wallet_address_ss = localStorage.getItem('wallet_address');
        api.post('/api/account/post_v1_account_login',
            {
                user_name: selectedId,
                password: password,
                wallet_address: wallet_address_ss,
            })
            .then(response => {
                if (response.data && response.data.valid) {
                    if (selectedId) {
                        sessionStorage.setItem('account', response.data.id);
                        localStorage.setItem('token', response.data.accessToken);
                        dispatch(setGlobalVariable(response.data.id));
                        showSnackbar('Login success', 'success');
                        handleClose();
                        reload();
                        AccountAction(1, response.data.id)
                    }
                } else {
                    showSnackbar('Login fail', 'error');
                }
            })
            .catch(error => console.error(error));



    }

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleServerChange = (event) => {
        setServer(event.target.value);
    };

    useEffect(() => {
        getAccount();
    }, [server]);

    return (
        <div className="popup-box" >
            <div className="box">
                <Box sx={{ padding: '20px', fontSize: '30px' }}>
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                            <Box component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/x1k_logo.png`} />
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <span className="close-icon" onClick={handleClose}>
                                &times;
                            </span>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='popup-content' sx={{ padding: '20px' }}>
                    <Box className='content-login'>
                        <Box sx={{ marginBottom: '20px', fontSize: '30px' }}>
                        Log in
                        </Box>

                        <Box sx={{ marginBottom: '20px', minWidth: '120px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Server</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={server}
                                    label="Server"
                                    onChange={handleServerChange}
                                    sx={{ color: 'white' }}
                                >
                                    <MenuItem value={0}>Round 1</MenuItem>
                                    <MenuItem value={1}>Round 2</MenuItem>
                                    <MenuItem value={2}>Round Live</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className='text-dark-gray' sx={{ marginBottom: '20px' }}>
                        Select the user's funding package to start trading
                        </Box>
                        <Box className='content-user'>
                            <div className="packageList" >
                                {dataUser.map((pkg, index) => (
                                    <label key={pkg.id} className="packageItem">
                                        <input
                                            key={pkg.user_name}
                                            type="radio"
                                            name="package"
                                            value={pkg.user_name}
                                            checked={selectedId === pkg.user_name}
                                            onChange={() => handlePackageSelect(pkg.user_name)}
                                        />
                                        <span className="packageText" >ID: {pkg.user_name}</span>
                                    </label>
                                ))}
                            </div>
                            <div className="packageList" >
                                {(!dataUser || dataUser.length == 0) && <label>No data</label>}
                            </div>
                        </Box>
                        {(dataUser && dataUser.length > 0) &&
                        <Box className='content-user'>
                            <Grid container spacing={2}>
                                <Grid display="flex" justifyContent="left" alignItems="center" size="auto">
                                    <input
                                        className="passwordInput"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Pasword"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Grid>
                                <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                                    <button className="eyeButton" onClick={toggleShowPassword}>
                                        {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                </Grid>
                            </Grid>
                        </Box>} 
                    </Box>
                    {/* <Box sx={{ marginBottom: '20px' }}>
                        Chỉ kết nối với các trang web mà bạn tin tưởng
                    </Box>
                    <Box className='text-blue' sx={{ marginBottom: '20px' }}>
                        Tìm hiểu thêm
                    </Box> */}
                    <Grid container spacing={2}>
                        <Grid className="btn-forget-pw pointer" display="flex" justifyContent="center" alignItems="center" size={{ xs: 6, md: 6 }}>
                            <Box className='text-dark-gray'>Forgot password</Box>
                        </Grid>
                        <Grid className="btn-login pointer" display="flex" justifyContent="center" alignItems="center" size={{ xs: 6, md: 6 }} onClick={Login}>
                            <Box className='text-white'>Complete</Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
};

export default PopupLogin;
