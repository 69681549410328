import { useState, useEffect } from 'react';
import "./changeSlTp.css"; // Optional: Create a CSS file for styling the popup
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ethers, BrowserProvider } from 'ethers';
import { useSnackbar } from '../SnackbarProvider';
import TextField from '@mui/material/TextField';
import api from '../../instance_api'

const wallet_address_ss = localStorage.getItem('wallet_address');


const ChangeSlTp = ({ trading_id, stop_loss, take_profit, handleClose }) => {
    // const showSnackbar = useSnackbar();
    const [stopLoss, setStopLoss] = useState(null);
    const [takeProfit, setTakeProfit] = useState(null);


    useEffect(() => {
        setStopLoss(stop_loss);
        setTakeProfit(take_profit);
    }, []);

    const handleChangeSltp = () => {
        api.post(process.env.REACT_APP_API_URL + '/api/trading_tx/post_v1_trading_transaction_update',
            {
                id: trading_id,
                stop_loss: Number(stopLoss),
                take_profit: Number(takeProfit)
            })
            .then(response => {
                handleClose()
            })
            .catch(error => console.error(error));
    }

    const handleChangeStoploss = (event) => {
        setStopLoss(event.target.value);
    };

    const handleChangeTakeprofit = (event) => {
        setTakeProfit(event.target.value);
    };


    return (
        <div className="popup-box-change-sltp">
            <div className="box-change-sltp">
                <Box sx={{ padding: '20px 0', fontSize: '30px' }}>
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                            <Box className='text-white' >
                                SL/TP
                            </Box>
                        </Grid>
                        {/* <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            <span className="close-icon" onClick={handleClose}>
                                &times;
                            </span>
                        </Grid> */}
                    </Grid>
                </Box>
                <Box className='popup-content-change-sltp' sx={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid size={6}>
                            <TextField id="outlined-basic" label="Stop loss" variant="outlined" value={stopLoss} onChange={handleChangeStoploss}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        color: 'white', // Text color
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#0091ff', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#0091ff', // Hover border color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#0091ff', // Focused border color
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#0091ff', // Label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#0091ff', // Focused label color
                                    },
                                }} />
                        </Grid>
                        <Grid size={6}>
                            <TextField id="outlined-basic" label="Take profit" variant="outlined" value={takeProfit} onChange={handleChangeTakeprofit}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        color: 'white', // Text color
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#0091ff', // Border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#0091ff', // Hover border color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#0091ff', // Focused border color
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#0091ff', // Label color
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#0091ff', // Focused label color
                                    },
                                }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ marginTop: '20px', padding: '20px 0 ', borderTop:'1px solid #a0a0a0 ' }}>
                    {/* <Grid display="flex" justifyContent="center" alignItems="center" onClick={handleChangeSltp}>
                        <Box>
                            Edit
                        </Box>

                    </Grid> */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box className="confirm-model-button" onClick={handleChangeSltp}>
                            Confirm
                        </Box>
                        <Box className="cancel-model-button" onClick={handleClose}>
                            Cancel
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default ChangeSlTp;
