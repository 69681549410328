import * as React from 'react';
import { Box } from '@mui/material';
import { useState } from "react";

import { Route, Routes, Navigate  } from 'react-router-dom';
import PrimarySearchAppBar from '../X1kAppBar';
import DashBoard from '../dashboard';
import Support from '../support';
import CapitalUpgrade from '../capitalupgrade';
import LauchApp from '../lauchapp';
import Doc from '../doc';
import EvaluationProcess from '../evaluation-process';

const MainDashboard = () => {
  const [reload, setReload] = useState(false);
  const toogleReload = () => {
    setReload(!reload)
  };
  

  
  return (
    <Box sx={{ display: 'flex' }}>
      <PrimarySearchAppBar toogleReload={toogleReload}/>
      <Routes>
        <Route path="/" element={<DashBoard/>}/>
        <Route path="support" element={<Support/>}/>
        <Route path="capitalupgrade" element={<CapitalUpgrade/>}/>
        <Route path="lauchapp" element={<LauchApp key={reload}/>}/>
        <Route path="doc" element={<Doc/>}/>
        <Route path="evaluationprocess" element={<EvaluationProcess/>}/>
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Box>
  );
};

export default MainDashboard;

