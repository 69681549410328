import axios from 'axios';

const api_key = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api_key.interceptors.request.use((config) => {
    config.withCredentials = true;
    config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api_key;