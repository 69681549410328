import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import "./payout.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import api from '../../instance_api_key'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const Payout = () => {
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [acccountId, setAccountId] = useState("");
    const [account, setAccount] = useState(null);

    const [price, setPrice] = useState(null);

    const [lstPayout, setLstPayout] = useState([]);


    useEffect(() => {
        setAccountId(queryParams.get('id'));

        if (queryParams.get('id')) {
            api.get(`/api/account/get_v1_account_by_id/${queryParams.get('id')}`)
                .then(response => {
                    setAccount(response.data);
                    fetchPayout(response.data.user_name);
                })
                .catch(error => console.error(error));
        }

        fetchEthPrice();
    }, []);

    const fetchPayout = (username) => {
        api.get(`/api/payout/get_v1_payout_by_username_wallet/${username}/${localStorage.getItem('wallet_address')}`)
            .then(response => {
                setLstPayout(response.data);
            })
            .catch(error => console.error(error));
    }

    const fetchEthPrice = async () => {
        try {
            const responseBinance = await api.get(
                'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
            );
            const ethPriceBinance = Number(responseBinance.data.price).toFixed(2);

            const responseOkx = await api.get(
                'https://www.okx.com/api/v5/market/ticker?instId=ETH-USDT'
            );
            const ethPriceOkx = Number(responseOkx.data.data[0].last).toFixed(2);

            setPrice(((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2))
        } catch (err) {

        }
    }

    // Function to send Sepolia ETH
    const createRequestPayout = async () => {
        api.post('/api/payout/post_v1_payout_transaction',
            {
                wallet_address: localStorage.getItem('wallet_address'),
                account_username: account.user_name,
                tx_id: null,
                amount: 10
            })
            .then(response => {
                if (response && response.data.valid) {
                    fetchPayout(account.user_name)
                }
            })
            .catch(error => console.error(error));
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Typography variant="h5" gutterBottom>
                Payout
            </Typography>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="payout-item1">
                        <div style={{ width: '20%' }}>
                            <img src={`${process.env.PUBLIC_URL}/images/Payout1.png`} />
                        </div>
                        <div style={{ width: '80%' }}>
                            <div>Withdrawable Profit</div>
                            <div><h3>${account?.balance}</h3></div>
                        </div>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="payout-item1">
                        <div style={{ width: '20%' }}>
                            <img src={`${process.env.PUBLIC_URL}/images/Payout2.png`} />
                        </div>
                        <div style={{ width: '80%' }}>
                            <div>Withdrawable amount</div>
                            <div><h3>$10</h3></div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="payment-item2">
                        <div style={{ display: 'flex', marginBottom: '3%' }}>
                            <div style={{ width: '80%' }}>
                                <div><b>Link</b></div>
                                <div style={{ fontSize: '14px' }}>Retweet the link and you will receive a 1% discount on the project marketing fee</div>
                            </div>
                            <div style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                <div className="payout-link-share">
                                    <FontAwesomeIcon icon={faShare} />
                                </div>
                            </div>
                        </div>
                        <div className="payout-link-item1">
                            <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <img style={{ width: '20px', height: '20px' }} src={`${process.env.PUBLIC_URL}/images/Farcaster-logo.png`} />
                                &nbsp;Link farcaster
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                <b>http/linkfarscaere/anthony1</b>
                            </div>
                        </div>
                        <div className="payout-link-item1">
                            <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <img style={{ width: '20px', height: '20px' }} src={`${process.env.PUBLIC_URL}/images/X-logo.png`} />
                                &nbsp;Link X
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                                <b>http/linkfarscaere/anthony1</b>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="payment-item2">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '70%' }}>
                                <div><b>Which crypto asset would you like to withdraw?</b></div>
                            </div>
                            <div style={{ width: '30%' }}>

                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '13%' }}>
                                <Button variant="outlined" className="pauout-tab4-item" onClick={createRequestPayout}>
                                    <img className="pauout-tab4-item-logo-img" src={`${process.env.PUBLIC_URL}/images/Ethereum-logo.png`} />
                                    &nbsp;ETH</Button>
                            </div>
                            {/* <div style={{ width: '13%' }}>
                                <div className="pauout-tab4-item">
                                    <img className="pauout-tab4-item-logo-img" src={`${process.env.PUBLIC_URL}/images/usdc-logo.png`} />
                                    &nbsp;USDC
                                </div>
                            </div>
                            <div style={{ width: '13%' }}>
                                <div className="pauout-tab4-item">
                                    <img className="pauout-tab4-item-logo-img" src={`${process.env.PUBLIC_URL}/images/usdt-logo.png`} />
                                    &nbsp;USDT
                                </div>
                            </div>
                            <div style={{ width: '13%' }}>
                                <div className="pauout-tab4-item">
                                    X1K
                                </div>
                            </div> */}
                        </div>
                        <div style={{
                            display: 'flex', margin: '8px 9px', padding: '8px', background: '#ececec',
                            borderRadius: '12px', alignItems: 'center'
                        }}>
                            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                <img className="pauout-tab4-item-logo-img" src={`${process.env.PUBLIC_URL}/images/metamask-logo.png`} />
                                &nbsp;<b>Payout address:</b>
                            </div>
                            <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-end' }}>
                                {account?.wallet_address}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
                Withdraw history
            </Typography>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Link basescan</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lstPayout.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.payout_date}</TableCell>
                                        <TableCell align="right">{row.tx_id}</TableCell>
                                        <TableCell align="right">{row.amount}</TableCell>
                                        <TableCell align="right">{row.payout_st}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Payout;
