import { Box, Toolbar, Typography, Button, tableCellClasses } from "@mui/material";
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid2";
import BreadcrumbsNav from "../Breadcrumbs"
import "./ref-fee.style.css";
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import api_key from '../../instance_api_key'
const RefFee = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        bgcolor: 'background.paper',
        borderRadius: '10px'
    };


    const [open, setOpen] = React.useState(false);
    const [itemClaim, setItemClaim] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isClaimAll, setIsClaimAll] = useState(false);
    const columns = [
        { field: 'user_name', headerName: 'Account', minWidth: 300 },
        { field: 'round', headerName: 'Round', minWidth: 200 },
        { field: 'volumeT', headerName: 'Volume', minWidth: 200 },
        { field: 'feepercentT', headerName: 'Feepercent', minWidth: 200 },
        { field: 'feeT', headerName: 'Fee', minWidth: 200 },
        {
            field: 'isClaim',
            headerName: 'Action',
            width: 110,
            renderCell: (params) => {
                // Access the row data (params.row) and extract the ID
                const item = params.row;
                const handleClick = () => {
                    // You can pass the item (row data) to any function
                    setItemClaim(item);
                    handleOpen(true)
                };
                return (
                    <div>
                        {/* {params.value === 1 ? (
                        <span className="claim-btn pointer" onClick={handleOpen}>claim

                        </span>

                    ) : (
                        <span className="text-red">claimed</span>
                    )} */}
                        {params.value === 1 && <span className="claim-btn pointer" onClick={handleClick}>claim</span>}
                        {params.value === 2 && <span className="text-red">claimed</span>}
                        {(params.value === 0 || !params.value) && <span></span>}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box sx={{ ...style }}>
                                <Box sx={{ padding: '20px' }}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <CheckIcon className="check-icon" />
                                        <h2 className="confirmation-title">Do you want claim?</h2>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {
                                            isClaimAll ?
                                                <Box className="claim-model-button" onClick={ClaimAll}>
                                                    Confirm
                                                </Box>
                                                :
                                                <Box className="claim-model-button" onClick={Claim}>
                                                    Confirm
                                                </Box>
                                        }
                                        <Box className="no-model-button" onClick={handleClose}>
                                            Cancel
                                        </Box>
                                    </Box>

                                </Box>

                            </Box>
                        </Modal>
                    </div>
                );
            }
        },
    ];


    const paginationModel = { page: 0, pageSize: 5 };
    // State for managing the sort model

    const [dataTable, setDataTable] = useState([]);
    const [dataAccount, setDataAccount] = useState([]);
    const [dataRefFee, setDataRefFee] = useState([]);
    const [walletAddress, setWalletAddress] = useState("");
    const [totalDraw, setTotalDraw] = useState(0);
    let isEffectExecuted = false;
    let a_dataAccount = [];

    useEffect(() => {
        if (!isEffectExecuted) {
            isEffectExecuted = true;
            // const fullData = rows;
            const b_wallet_address = localStorage.getItem('wallet_address');
            setWalletAddress(b_wallet_address);
            loadData(b_wallet_address);
        }
    }, []);

    const loadData = async (wallet_address) => { 
        GetRefByWallet(wallet_address);
        GetRefFeeByWallet(wallet_address);
    }

    const GetRefByWallet = async (walletAddress) => {
        await api_key.get(`/api/ref/getRefByWalletAddress/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    console.log("dataRef", response.data)
                    setDataAccount([]);
                    response.data.forEach((element, i) => {
                        GetAccountByWallet(element.code_ref, i + 1, response.data.length);
                    });
                }
            })
            .catch(error => console.error(error));
    }
    const GetAccountByWallet = async (walletAddress, index, totalRef) => {
        await api_key.get(`/api/account/get_v1_account_by_wallet_v2/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    mappingData(response.data,  index, totalRef);
                }
            })
            .catch(error => console.error(error));
    }



    const GetRefFeeByWallet = async (walletAddress) => {
        await api_key.get(`/api/ref/getRefFeeByWalletAddress/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    setDataRefFee(response.data);
                    a_dataAccount = response.data;
                    console.log("dataRefFee", response.data)
                }
            })
            .catch(error => console.error(error));
    }
    const array2 = [];
    const mappingData = async (data, i, totalRef) => {
        let b_total_data = array2.concat(data)


        console.log("dataAccount", b_total_data)
        if(i === totalRef) {
            let total = 0;
            let index = 0;
            let b_data = [];
            if (b_total_data) {
                b_total_data.forEach((item) => {
                    for (let i = 0; i <= item.server; i++) {
                        const b_i = {};
                        index = index + 1
                        b_i.id = index;
                        b_i.round = i + 1
                        b_i.id_item = item.id;
                        b_i.user_name = item.user_name;
                        b_i.fee = 10;
                        b_i.feepercent = 15;
                        b_i.volumeT = formatCurrency(b_i.feepercent);
                        b_i.feeT = formatCurrency(b_i.fee);
                        b_i.feepercentT = b_i.feepercent + "%";
                        b_i.balance = item.balance;
                        b_i.current_balance = item.current_balance;
                        b_i.finish = item.finish;
                        b_i.isClaim = 1;
                        let b_refFee = a_dataAccount?.find(e => e.account === item.user_name && e.round === b_i.round);
                        if(b_refFee?.claim) {
                            b_i.isClaim=2;
                        }
                        // if (i !== item.server) {
                        //     if (item.finish?.includes(b_i.round)) {
                        //         b_i.isClaim = 2;
                        //     } else {
                        //         b_i.isClaim = 1;
                        //     }
                        // } else {
                        //     if (item.finish?.includes(b_i.round)) {
                        //         b_i.isClaim = 2;
                        //     } else {
    
                        //         if (item.current_balance > item.balance) {
                        //             b_i.isClaim = 1;
                        //         } else {
                        //             b_i.isClaim = null;
                        //         }
                        //     }
                        // }
                        b_data.push(b_i);
    
                    }
                })
                if (b_data) {
                    b_data.forEach((e, i) => {
                        let num = 0;
                        if (e.isClaim === 1) {
                            if (!isNaN(e.balance)) {
                                e.F_volume = parseFloat(e.balance);
                            }
                            num = (e.F_volume * e.feepercent / 100) - e.fee;
                            total = total + num;
                        }
                    })
                }
                console.log("data", b_data)
                setDataTable(b_data);
                setTotalDraw(formatCurrency(total));
            }
        }
    }

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };
    const Claim = () => {
        const item = itemClaim;
        // if (item.finish) {
        //     item.finish = item.round?.toString() + item.finish?.toString();
        // } else {
        //     item.finish = item.round?.toString();
        // }
        console.log(item);
        api_key.post(`/api/ref/post_v1_claim_ref_fee`, { wallet_address: walletAddress, account: item.user_name, round: item.round})
            .then(response => {
                if (response.data?.id) {
                    setOpen(false);
                    loadData(walletAddress);

                }

            })
            .catch(error => console.error(error));
    };

    const CheckIsClaimAll = () => {
        setIsClaimAll(true);
        handleOpen(true)
    };

    const ClaimAll = () => {
        let b_data_claim = dataTable?.filter(e => e.isClaim === 1);
        if (b_data_claim) {
            b_data_claim.forEach((item,i) => {
                api_key.post(`/api/ref/post_v1_claim_ref_fee`, { wallet_address: walletAddress, account: item.user_name, round: item.round})
                    .then(response => {
                        if (response.data?.id) {
                            setOpen(false);
                            if(i+1 === b_data_claim.length){
                                loadData(walletAddress);
                            }
                        }

                    })
                    .catch(error => console.error(error));
            })
        }

    };
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Ref fee
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={dataTable}
                            columns={columns}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
                        </div>
                        <div style={{ width: '20%' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Box>
                                    <div style={{ marginRight: '10px' }}>Total: <span style={{ fontSize: '21px', fontWeight: 'bold' }}>{totalDraw}</span></div>
                                    <Button variant="contained"
                                        sx={{ backgroundColor: '#1CA7EC', color: 'white', '&:hover': { backgroundColor: '#4cd3c2' }, width: '100%' }} onClick={CheckIsClaimAll} >
                                        Claim All
                                    </Button>
                                </Box>
                            </div>
                        </div>

                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};
export default RefFee
