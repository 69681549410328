import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./billing.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import React, { useState, useEffect, } from 'react';
import { BrowserProvider, ethers } from 'ethers';
import axios from 'axios';
import api from '../../instance_api_key'
import { DataGrid } from '@mui/x-data-grid';

const Billing = () => {
    const columns = [
        { field: 'stt', headerName: 'No', minWidth: 100 },
        { field: 'currency', headerName: 'Currency', minWidth: 200 },
        { field: 'balanceT', headerName: 'Payment Method', minWidth: 200 },
        { field: 'feeT', headerName: 'Fee', minWidth: 200 },
        { field: 'textStatus', headerName: 'Satus', minWidth: 200 },
        { field: 'textType', headerName: 'Type', minWidth: 200 },
        { field: 'tx_dt', headerName: 'Date', minWidth: 200 },
    ];


    const [walletAddress, setWalletAddress] = useState(null);
    const [dataBill, setDataBill] = useState([]);
    useEffect(() => {
        checkIfWalletIsConnected();
    }, []);

    const checkIfWalletIsConnected = async () => {

        const tempProvider = new BrowserProvider(window.ethereum);

        // Request account access if needed
        const accounts = await tempProvider.send('eth_requestAccounts', []);
        getDataBill(accounts[0]);
        setWalletAddress(accounts[0]);
    };

    const getDataBill = (walletAddress) => {
        api.get(`/api/transaction/get_transaction_by_wallet_address/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    response.data.forEach((element, i) => {
                        element.stt = i + 1
                        element.textStatus = convertStatus(element.status);
                        element.textType = convertType(element.type);
                        element.balanceT = formatCurrency(element.balance)
                        element.feeT = formatCurrency(element.fee)

                    });
                    setDataBill(response.data)
                }
                console.log(response.data);
            })
            .catch(error => console.error(error));
    }

    const convertStatus = (e) => {
        // 0-pending,1-success, 2-failed
        let text = "pending"
        if (e === 1) {
            text = "success";
        } else if (e === 2) {
            text = "failed"
        }
        return text;
    }

    const convertType = (e) => {
        // 1-Deposit, 2-Long, 3-Short, 4-Payout
        let text = "Deposit"
        if (e === 2) {
            text = "Long";
        } else if (e === 3) {
            text = "Short"
        } else if (e === 4) {
            text = "Payout"
        }
        return text;
    }
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };
    const paginationModel = { page: 0, pageSize: 5 };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Billing-Payment Method
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={dataBill}
                            columns={columns}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Billing;
