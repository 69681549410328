import React, { useState, useEffect } from 'react';

const CountdownTimer = ({onTimeChange}) => {
  const [timeLeft, setTimeLeft] = useState(900); // Set initial time (60 seconds for 1 minute)

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Decrement time every second (1000 ms)
      if ((timeLeft - 1) == 0) {
        onTimeChange(true);
        setTimeLeft(900);
      }
      return () => clearInterval(interval); // Clear interval on component unmount or when time reaches 0

    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <p>Remaining time: {formatTime(timeLeft)}</p>
  );
};

export default CountdownTimer;