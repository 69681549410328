import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const BreadcrumbsNav = () => {
    const location = useLocation();

    // Split pathname into parts
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link component={RouterLink} underline="hover" color="inherit" to="/">
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
            </Link>

            {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                // If it is the last path part, render a Typography instead of Link
                return index === pathnames.length - 1 ? (
                    <Typography color="text.primary" key={to}>
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Typography>
                ) : (
                    <Link
                        component={RouterLink}
                        underline="hover"
                        color="inherit"
                        to={to}
                        key={to}
                    >
                        {value.charAt(0).toUpperCase() + value.slice(1)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbsNav;