import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./leaderboard.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const Leaderboard = () => {
    const columns = [
        { field: 'id', headerName: 'No', minWidth: 200 },
        { field: 'name', headerName: 'Name', minWidth: 300 },
        { field: 'profit_usd', headerName: 'Profit USD	', minWidth: 300 },
        { field: 'account_size', headerName: 'Account size	', minWidth: 300 },
        { field: 'gain', headerName: 'Gain%', minWidth: 300 },
      
    ];

    const rows = [
        {
            id: 1,
            name: 'February 29',
            profit_usd: '$782.01',
            account_size: '$782.01',
            gain: '53%',
        },
        {
            id: 2,
            name: 'February 29',
            profit_usd: '$782.01',
            account_size: '$782.01',
            gain: '53%',
        },
        {
            id: 3,
            name: 'February 29',
            profit_usd: '$782.01',
            account_size: '$782.01',
            gain: '53%',
        },
        {
            id: 4,
            name: 'February 29',
            profit_usd: '$782.01',
            account_size: '$782.01',
            gain: '53%',
        },
    ];
    const paginationModel = { page: 0, pageSize: 5 };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Leaderboard Aug 24, 2024
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <div style={{display: 'flex'}}>
                        <div className="leaderboard-item1">All</div>
                        <div className="leaderboard-item1">$5.000</div>
                        <div className="leaderboard-item1">$10.000</div>
                        <div className="leaderboard-item1">$25.000</div>
                        <div className="leaderboard-item1">$50.000</div>
                        <div className="leaderboard-item1">$100.000</div>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Leaderboard
