import { Box, Toolbar, Tabs, Tab, Stack, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadcrumbsNav from "../Breadcrumbs"
import { useNavigate } from "react-router-dom";
import api from '../../instance_api'
import api_key from '../../instance_api_key'

import axios from 'axios';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const wallet_address_ss = localStorage.getItem('wallet_address');

const Accounts = () => {
    const navigate = useNavigate();

    const [value, setValue] = React.useState(1);
    const [account, setAccount] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        api_key.get(`/api/account/get_v1_account/${newValue}/${wallet_address_ss}`)
            .then(response => {
                setAccount(response.data);
            })
            .catch(error => console.error(error));
    };


    useEffect(() => {
        api_key.get(`/api/account/get_v1_account/1/${wallet_address_ss}`)
            .then(response => {
                setAccount(response.data);
            })
            .catch(error => console.error(error));
    }, []);

    const handleAccountOverview = (item) => {
        navigate("/profile/account-overview?id=" + item.id);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
      };
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <img style={{ width: '-webkit-fill-available' }} src={`${process.env.PUBLIC_URL}/images/Account-banner.png`} />
                </Grid>
                <Grid item size={{ xs: 12, md: 7, lg: 9 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Inactive" {...a11yProps(0)} />
                                <Tab label="Active" {...a11yProps(1)} />
                                <Tab label="Breached" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container spacing={3}>
                                {account.length > 0 && account.map(item => (
                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                                        <Box sx={{ width: '100%', height: '300px', bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
                                            <div style={{ fontSize: '36px', fontWeight: 'bold' }}>
                                                {formatCurrency(item.balance)}
                                            </div>
                                            <div>
                                                <p>ID: <b>{item.user_name}</b>&nbsp;<FontAwesomeIcon icon={faClone} /></p>
                                            </div>
                                            <div>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Server Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.server}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Balance</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Equity</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Account Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.account_type}</Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <Button variant="contained" onClick={() => handleAccountOverview(item)}  sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}>
                                                        Dashboard</Button>
                                                </Stack>
                                            </div>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Grid container spacing={3}>
                                {account.length > 0 && account.map(item => (
                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                                        <Box sx={{ width: '100%', height: '300px', bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
                                            <div style={{ fontSize: '36px', fontWeight: 'bold' }}>
                                                {formatCurrency(item.balance)}
                                            </div>
                                            <div>
                                                <p>ID: <b>{item.user_name}</b>&nbsp;<FontAwesomeIcon icon={faClone} /></p>
                                            </div>
                                            <div>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Server Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.server}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Balance</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Equity</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Account Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.account_type}</Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <Button variant="contained" onClick={() => handleAccountOverview(item)} sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}>
                                                        Dashboard</Button>
                                                </Stack>
                                            </div>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <Grid container spacing={3}>
                                {account.length > 0 && account.map(item => (
                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                                        <Box sx={{ width: '100%', height: '300px', bgcolor: "#FFFFFF", p: 2, borderRadius: '24px' }}>
                                            <div style={{ fontSize: '36px', fontWeight: 'bold' }}>
                                                {formatCurrency(item.balance)}
                                            </div>
                                            <div>
                                                <p>ID: <b>{item.user_name}</b>&nbsp;<FontAwesomeIcon icon={faClone} /></p>
                                            </div>
                                            <div>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Server Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.server}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Balance</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Equity</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{formatCurrency(item.balance)}</Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>Account Type</Grid>
                                                    <Grid item size={{ xs: 12, md: 6, lg: 6 }}>{item.account_type}</Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <Button variant="contained" onClick={() => handleAccountOverview(item)} sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' } }}>
                                                        Dashboard</Button>
                                                </Stack>
                                            </div>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                    </Box>
                </Grid>
                <Grid item size={{ xs: 12, md: 5, lg: 3 }}>
                    <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/trading-view.png`} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Accounts