import {
    Box, Toolbar, Typography, Switch
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./profile.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import { BrowserProvider, ethers } from 'ethers';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import api from '../../instance_api_key';
import { useSnackbar } from '../SnackbarProvider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
};

const Profile = () => {
    const showSnackbar = useSnackbar();
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [walletAddress, setWalletAddress] = useState(null);
    const [email, setEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [linkX, setLinkX] = useState(null);
    const [linkFacaster, setLinkFacaster] = useState(null);
    const [avartar, setAvartar] = useState(null);
    const [profile, setProfile] = useState(null);
    const [user, setUser] = useState(null);
    let isEffectExecuted = false;
    useEffect(() => {
        if (!isEffectExecuted) {
            checkIfWalletIsConnected();
            isEffectExecuted = true;
        }
    }, []);



    const checkIfWalletIsConnected = async () => {

        const tempProvider = new BrowserProvider(window.ethereum);

        // Request account access if needed
        const accounts = await tempProvider.send('eth_requestAccounts', []);
        getDataProfile(accounts[0]);
        setWalletAddress(accounts[0]);
    };

    const getDataProfile = (walletAddress) => {
        api.get(`/api/profile/getByWalletAddress/${walletAddress}`)
            .then(response => {
                if (response.data) {
                    const dataProfile = response.data[0];
                    dataProfile.wallet_address = walletAddress
                    setEmail(dataProfile.email);
                    // setUserName(dataProfile.user_name);
                    // setLinkX(dataProfile.link_x);
                    // setLinkFarcaster(dataProfile.link_facaster);
                    // setAvartar(dataProfile.avartar);
                    setProfile(dataProfile);
                    if (dataProfile) {
                        connectX(dataProfile);
                    }
                }
                console.log(response.data);
            })
            .catch(error => console.error(error));
    }

    const connectX = (dataProfile) => {
        api
            .get("http://localhost:5000/profile", { withCredentials: true })
            .then((response) => {
                const data = response.data
                console.log('X', data)
                // setAvartar(data.photos[0]?.value)
                // setLinkX("https://twitter.com/" + data.username)
                const checkConnectX = localStorage.getItem('connectX');
                if (checkConnectX) {
                    save(dataProfile, data);
                    localStorage.removeItem('connectX');
                }

            })
            .catch((error) => console.error("Không thể lấy hồ sơ người dùng:", error));
    };

    const save = (dataProfile, dataX) => {
        api.post(`/api/profile/update_profile`, { id: dataProfile?.id, email: dataProfile?.email, linkX: "https://twitter.com/" + dataX?.username, linkFacaster: linkFacaster, avartar: dataX?.photos[0]?.value })
            .then(response => {
                console.log(response.data);
                handleClose();
                getDataProfile(dataProfile.wallet_address);
            })
            .catch(error => console.error(error));
    };
    const update = () => {
        api.post(`/api/profile/update_profile`, { id: profile?.id, email: email, linkX: profile.link_x, linkFacaster: linkFacaster, avartar: profile.avartar })
            .then(response => {
                console.log(response.data);
                handleClose();
                getDataProfile(walletAddress);
            })
            .catch(error => console.error(error));
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleLogin = () => {
        localStorage.setItem('connectX', true);
        window.location.href = "http://localhost:5000/auth/twitter";

    };
    const [codeRef, setCodeRef] = useState(null);
    const handleChangeCodeRef = (event) => {
        setCodeRef(event.target.value);
    };
    const handleAdd = () => {
        console.log("codeRef", codeRef)
        api.post(`/api/ref/post_v1_ref_insert_ref`, { wallet_address: walletAddress, code_ref: codeRef })
        .then(response => {
            if (response.data) {
                showSnackbar(response.data.messages, 'success');
            }
            console.log(response.data);
        })
        .catch(error => console.error(error));
    };
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Profile
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Edit and manage your personal and account information here
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="avartar-item">
                        <div className={`avartar-back ${profile?.email ? 'active' : 'inactive'}`}>
                            <img style={{ width: '120px', height: '120px', borderRadius: '50%' }} src={profile?.avartar ? profile?.avartar : `${process.env.PUBLIC_URL}/images/profile-avatar.png`} />
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <div style={{ width: '90%', paddingBottom: '5px', borderBottom: '1px solid #ececec' }}>
                                <div style={{ fontWeight: 'bold' }}>Email</div>
                                <div>{profile?.email}</div>
                            </div>
                            <div style={{ width: '10%', textAlign: 'end', cursor: 'pointer' }} onClick={handleOpen}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <div style={{ width: '90%', paddingBottom: '5px', borderBottom: '1px solid #ececec' }}>
                                <div style={{ fontWeight: 'bold' }}>Link X</div>
                                <div>{profile?.link_x}</div>
                            </div>
                            <div style={{ width: '10%', textAlign: 'end', cursor: 'pointer' }} onClick={handleLogin}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <div style={{ width: '90%', paddingBottom: '5px', borderBottom: '1px solid #ececec' }}>
                                <div style={{ fontWeight: 'bold' }}>Link farcaster</div>
                                <div>{profile?.linkFarcaster}</div>
                            </div>
                            <div style={{ width: '10%', textAlign: 'end' }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '5px' }}>
                            <div style={{ width: '90%', paddingBottom: '5px', borderBottom: '1px solid #ececec' }}>
                                <div style={{ fontWeight: 'bold' }}>Metamask Add</div>
                                <div>{walletAddress}</div>
                            </div>

                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop:'20px' }}>
                            <TextField id="outlined-basic" label="Wallet address" variant="outlined" size="small" sx={{width:'85%'}} value={codeRef} onChange={handleChangeCodeRef}/>
                            <Button variant="contained" onClick={handleAdd} sx={{width:'15%'}}>ADD REF</Button>
                        </div>
                    </div>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div className="avartar-item">
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                            Email Notifications
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Configure Email Notification
                        </Typography>
                        <div style={{ width: '100%' }}>
                            <Switch {...label} disabled />&nbsp;Receive notifications from email
                        </div>
                        <div style={{ width: '100%' }}>
                            <Switch {...label} disabled />&nbsp;Important Email
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Box sx={{ padding: '20px' }}>
                        <Box sx={{ borderBottom: '#a0a0a0 solid 1px', fontSize: '20px', paddingBottom: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid display="flex" justifyContent="center" alignItems="center" size={3}>
                                    <img style={{ width: '120px', height: '120px', borderRadius: '50%' }} src={avartar ? avartar : `${process.env.PUBLIC_URL}/images/profile-avatar.png`} />
                                </Grid>
                                <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" size={6} >
                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                                        <Input id="standard-adornment-email" defaultValue={email} onChange={(event) => { setEmail(event.target.value) }} />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-linkX">Link X</InputLabel>
                                        <Input id="standard-adornment-linkX-disabled" disabled defaultValue={profile?.link_x} />
                                    </FormControl>
                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-wallet">Metamask Add</InputLabel>
                                        <Input id="standard-adornment-wallet-disabled" disabled defaultValue={walletAddress} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid display="flex" justifyContent="center" alignItems="center" size="grow">
                            </Grid>
                            <Grid display="flex" justifyContent="center" alignItems="center" size={9}>
                            </Grid>
                            <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center" size={1.2} sx={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#3e63dd', color: '#fff', fontSize: '20px', borderRadius: '10px', cursor: 'pointer' }} onClick={update}>
                                <Box >
                                    Save
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Modal>
        </Box>
    );
};
export default Profile
