import './index.css';
import '../../App.css';
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMinimize, faCheck, faInfoCircle, faMessage } from "@fortawesome/free-solid-svg-icons";
import PrimaryFooter from '../X1kFooter';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import api_no_auth from '../../instance_api_no_authentication';
import api from '../../instance_api';

const CapitalUpgrade = () => {
    // const dataAccSize = [
    //     { id: 0, stt: 1, text: '5,000', value: 50 },
    //     { id: 1, stt: 2, text: '10,000', value: 95 },
    //     { id: 2, stt: 3, text: '20,000', value: 186 },
    //     { id: 3, stt: 4, text: '50,000', value: 268 },
    //     { id: 4, stt: 5, text: '100,000', value: 499 }

    // ];
    // const [accSize, setAccSize] = useState(dataAccSize);


    // const dataStage = [
    //     { id: 0, show: true, title: 'Giai đoạn đánh giá', value: '01', target1: 8, target2: 5, minDate: 3, minValue: 0.5, date: 0, nextDate: 0, declineDate: 5, declineValue: 10 },
    //     { id: 1, show: false, title: 'Giai đoạn cấp vốn', value: '02', target1: 0, target2: 0, minDate: 0, minValue: 0, date: 0, nextDate: 0, declineDate: 5, declineValue: 10 },
    //     { id: 2, show: false, title: 'Giai đoạn rút tiền', value: '03', target1: 0, target2: 0, minDate: 0, minValue: 0, date: 14, nextDate: 14, declineDate: 0, declineValue: 0 },
    // ];
    // const [activeStage, setActiveStage] = useState('01');

    // const showContent = (step, active) => () => {
    //     setActiveStage(step);
    //     const nextShapes = shapes.map(shape => {
    //         if (shape.value === step) {
    //             shape.show = active === 1 ? true : false;
    //             return shape;
    //         } else {
    //             shape.show = false
    //             return shape;
    //         }
    //     });
    //     // Re-render with the new array
    //     setShapes(nextShapes);

    //     if (step === "01" && active === 1) {
    //         setIsExam(true);
    //     } else {
    //         setIsExam(false);
    //     }
    // };
    // const [shapes, setShapes] = useState(dataStage);
    // const [isExam, setIsExam] = useState(true);

    // const dataExam = [
    //     { id: 0, value: 1, show: true, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '', c2: '$100.000', c3: '' },
    //     { id: 1, value: 1, show: true, stt: 2, textColor: false, text: 'Số ngày giao dịch tối thiểu', c1: '', c2: '3 Days', c3: '' },
    //     { id: 3, value: 1, show: true, stt: 3, textColor: false, text: 'Tỷ suất sinh lời tối thiểu', c1: '', c2: '0.5%', c3: '' },
    //     { id: 4, value: 1, show: true, stt: 4, textColor: true, text: 'Mức lợi nhuận tối thiểu', c1: '', c2: '$500', c3: '' },
    //     { id: 5, value: 2, show: false, stt: 1, textColor: false, text: 'Mức giới hạn sụt giảm', c1: '5%', c2: '5%', c3: '5%' },
    //     { id: 6, value: 2, show: false, stt: 2, textColor: true, text: 'Công thức và các trường hợp xảy ra', c1: '100K$ - 100K$* 5%= 95K$', c2: '102K$ - 102K $ *5% = 96.9K$', c3: '99K$ - 99K$ * 5% = 94.05K$' },
    //     { id: 7, value: 3, show: false, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '', c2: '$100,000', c3: '' },
    //     { id: 8, value: 3, show: false, stt: 2, textColor: false, text: 'Mức giới hạn sụt giảm', c1: '', c2: '10%', c3: '' },
    //     { id: 9, value: 3, show: false, stt: 3, textColor: true, text: 'Bạn sẽ vi phạm nếu tài khoản sụt giảm dưới', c1: '', c2: '$90,000', c3: '' },
    // ];

    // const [activeButton, setActiveButton] = useState('Lợi nhuận ngày');
    // const showExam = (step, active, text) => () => {
    //     setActiveButton(text);
    //     const nextExam = exam.map(shape => {
    //         if (shape.value === step) {
    //             shape.show = active === 1 ? true : false;
    //             return shape;
    //         } else {
    //             shape.show = false
    //             return shape;
    //         }
    //     });
    //     // Re-render with the new array
    //     setShowExam(nextExam);
    // };
    // const [exam, setShowExam] = useState(dataExam);


    // const dataBalanceDetail = [
    //     { id: 0, value: 1, show: true, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '$5,000', c2: '$6,000', c3: '$7,000', c4: '$8,000', c5: '$9,000' },
    //     { id: 1, value: 1, show: true, stt: 2, textColor: true, text: 'Mục tiêu tài khoản', c1: '$5,500', c2: '$6,600', c3: '$7,700', c4: '$8,800', c5: '$9,900' },
    //     { id: 3, value: 1, show: true, stt: 3, textColor: false, text: 'Tỷ lệ thanh toán', c1: '80%/20%', c2: '80%/20%', c3: '80%/20%', c4: '80%/20%', c5: '80%/20%' },

    //     { id: 0, value: 2, show: false, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '$10,000', c2: '$12,000', c3: '$14,000', c4: '$16,000', c5: '$18,000' },
    //     { id: 1, value: 2, show: false, stt: 2, textColor: true, text: 'Mục tiêu tài khoản', c1: '$5,500', c2: '$6,600', c3: '$7,700', c4: '$8,800', c5: '$9,900' },
    //     { id: 3, value: 2, show: false, stt: 3, textColor: false, text: 'Tỷ lệ thanh toán', c1: '80%/20%', c2: '80%/20%', c3: '80%/20%', c4: '80%/20%', c5: '80%/20%' },

    //     { id: 0, value: 3, show: false, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '$25,000', c2: '$30,000', c3: '$35,000', c4: '$40,000', c5: '$45,000' },
    //     { id: 1, value: 3, show: false, stt: 2, textColor: true, text: 'Mục tiêu tài khoản', c1: '$5,500', c2: '$6,600', c3: '$7,700', c4: '$8,800', c5: '$9,900' },
    //     { id: 3, value: 3, show: false, stt: 3, textColor: false, text: 'Tỷ lệ thanh toán', c1: '80%/20%', c2: '80%/20%', c3: '80%/20%', c4: '80%/20%', c5: '80%/20%' },

    //     { id: 0, value: 4, show: false, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '$50,000', c2: '$60,000', c3: '$70,000', c4: '$80,000', c5: '$90,000' },
    //     { id: 1, value: 4, show: false, stt: 2, textColor: true, text: 'Mục tiêu tài khoản', c1: '$5,500', c2: '$6,600', c3: '$7,700', c4: '$8,800', c5: '$9,900' },
    //     { id: 3, value: 4, show: false, stt: 3, textColor: false, text: 'Tỷ lệ thanh toán', c1: '80%/20%', c2: '80%/20%', c3: '80%/20%', c4: '80%/20%', c5: '80%/20%' },

    //     { id: 0, value: 5, show: false, stt: 1, textColor: true, text: 'Số dư tài khoản', c1: '$100,000', c2: '$120,000', c3: '$140,000', c4: '$160,000', c5: '$180,000' },
    //     { id: 1, value: 5, show: false, stt: 2, textColor: true, text: 'Mục tiêu tài khoản', c1: '$5,500', c2: '$6,600', c3: '$7,700', c4: '$8,800', c5: '$9,900' },
    //     { id: 3, value: 5, show: false, stt: 3, textColor: false, text: 'Tỷ lệ thanh toán', c1: '80%/20%', c2: '80%/20%', c3: '80%/20%', c4: '80%/20%', c5: '80%/20%' },
    // ];


    const navigate = useNavigate();
    const Support = (step) => () => {
        navigate("/support");
    };


    const [dataDoc, setDataDoc] = useState([]);
    const [dataBalance, setDataBalance] = useState([]);
    let isEffectExecuted = false;
    const descriptionRef = useRef(null);
    useEffect(() => {
        if (!isEffectExecuted) {
            isEffectExecuted = true;
            getBalance();
        }
        const handleClickOutside = (event) => {
            if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
              setShowDescription(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    const getDoc = (balance) => {
        api_no_auth.get(`/api/docs/getDocsByType/CapitalExpansion`)
            .then(response => {
                if (response.data) {
                    setDataDoc(response.data);
                    const detail = response.data.filter(e => e.c5 === balance?.toString());
                    setDataDetail(detail);

                }
            })
            .catch(error => console.error(error));
    }
    const getBalance = () => {
        api_no_auth.get(`/api/balance/get_v1_balance/1`)
            .then(response => {
                if (response && response.data.length > 0) {
                    response.data.forEach(element => {
                        element.balanceText = formatCurrency(element.balance);
                        if (element.sale > 0) {
                            element.fee_amt = element.fee_amt - (element.fee_amt * element.sale / 100);
                        }
                    });
                    setDataBalance(response.data);
                    setActiveBalance(response.data[0]);
                    getDoc(response.data[0]?.balance);


                }
            })
            .catch(error => console.error(error));
    }
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };
    const [activeBalance, setActiveBalance] = useState(dataBalance[0]);

    const showDetaiBalance = (item) => () => {
        setActiveBalance(item);
        const detailDoc = dataDoc?.filter(e => e.c5 === item.balance?.toString());
        setDataDetail(detailDoc);
        setShowDetailBalance(detailDoc);
    };
    const [detail, setShowDetailBalance] = useState([]);
    const [accountBalance, setAccountBalance] = useState([]);
    const [balanceTarget, setBalanceTarget] = useState([]);
    const [payoutRatio, setPayoutRatio] = useState([]);
    const [countItem, setCountItem] = useState(0);
    const setDataDetail = (data) => {
        setAccountBalance([]);
        setBalanceTarget([]);
        setPayoutRatio(null);
        let b_c2 = [];
        let b_c3 = [];
        let b_c4 = [];
        data?.forEach(e => {
            b_c2.push(e.c2);
            b_c3.push(e.c3);
            b_c4.push(e.c4);
        })
        setAccountBalance(b_c2);
        setBalanceTarget(b_c3);
        setPayoutRatio(b_c4);
        setCountItem(data?.length);
    };

    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible); // Đổi trạng thái giữa true và false
    };

    const [isVisible2, setIsVisible2] = useState(true);
    const toggleVisibility2 = () => {
        setIsVisible2(!isVisible2); // Đổi trạng thái giữa true và false
    };

    const [showDescription, setShowDescription] = useState(false);
    const [descriptionIconText, setDescriptionIconText] = useState("");
    const [indexIconText, setIndexIconText] = useState(0);
    const toggleDescription = (value, index) => {
      setShowDescription(!showDescription);
      setDescriptionIconText(value);
      setIndexIconText(index);
    };
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', color: '#fff', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-upgrade">
                <Box className="content-upgrade">
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="left" alignItems="left" size="grow">
                            <Box className="content-upgrade-img" component="img" alt='bitcoin' src={`${process.env.PUBLIC_URL}/images/bitcoin.png`} />
                        </Grid>
                        <Grid display="flex" justifyContent="center" alignItems="center" >
                            <Box sx={{ textAlign: 'center', width: '100%' }}>
                                {/* <Box sx={{ fontSize: '55px', fontWeight: '500' }}>Để nhận được tài khoản 2-step</Box>
                                <Box sx={{ fontSize: '55px', fontWeight: '500' }}>bạn cần trải qua 2 vòng đánh giá</Box> */}
                                <Box className="choose-upgrade-title" sx={{ fontSize: '55px', fontWeight: '500' }}>Capital expansion policy</Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box className="choose-upgrade">There are 5 account sizes for you to choose from</Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="right" size="grow">
                            <Box className="content-upgrade-img" component="img" alt='etherium' src={`${process.env.PUBLIC_URL}/images/etherium.png`} />
                        </Grid>

                    </Grid>

                    {/* <Box className="support-btn" onClick={Support()}>
                        <FontAwesomeIcon icon={faMessage} />
                    </Box> */}
                    <Box className="main-content-update" sx={{ padding: '60px' }}>
                        {/* <Grid container spacing={2} columns={20}>
                            {accSize.map(item => (
                                <Grid key={item.id} size={4} className="accout-size-items">
                                    <Box className="accout-number-items">
                                        <Box sx={{ border: '#fff solid 4px', fontWeight: 'bold', width: '30px', height: '30px' }}>
                                            <Box sx={{ marginTop: '-5px', fontSize: '20px' }}>{item.stt}</Box>
                                        </Box>
                                    </Box>
                                    <Box className='text-dark-gray' sx={{ fontSize: '25px', marginTop: '30px' }}>Account size</Box>
                                    <Box sx={{ fontSize: '42px', fontWeight: 'bold' }}>${item.text}</Box>
                                    <Box sx={{ fontSize: '20px', marginTop: '20px' }}><FontAwesomeIcon icon={faWindowMinimize} style={{ marginBottom: '5px', marginRight: '10px' }} /> <span className='text-dark-gray'>Cost</span> <span>${item.value}</span></Box>
                                </Grid>
                            ))}
                        </Grid> */}


                        {/* <Box sx={{ fontSize: '50px', fontWeight: '700', marginTop: '60px' }}>
                           Gồm 3 bước
                        </Box>
                        <Box sx={{ marginTop: '25px' }}>
                            <Grid container spacing={2} columns={16} size={4} sx={{ marginLeft: '25px' }}>
                                <Grid size={2} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`pointer stage ${activeStage === '01' ? 'active' : ''}`} onClick={showContent("01", 1)}>01</Box>
                                </Grid>
                                <Grid size={5} >
                                    <Box sx={{ marginTop: '20px', borderBottom: '#fff solid 2px' }}></Box>
                                </Grid>
                                <Grid size={2} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`pointer stage ${activeStage === '02' ? 'active' : ''}`} onClick={showContent("02", 1)}>02</Box>
                                </Grid>
                                <Grid size={5} >
                                    <Box sx={{ marginTop: '20px', borderBottom: '#fff solid 2px' }} ></Box>
                                </Grid>
                                <Grid size={2} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`pointer stage ${activeStage === '03' ? 'active' : ''}`} onClick={showContent("03", 1)}>03</Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} columns={20} size={6} sx={{ marginTop: '10px' }}>
                                <Grid size={4.5} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`text-stage ${activeStage === '01' ? 'active' : ''}`}>Đánh giá</Box>
                                </Grid>
                                <Grid size={3} >
                                </Grid>
                                <Grid size={4} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`text-stage ${activeStage === '02' ? 'active' : ''}`}>Cấp vốn</Box>
                                </Grid>
                                <Grid size={3} >
                                </Grid>
                                <Grid size={4} display="flex" justifyContent="center" alignItems="center">
                                    <Box className={`text-stage ${activeStage === '03' ? 'active' : ''}`}>Rút tiền</Box>
                                </Grid>
                            </Grid>
                        </Box> */}


                        {/* <Box className="item-capital">
                            {shapes.map(item => (
                                <Box key={item.id}  >
                                    {item.show === true ? (
                                        <Box sx={{ padding: '30px' }}>
                                            <Grid sx={{ padding: '10px 0' }} container spacing={3} className="top-item-capital">
                                                <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                                    <Box>
                                                        <Box sx={{ fontSize: '30px', fontWeight: '500' }}>{item.title}</Box>
                                                        <Box sx={{ fontSize: '20px', }}>
                                                            {item.value === "01" ? (
                                                                <Box className='text-capital text-dark-gray'>Gồm 2 vòng và yêu cầu như sau:</Box>
                                                            ) : (
                                                                <Box></Box>
                                                            )}
                                                            {item.value === "02" ? (
                                                                <Box>
                                                                    <Box className='text-capital text-dark-gray'>Sau khi vượt qua giai đoạn đánh giá bạn sẽ chuyển sang giai đoạn cấp vốn</Box>
                                                                    <Box className='text-capital text-dark-gray'>Yêu cầu tối thiểu để payout là đạt lợi nhuận 100$ và không giới hạn thời gian.</Box>
                                                                    <Box className='text-capital text-dark-gray'>Mức ăn chia khởi điểm là 80% và được tăng dần lên tới 90%</Box>
                                                                    <Box className='text-capital text-dark-gray'>2 quy tắc giao dịch bạn cần chú ý đó là :</Box>
                                                                </Box>
                                                            ) : (
                                                                <Box></Box>
                                                            )}
                                                            {item.value === "03" ? (
                                                                <Box className='text-capital text-dark-gray'>Bạn được rút tiền lần đầu:</Box>
                                                            ) : (
                                                                <Box></Box>
                                                            )}
                                                        </Box>
                                                    </Box>


                                                </Grid>
                                                <Grid display="flex" justifyContent="center" alignItems="center" size={5}></Grid>
                                                <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                                                    {item.value === "01" ? (
                                                        <Box className='img-capital' component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/Frame_1.png`} />
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                    {item.value === "02" ? (
                                                        <Box className='img-capital' component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/Frame_2.png`} />
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                    {item.value === "03" ? (
                                                        <Box className='img-capital' component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/Frame_3.png`} />
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid className="bottom-item-capital">
                                                <Box sx={{ paddingTop: '20px' }}>
                                                    {item.value === "01" ? (
                                                        <Grid container spacing={7}>
                                                            <Grid size="auto">
                                                                <Box className='text-capital text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Mục tiêu lợi nhuận: <span className="text-blue">{item.target1}% vòng 1, {item.target1}% vòng 2</span></Box>
                                                                <Box className='text-capital text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Số ngày giao dịch tối thiểu có lợi nhuận là:  <span className="text-blue">{item.minDate} ngày, ít nhất {item.minValue}%/ngày</span></Box>
                                                                <Box className='text-capital text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Thời gian: <span className="text-blue">Không giới hạn</span></Box>
                                                            </Grid>
                                                            <Grid size="grow">
                                                                <Box>
                                                                    <Box className='text-capital text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Giới hạn sụt giảm ngày là: <span className="text-blue">{item.declineDate}%</span></Box>
                                                                    <Box className='text-capital text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Giới hạn sụt giảm tài khoản là: <span className="text-blue">{item.declineValue}%</span></Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                    {item.value === "02" ? (
                                                        <Grid container spacing={3}>
                                                            <Grid size="auto">
                                                                <Box className='text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Giới hạn sụt giảm ngày là: <span className="text-blue">{item.declineDate}%</span></Box>
                                                            </Grid>
                                                            <Grid size="grow">
                                                                <Box>
                                                                    <Box className='text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Giới hạn sụt giảm tài khoản là: <span className="text-blue">{item.declineValue}%</span></Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                    {item.value === "03" ? (
                                                        <Grid container spacing={3}>
                                                            <Grid size="auto">
                                                                <Box className='text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Sau khi nhận được tài khoản cấp vốn: <span className="text-blue">{item.date} ngày</span></Box>
                                                            </Grid>
                                                            <Grid size="grow">
                                                                <Box>
                                                                    <Box className='text-white' sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#f5d90a' }} /> Các lần tiếp theo vào: <span className="text-blue">{item.nextDate}%</span></Box>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Box>

                                    ) : (
                                        <Box></Box>
                                    )}
                                </Box>

                            ))}
                        </Box> */}


                        {/* {isExam === true ? (
                            <Box sx={{ marginTop: '30px' }}>
                                <Box >
                                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ fontSize: '25px' }}>Các ví dụ về điều kiện của giai đoạn 1:</Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" size={4} sx={{ margin: '35px' }}>
                                        <div className="toggle-buttons">
                                            <button
                                                className={`button ${activeButton === 'Lợi nhuận ngày' ? 'active' : ''}`}
                                                onClick={showExam(1, 1, 'Lợi nhuận ngày')}
                                            >
                                                Lợi nhuận ngày
                                            </button>
                                            <button
                                                className={`button ${activeButton === 'Giới hạn giảm ngày' ? 'active' : ''}`}
                                                onClick={showExam(2, 1, 'Giới hạn giảm ngày')}
                                            >
                                                Giới hạn giảm ngày
                                            </button>
                                            <button
                                                className={`button ${activeButton === 'Giới hạn giảm tài khoản' ? 'active' : ''}`}
                                                onClick={showExam(3, 1, 'Giới hạn giảm tài khoản')}
                                            >
                                                Giới hạn giảm tài khoản
                                            </button>
                                        </div>
                                    </Box>
                                </Box>
                                <Box>
                                    {exam.map(item => (
                                        <Box >
                                            {item.show === true ? (
                                                <Box key={item.id} className={`${item.stt === 1 ? 'itemExamFisrt' : 'itemExam'} ${item.stt % 2 === 0 ? '' : 'active'} `}>
                                                    <Grid container spacing={2} sx={{ fontSize: '20px' }}>
                                                        <Grid size={4} container spacing={2} >
                                                            <Grid size={8} display="flex" justifyContent="left" alignItems="left">
                                                                <span className="text-dark-gray" >
                                                                    {item.text}
                                                                </span>
                                                            </Grid>
                                                            <Grid size={4} display="flex" justifyContent="center" alignItems="center">
                                                                <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} /></div>
                                                            </Grid>

                                                        </Grid>
                                                        <Grid size={8} container spacing={2}>
                                                            <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                                {item.c1}
                                                            </Grid>
                                                            <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                                {item.c2}
                                                            </Grid>
                                                            <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                                {item.c3}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            ) : (
                                                <Box></Box>
                                            )}
                                        </Box>

                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Box></Box>
                        )} */}

                        <Box>
                            {/* <Box sx={{ fontSize: '50px', color: '#fff', marginTop: '50px', fontWeight: '500' }}>Chính sách mở rộng vốn</Box> */}
                            <Box className="text-dark-gray" sx={{ fontSize: '20px', marginTop: '30px', fontWeight: 'bold' }}>Balance</Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* <Grid container spacing={2} >
                                    <Grid size={8} display="flex" justifyContent="left" alignItems="center">
                                        <div className="toggle-buttons-bal">
                                            {dataBalance?.map(item => (
                                                <button key={item.id}
                                                    className={`button-bal ${activeBalance === item ? 'active' : ''}`}
                                                    onClick={showDetaiBalance(item)}
                                                >
                                                    {item.balanceText}
                                                </button>
                                            )
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid size={4} display="flex" justifyContent="right" alignItems="center">
                                        <Box className='compare-btn-bal'>
                                        Quick comparison
                                        </Box>
                                    </Grid>
                                </Grid> */}
                                <Grid container spacing={1.5} sx={{ marginTop: '20px' }} >
                                    {dataBalance.map(item => (
                                        <Box key={item.id} onClick={showDetaiBalance(item)}>
                                            <Grid size="auto" className={`balance-btn ${activeBalance === item ? '' : 'active'} `} >
                                                {item.balanceText}
                                            </Grid>
                                        </Box>
                                    ))}
                                    <Grid size={6}>
                                    </Grid>
                                    {/* <Grid size="grow" className='compare-btn'>
                                        Quick comparison
                                    </Grid> */}
                                </Grid>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                {/* {detail.map(item => (
                                    <Box key={item.id} className={`${item.stt === 1 ? 'itemExamFisrt' : 'itemExam'} ${item.stt % 2 === 0 ? '' : 'active'} `}>
                                        <Grid container spacing={2} sx={{ fontSize: '20px' }}>
                                            <Grid size={4} container spacing={2} >
                                                <Grid size={8} display="flex" justifyContent="left" alignItems="left">
                                                    <span className="text-dark-gray" >
                                                        {item.text}
                                                    </span>
                                                </Grid>
                                                <Grid size={4} display="flex" justifyContent="center" alignItems="center">
                                                    <div className="text-dark-gray pointer"><FontAwesomeIcon icon={faInfoCircle} /></div>
                                                </Grid>

                                            </Grid>
                                            <Grid size={8} container spacing={2}>
                                                <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                    {item.c1}
                                                </Grid>
                                                <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                    {item.c2}
                                                </Grid>
                                                <Grid size={4} display="flex" justifyContent="center" alignItems="center" className={`${item.textColor === true ? 'text-blue' : 'text-white'} `}>
                                                    {item.c3}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))} */}
                                <Grid container spacing={0}>
                                    <Grid size={1.5}>
                                        <Grid container spacing={0} className='step-item-sub text-dark-gray'>
                                            <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                                <Box className='content-table'>Account Balance</Box>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center">
                                            </Grid>
                                            <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{position:'relative'}}>
                                                <div className="text-dark-gray pointer" onClick={() =>toggleDescription("Account Balance", 0)}><FontAwesomeIcon icon={faInfoCircle} /></div>
                                                { showDescription  && indexIconText === 0 &&(<Box className='description-icon-update' ref={descriptionRef}>{descriptionIconText} </Box>)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} className='step-item text-dark-gray'>
                                            <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                                <Box className='content-table'>Balance Target</Box>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center">
                                            </Grid>
                                            <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{position:'relative'}}>
                                                <div className="text-dark-gray pointer" onClick={() =>toggleDescription("Balance Target", 1)}><FontAwesomeIcon icon={faInfoCircle} /></div>
                                                { showDescription  && indexIconText === 1 &&(<Box className='description-icon-update' ref={descriptionRef}>{descriptionIconText} </Box>)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} className='step-item-sub text-dark-gray'>
                                            <Grid display="flex" justifyContent="left" alignItems="left" size="auto">
                                                <Box className='content-table'>Payout ratio</Box>
                                            </Grid>
                                            <Grid display="flex" justifyContent="center" alignItems="center">
                                            </Grid>
                                            <Grid display="flex" justifyContent="right" alignItems="right" size="grow" sx={{position:'relative'}}>
                                                <div className="text-dark-gray pointer" onClick={() =>toggleDescription("Payout ratio", 2)}><FontAwesomeIcon icon={faInfoCircle} /></div>
                                                { showDescription  && indexIconText === 2 &&(<Box className='description-icon-update' ref={descriptionRef}>{descriptionIconText} </Box>)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid size={10.5} sx={{overflow:'auto'}} className="balance-content-mobile">
                                        <Grid container spacing={2} className='step-item-sub item-content text-white' sx={{width: `${30 * countItem}vw`,}}>
                                            {accountBalance.map(item => (
                                                <span key={item}>{formatCurrency(item)}</span>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={2} className='step-item item-content text-white'  sx={{width: `${30 * countItem}vw`,}}>
                                            {balanceTarget.map(item => (
                                                <span key={item}>{formatCurrency(item)}</span>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={2} className='step-item-sub item-content text-white'  sx={{width: `${30 * countItem}vw`,}}>
                                            {payoutRatio.map(item => (
                                                <span >{item}</span>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <Grid size={10.5} className="balance-content-web">
                                        <Grid container spacing={2} className='step-item-sub item-content text-white'>
                                            {accountBalance.map(item => (
                                                <span key={item}>{formatCurrency(item)}</span>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={2} className='step-item item-content text-white' >
                                            {balanceTarget.map(item => (
                                                <span key={item}>{formatCurrency(item)}</span>
                                            ))}
                                        </Grid>
                                        <Grid container spacing={2} className='step-item-sub item-content text-white' >
                                            {payoutRatio.map(item => (
                                                <span >{item}</span>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box>
                            <Grid container spacing={5} columns={12}>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <Box className="payout-container">
                                        <Box sx={{ textAlign: 'center' }}><h2 className="payout-container-title" style={{ margin: '0' }}>Payout Eligibility Requirements</h2></Box>
                                        <Grid container spacing={1}>
                                            <Grid size={8}>
                                                <div className="payout-text">
                                                    <p>Time Requirement: Users are eligible for payout 14 days after receiving their LIVE account</p>
                                                    <Box className={` ${isVisible ? 'scenarios-sub' : 'scenarios-sub-show'} `}>
                                                        <p>Profit Requirement: Users must achieve a minimum trading profit of $100 in their LIVE account to request payout. Achieving this also qualifies them for a refund of the entry fee.</p>
                                                        <p>Trade Profit Limitation: No single trade can account for more than 40% of the total profit generated in the payout period. This rule ensures that profit distribution across trades remains balanced and diversified.</p>
                                                    </Box>
                                                    <Box className="see-more"  >
                                                        <Box onClick={toggleVisibility}>
                                                            {isVisible ? "See more..." : "Close"}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                            <Grid size={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className="payout-image">
                                                    <Box component="img" alt='Verify Email Image' src={`${process.env.PUBLIC_URL}/images/VerifyEmail.png`} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <Box className="payout-container">
                                        <Box sx={{ textAlign: 'center' }}><h2 className="payout-container-title" style={{ margin: '0' }}>Capital Increase Requirements</h2></Box>
                                        <Grid container spacing={1}>
                                            <Grid size={8}>
                                                <div className="payout-text">
                                                    <p>Maximum Capital Expansion: The account's maximum capital can be expanded up to $500,000.</p>
                                                    <Box className={` ${isVisible2 ? 'scenarios-sub' : 'scenarios-sub-show'} `}>
                                                        <p>Profit Requirement: Users must reach a total profit of 10% to qualify for a capital increase.</p>
                                                        <p>Consistent Positive Returns: To move up to the next funding level, users need at least three days with positive returns of 0.5% or more.</p>
                                                        <p>Trade Profit Limitation: No single trade can account for more than 40% of the total profit generated in the payout period. This rule ensures that profit distribution across trades remains balanced and diversified.</p>
                                                    </Box>
                                                    <Box className="see-more"  >
                                                        <Box onClick={toggleVisibility2}>
                                                            {isVisible2 ? "See more..." : "Close"}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                            <Grid size={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className="payout-image">
                                                    <Box component="img" alt='Verify Level Image' src={`${process.env.PUBLIC_URL}/images/VerifyLevel.png`} />
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <PrimaryFooter />
        </Box>
    );
}
export default CapitalUpgrade
