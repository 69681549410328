export const baseTestnetChainId = '0x14a34'; 
export const baseTestnetChainParams = {
    chainId: baseTestnetChainId,
    chainName: 'Base Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.base.org'], 
    blockExplorerUrls: ['https://sepolia-explorer.base.org'],
  };
  
