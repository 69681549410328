import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import "./request-payout.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useSnackbar } from '../SnackbarProvider';
import { ethers, JsonRpcProvider, BrowserProvider } from "ethers";
import Link from '@mui/material/Link';
import api from '../../instance_api_key';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const RequestPayout = () => {
  const showSnackbar = useSnackbar();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [acccountId, setAccountId] = useState("");
    const [account, setAccount] = useState(null);

    const [price, setPrice] = useState(null);

    const [lstPayout, setLstPayout] = useState([]);


    useEffect(() => {
        fetchPayout();
        fetchEthPrice();
    }, []);

    const fetchPayout = () => {
        api.get(`/api/payout/get_v1_payout_by_status/0`)
            .then(response => {
                setLstPayout(response.data);
            })
            .catch(error => console.error(error));
    }

    const fetchEthPrice = async () => {
        try {
            const responseBinance = await api.get(
                'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
            );
            const ethPriceBinance = Number(responseBinance.data.price).toFixed(2);

            const responseOkx = await api.get(
                'https://www.okx.com/api/v5/market/ticker?instId=ETH-USDT'
            );
            const ethPriceOkx = Number(responseOkx.data.data[0].last).toFixed(2);

            setPrice(((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2))
        } catch (err) {

        }
    }

    const approvePayout = async (item) => {
        if (!window.ethereum) {
            alert('MetaMask is required!');
            return;
        }
        const tempProvider = new BrowserProvider(window.ethereum);
        const network = tempProvider && await tempProvider.getNetwork();
        const chainid = network.chainId.toString()
        if (network.name != 'base-sepolia' && chainid != "1301") {
            showSnackbar('Please switch to Base Sepolia or Unichain Sepolia', 'warning');
            return;
        }

        try {

            // Connect to MetaMask
            const provider = new ethers.BrowserProvider(window.ethereum);
            await provider.send('eth_requestAccounts', []); // Request account access
            const signer1 = await provider.getSigner();

            const amt = (item.amount / Number(price)).toFixed(7);
            // Create transaction object
            const tx = {
                to: item.wallet_address,
                value: ethers.parseEther(amt.toString()), // Convert ETH to wei
            };

            // Send the transaction
            const transactionResponse = await signer1.sendTransaction(tx);

            // Wait for transaction confirmation
            const receipt = await transactionResponse.wait();
            axios.post(process.env.REACT_APP_API_URL + '/api/payout/post_v1_payout_update',
                {
                    id: item.id,
                    tx_id: transactionResponse.hash,
                    status: receipt.status
                })
                .then(response => {
                    if (response && response.data && response.data.valid == true) {
                        fetchPayout()
                    }
                })
                .catch(error => console.error(error));
            console.log('Transaction confirmed:', receipt);
        } catch (error) {
            console.error('Error sending transaction:', error);

            if (error.action == 'estimateGas') {
                showSnackbar('The fee balance is insufficient to execute the order.', 'warning');
                return;
            }
        }
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Typography variant="h5" gutterBottom>
                Request Payout
            </Typography>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Action</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Link unichainscan</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lstPayout.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">
                                           {!row?.tx_id && <Button variant="outlined" onClick={() => {approvePayout(row)}}>Approve</Button>} 
                                        </TableCell>
                                        <TableCell >{row.id}</TableCell>
                                        <TableCell >{row.payout_date}</TableCell>
                                        <TableCell sx={{maxWidth:'300px', whiteSpace: "normal", wordWrap: "break-word"}}> 
                                          {row?.tx_id && <Link href={"https://base-sepolia.blockscout.com/tx/" + row.tx_id} target="_blank">{row.tx_id}</Link> }  
                                        </TableCell>
                                        <TableCell >{row.amount}</TableCell>
                                        <TableCell >{row.payout_st}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RequestPayout;
