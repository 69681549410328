import { useState, useEffect } from 'react';
import "./popup.css"; // Optional: Create a CSS file for styling the popup
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ethers, BrowserProvider } from 'ethers';
import { useSnackbar } from '../SnackbarProvider';
import api from '../../instance_api'
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30%",
    bgcolor: '#232323',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '0 20px'
};
const wallet_address_ss = localStorage.getItem('wallet_address');


const Popup = ({ opTakeProfitPrice, opStopLossPrice, checked_sltp, markPrice, opTrading, gasPrice, leverage, price, payAmount, longAmount, TradeLongAmount, activeTrading,entryPrice, handleClose }) => {
    const showSnackbar = useSnackbar();

    const [checked, setChecked] = useState([true, false]);
    const handleChangeCheckbox = (event) => {
        setChecked([event.target.checked, checked[1]]);
    };
    const [txHash, setTxHash] = useState(null);
    const [status, setStatus] = useState(0);

    const [liqPrice, setLiqPrice] = useState(0);

    const [volumeFee, setVolumeFee] = useState(0);
    const [tradingFee, setTradingFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const [popupPrice, setPopupPrice] = useState(price);
    const [popupTradeLongAmount, setPopupTradeLongAmount] = useState(TradeLongAmount);
    const [volumne, setVolumne] = useState(0);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [messageTPS, setMessageTPSL] = useState('');
    const handleOpenModel = () => setConfirm(true);
    const handleCloseModel = () => setConfirm(false);

    useEffect(() => {
        if (activeTrading == 'Long') {
            const value = Number((popupPrice - (popupPrice * leverage / 100)).toFixed(2));
            setLiqPrice(value);
        } else {
            const value = Number((popupPrice + (popupPrice * leverage / 100)).toFixed(2));
            setLiqPrice(value);
        }
        setVolumne(payAmount * leverage);

        api.post('/api/trading_tx/post_v1_trading_transaction_calculator_fee',
            {
                pay_amt: Number(payAmount),
                leverage: Number(leverage),
                trading_type: opTrading == 'Market' ? 0 : 1,
            })
            .then(response => {
                if (response && response.data) {
                    setVolumeFee(response.data.volume_fee);
                    setTradingFee(response.data.trading_fee);
                    setTotalFee(response.data.total_fee);
                }
            })
            .catch(error => console.error(error));
    }, []);


    const GetNoneTp = () => {
        if(activeTrading == 'Long') {
            return (price * 900/100).toFixed(2);
        } else {
            return (price * -900/100).toFixed(2);
        }
    }

    const GetNoneSl = () => {
        if(activeTrading == 'Long') {
            return (price - (price * 75/100)).toFixed(2);
        } else {
            debugger;
            return (price + (price * 75/100)).toFixed(2);
        }
    }

    // Function to send Sepolia ETH
    const sendTransaction = async () => {
        handleCloseModel();
        setLoading(true);
        if (status == 2) {
            showSnackbar('Executing order', 'warning');
            return;
        }
        const fee_amt = (totalFee / popupPrice).toFixed(18);

        if (!window.ethereum) {
            alert('MetaMask is required!');
            return;
        }

        const tempProvider = new BrowserProvider(window.ethereum);
        const network = tempProvider && await tempProvider.getNetwork();
        const chainid = network.chainId.toString()
        if (network.name != 'base-sepolia' && chainid != "1301") {
            showSnackbar('Please switch to Base Sepolia or Unichain Sepolia', 'warning');
            return;
        }

        try {
            setStatus(2); //pending

            // Connect to MetaMask
            const provider = new ethers.BrowserProvider(window.ethereum);
            await provider.send('eth_requestAccounts', []); // Request account access
            const signer1 = await provider.getSigner();

            // Create transaction object
            const tx = {
                to: '0x16b303859738cfade46b00ff6457b923de75ce53',
                value: ethers.parseEther(fee_amt.toString()), // Convert ETH to wei
            };

            // Send the transaction
            const transactionResponse = await signer1.sendTransaction(tx);
            setTxHash(transactionResponse.hash); // Store the transaction hash
            console.log('Transaction sent:', transactionResponse);
            const session_account = sessionStorage.getItem('account');
            api.post(process.env.REACT_APP_API_URL + '/api/trading_tx/post_v1_trading_transaction',
                {
                    wallet_address: wallet_address_ss,
                    account_id: session_account,
                    pay_amt: Number(payAmount),
                    long_amt: Number(longAmount),
                    unit: 'ETH',
                    leverage: leverage,
                    entry_price: Number(popupPrice),
                    liquid_price: Number((popupPrice - (popupPrice * leverage / 100)).toFixed(2)),
                    fee: Number(gasPrice),
                    tx_id: transactionResponse.hash,
                    transaction_type: activeTrading == 'Long' ? 1 : 0,
                    trading_type: opTrading == 'Market' ? 0 : 1,
                    volumn_amt: Number((payAmount * leverage).toFixed(2)),
                    tx_dt: new Date(),
                    fee_total: Number(fee_amt),
                    mark_price: markPrice,
                    stop_loss: checked_sltp == true ? Number(opStopLossPrice).toFixed(2) : GetNoneSl(),
                    take_profit: checked_sltp == true ? Number(opTakeProfitPrice).toFixed(2) : GetNoneTp()
                })
                .then(response => {
                    console.log(response.data);
                    // Optionally, redirect or update the UI
                })
                .catch(error => console.error(error));

            // Wait for transaction confirmation
            const receipt = await transactionResponse.wait();
            api.post(process.env.REACT_APP_API_URL + '/api/trading_tx/post_v1_trading_transaction_update_status',
                {
                    trading_type: opTrading == 'Market' ? 0 : 1,
                    tx_id: transactionResponse.hash,
                    status: receipt.status
                })
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => console.error(error));
            if (receipt.status === 1) {
                setStatus(1);
                handleClose();
            } else {
                setStatus(0);
            }
            console.log('Transaction confirmed:', receipt);
        } catch (error) {
            console.error('Error sending transaction:', error);

            if (error.action == 'estimateGas') {
                showSnackbar('The fee balance is insufficient to execute the order.', 'warning');
                setStatus(0);
                return;
            }
        }
        setLoading(false);

    };

    const checkConfirmSLTP = () => {
        let message = "Confirm not to set ";
        
        if (checked_sltp === false) {
            message = message + "stop loss and take profit!";
            setConfirm(true);
        } else {
            if (opStopLossPrice === 0 || opTakeProfitPrice === 0) {
                if (opStopLossPrice === 0) {
                    message = message + "stop loss!";
                } else {
                    message = message + "take profit!";
                }
                setConfirm(true);
            } else {
                sendTransaction();
            }
        }
        setMessageTPSL(message);

    }

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
      };
    return (
        <div className="popup-box">
            <div className="box">
                <Box sx={{ padding: '20px', fontSize: '30px' }}>
                    <Grid container spacing={2}>
                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                            <Box className='text-white' >
                                Confirm {activeTrading}
                            </Box>
                        </Grid>
                        <Grid display="flex" justifyContent="right" alignItems="center" size="grow">
                            {!loading && 
                            <span className="close-icon" onClick={handleClose}>
                                &times;
                            </span>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box className='popup-content' sx={{ padding: '20px' }}>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ borderBottom: '#a0a0a0 solid 1px', fontSize: '30px', paddingBottom: '40px' }}>
                        <Box>Pay {payAmount} $USDX</Box>
                        <Box sx={{ fontSize: '20px' }}><FontAwesomeIcon icon={faArrowDown} /></Box>
                        {/* <Box>{activeTrading} {longAmount} (${popupTradeLongAmount})</Box> */}
                        <Box>Volume {formatCurrency(volumne)}</Box>
                    </Box>
                    <Box sx={{ borderBottom: '#a0a0a0 solid 1px' }}>
                        <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px', padding: '30px 0' }}>
                            <Grid display="flex" justifyContent="left" alignItems="center" size="auto">
                                <Box sx={{ textAlign: 'left' }}>
                                    <Box className='text-dark-gray' >Leverage</Box>
                                    <Box className='text-dark-gray' >Entry Price</Box>
                                    {/* <Box className='text-dark-gray' >Liq. Price</Box> */}
                                    <Box className='text-dark-gray' >Volume Fee</Box>
                                    {opTrading != 'Limit' && <Box className='text-dark-gray' >Market Fee</Box>} 
                                    <Box className='text-dark-gray' >Total Fee</Box>
                                    {checked_sltp && <Box className='text-dark-gray' >Take Profit</Box>}
                                    {checked_sltp && <Box className='text-dark-gray' >Stop loss</Box>}
                                    {opTrading == 'Limit' && <Box className='text-dark-gray' >Mark Price</Box>}
                                    {/* <Box className='text-dark-gray' >Fees</Box> */}
                                </Box>

                            </Grid>
                            <Grid container spacing={1.2} display="flex" justifyContent="right" alignItems="center" size="grow">
                                <Box sx={{ textAlign: 'end' }}>

                                    <Box className='text-white'>{leverage}x</Box>
                                    <Box className='text-white'>{entryPrice}$</Box>
                                    {/* <Box className='text-white'>{liqPrice}$</Box> */}
                                    <Box className='text-white'>{volumeFee}$ ~ {(volumeFee / popupPrice).toFixed(5)} ETH</Box>
                                    {opTrading != 'Limit' && <Box className='text-white'>{tradingFee}$ ~ {(tradingFee / popupPrice).toFixed(5)} ETH</Box>} 
                                    <Box className='text-white'>{totalFee}$ ~ {(totalFee / popupPrice).toFixed(5)} ETH</Box>
                                    {checked_sltp && <Box className='text-white'>{opTakeProfitPrice}$</Box>}
                                    {checked_sltp && <Box className='text-white'>{opStopLossPrice}$</Box>}
                                    {opTrading == 'Limit' && <Box className='text-white'>${markPrice}</Box>}
                                    {/* <Box className='text-white'>{gasPrice} $ETH</Box> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <Box  >
                    <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px', padding:'30px 0' }}>
                        <Grid display="flex" justifyContent="left" alignItems="center" size="auto">
                            <Box sx={{ textAlign: 'left' }}>
                                <Box className='text-dark-gray' >Spread</Box>
                                <Box className='text-dark-gray' >Entry Price</Box>
                                <Box className='text-dark-gray' >Borrow Fee</Box>
                                <Box className='text-dark-gray' >Execution Fee</Box>
                                <Box className='text-dark-gray' >Allowed Slippage</Box>
                                <Box className='text-dark-gray' >Allow up to 1% slippage</Box>
                            </Box>

                        </Grid>
                        <Grid container spacing={1.2} display="flex" justifyContent="right" alignItems="center" size="grow">
                            <Box sx={{ textAlign: 'end' }}>
                                <Box className='text-white'>0.00%</Box>
                                <Box className='text-white'>${price}</Box>
                                <Box className='text-white'>0.0038% / 1h</Box>
                                <Box className='text-white'>0.0001 ETH</Box>
                                <Box className='text-white'>0.30%</Box>
                                <Box >
                                    <FormControlLabel
                                        sx={{ color: '#fff' }}
                                        label=""
                                        control={<Checkbox checked={checked[0]} onChange={handleChangeCheckbox} />}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box> */}
                    {!loading ?
                        <Box sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#3e63dd', color: '#fff', fontSize: '20px', borderRadius: '20px', cursor: 'pointer' }} onClick={checkConfirmSLTP}>

                            <Grid display="flex" justifyContent="center" alignItems="center" >
                                <Box>
                                    {activeTrading}
                                </Box>

                            </Grid>


                        </Box>
                        :
                        <Box sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#ddd', color: '#fff', fontSize: '20px', borderRadius: '20px', cursor: 'pointer' }}>

                            <Grid display="flex" justifyContent="center" alignItems="center">
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    sx={{ backgroundColor: '#ddd' }}
                                >
                                    Loading
                                </LoadingButton>

                            </Grid>
                        </Box>
                    }
                    <div>
                        <Modal
                            open={confirm}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className="popup-confirm-style">
                                <Box sx={{ padding: '20px 0 '}}>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <CheckIcon className="check-icon" />
                                    </Grid>
                                </Box>
                                <Box className='popup-content-change-sltp'>
                                    <Grid className='text-white'  container spacing={2} sx={{fontSize:'20px', fontWeight:'600'}}>
                                        {messageTPS}
                                    </Grid>
                                </Box>
                                <Box sx={{ marginTop: '20px', padding: '20px 0 ', borderTop: '1px solid #a0a0a0 ' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box className="confirm-model-button" onClick={sendTransaction}>
                                            Confirm
                                        </Box>
                                        <Box className="cancel-model-button" onClick={handleCloseModel}>
                                            Cancel
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Modal>
                    </div>
                </Box>
            </div>
        </div>
    );
};

export default Popup;
