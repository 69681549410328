import React from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";


// Recursive TreeItem component 
const RecursiveTreeItem = ({ node }) => {
  const navigate = useNavigate();

  const handleNavigateClick = () => {
    if (node.route) {
      navigate(node.route);
    }
  };

  return (
    <TreeItem
      itemId={node.id}
      key={node.id}
      nodeid={node.id}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {node.icon}
          <Box sx={{ marginLeft: 1 }}>{node.label}</Box>
        </Box>
      }
      onClick={handleNavigateClick}
    >
      {node.children && node.children.length > 0 && node.children.map(child => (
        <RecursiveTreeItem key={child.id} node={child} />
      ))}
    </TreeItem>
  );
};

const TreeViewComponent = ({ data }) => {
  return (
    <SimpleTreeView>
      {data.map(node => (
        <RecursiveTreeItem key={node.id} node={node} />
      ))}
    </SimpleTreeView>
  );
};

export default TreeViewComponent;
