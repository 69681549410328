// src/globalVariableSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: "",  // set your initial global variable value here
};

const globalVariableSlice = createSlice({
  name: 'globalVariable',
  initialState,
  reducers: {
    setGlobalVariable: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setGlobalVariable } = globalVariableSlice.actions;
export default globalVariableSlice.reducer;
