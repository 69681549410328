import {
    Box, Toolbar, Tabs, Tab, Typography, Button, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import "./competition.style.css";

import * as React from 'react';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import BreadcrumbsNav from "../Breadcrumbs"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Competition = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [showInfo, setShowInfo] = React.useState(0);

    const handleInfo = (info) => {
        console.log(info)
        setShowInfo(info)
    }

    function createData(rank, trader_name, plan_name, cash_prize) {
        return { rank, trader_name, plan_name, cash_prize };
    }

    const rows = [
        createData(883, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(884, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(885, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(886, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(887, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(888, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(889, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(890, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(891, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),
        createData(892, 'Joshua A.', 'Stellar 1-Step 50K', '$782.01'),

    ];

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Competition
                    </Typography>
                </Grid>
                {showInfo == 0 ?
                    (
                        <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Competition List" {...a11yProps(0)} />
                                        <Tab label="Upcoming" {...a11yProps(1)} />
                                        <Tab label="In Progress" {...a11yProps(2)} />
                                        <Tab label="Finished" {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive1.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-upcoming">Upcoming</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}
                                                        onClick={() => {
                                                            handleInfo(1)
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive1.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-upcoming">Upcoming</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive2.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-inprogress">Inprogress</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive1.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-upcoming">Upcoming</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive1.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-upcoming">Upcoming</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive2.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-inprogress">Inprogress</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Details</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={3}>
                                    <div style={{ display: 'flex' }}>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="competition-item-main">
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                                <div style={{ width: '70%', fontWeight: 'bold', fontSize: '20px' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-achive3.png`} />
                                                    &nbsp;
                                                    Octerber competition’ 24
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className="competition-item-child1-status-finished">Finished</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '70%' }}>
                                                    <img src={`${process.env.PUBLIC_URL}/images/competition-timer.png`} />
                                                    &nbsp;
                                                    Jun 8, 2024 - Jun 24, 2024 (GMT+3:00)
                                                </div>
                                                <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <img style={{ width: '24px', height: '15px', marginRight: '7px' }} src={`${process.env.PUBLIC_URL}/images/competition-people.png`} />
                                                    9125
                                                </div>
                                            </div>
                                            <div className="competition-item-child2">
                                                <div style={{ width: '30%' }}>
                                                    <div className="competition-item-child2-logo"><FontAwesomeIcon icon={faMoneyBill} size="2xl" /></div>
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <div style={{ fontSize: '16px' }}>Prize Pool</div>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>$30,000+ Cash & 2.75 worth of Chalenge Account</div>
                                                </div>
                                            </div>
                                            <div className="competition-item-child3">
                                                3 Days : 3 Hours : 34 Min : 32 Sec
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/competition-winner.png`} />&nbsp;
                                                        Winner</Button>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                    <Button variant="contained"
                                                        sx={{
                                                            backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'darkgray' },
                                                            width: '95%'
                                                        }}>
                                                        Enroll</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </Grid>
                    ) : (

                        <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                            <Typography variant="h6" gutterBottom>
                                Octerber competition’ 24
                            </Typography>
                            <Box sx={{ width: '100%', marginTop: '20px', display: 'flex' }}>
                                <div style={{ display: 'flex', width: 'max-content' }}>
                                    <div style={{ margin: '5px 15px', padding: '15px', borderRadius: '24px', background: 'white' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><img src={`${process.env.PUBLIC_URL}/images/competition-rank2.png`} /></div>
                                        <div style={{ textAlign: 'center' }}>2nd</div>
                                        <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>$30,000 in cash</div>
                                        <div style={{ textAlign: 'center' }}> Stellar 1-Step 50K</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: 'max-content' }}>
                                    <div style={{ margin: '5px 15px', padding: '15px', borderRadius: '24px', background: 'white' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><img src={`${process.env.PUBLIC_URL}/images/competition-rank3.png`} /></div>
                                        <div style={{ textAlign: 'center' }}>2nd</div>
                                        <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>$30,000 in cash</div>
                                        <div style={{ textAlign: 'center' }}> Stellar 1-Step 50K</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: 'max-content' }}>
                                    <div style={{ margin: '5px 15px', padding: '15px', borderRadius: '24px', background: 'white' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><img src={`${process.env.PUBLIC_URL}/images/competition-rank1.png`} /></div>
                                        <div style={{ textAlign: 'center' }}>2nd</div>
                                        <div style={{ textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>$30,000 in cash</div>
                                        <div style={{ textAlign: 'center' }}> Stellar 1-Step 50K</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '5px 15px', padding: '15px', borderRadius: '24px', background: 'white', border: '1px dotted' }}>
                                        <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px' }}>
                                            Here are the rules
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img style={{ marginRight: '20px' }} src={`${process.env.PUBLIC_URL}/images/competition-rule1.png`} />
                                            The Drawdown will be calculated based on balance
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img style={{ marginRight: '20px' }} src={`${process.env.PUBLIC_URL}/images/competition-rule1.png`} />
                                            The maximum daily loss limit is 5%.
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img style={{ marginRight: '20px' }} src={`${process.env.PUBLIC_URL}/images/competition-rule1.png`} />
                                            The maximum overall loss limit is 10%.
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img style={{ marginRight: '20px' }} src={`${process.env.PUBLIC_URL}/images/competition-rule1.png`} />
                                            Participants must meet a minimum trading requirement of 5 trading days.
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <Typography variant="subtitle1" gutterBottom>
                                Plan Name
                            </Typography>
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="All"
                                        inputProps={{ 'aria-label': 'all' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Box>
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="right">Rank</TableCell>
                                                    <TableCell align="right">Trader Name</TableCell>
                                                    <TableCell align="right">Plan Name</TableCell>
                                                    <TableCell align="right">Cashprize</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="right">{row.rank}</TableCell>
                                                        <TableCell align="right">{row.trader_name}</TableCell>
                                                        <TableCell align="right">{row.plan_name}</TableCell>
                                                        <TableCell align="right">{row.cash_prize}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Box>
                        </Grid>
                    )}
            </Grid>
        </Box>
    );
};
export default Competition
