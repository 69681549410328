import { Box, Toolbar, Typography } from "@mui/material";

const DashBoardProfile = () => {

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Typography paragraph>
          Welcom to X1K DashBoard
        </Typography>
      </Box>
    );
};

export default DashBoardProfile