import './index.css';
import '../../App.css';
import { Box, Slider, Switch, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Tab, Tabs, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from "axios";
import { formatUnits, BrowserProvider, ethers } from 'ethers';
import { Alchemy, Network } from 'alchemy-sdk';
import Popup from "./popup"; // Import the Popup component
import ChangeSltp from "./changeSlTp"; // Import the Popup component
import PropTypes, { element } from 'prop-types';
import { useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalVariable } from '../../globalVariableSlice';
import api from '../../instance_api'
import api_no_auth from '../../instance_api_no_authentication'
import { useSnackbar } from '../SnackbarProvider';
import PopupLogin from "../login";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const LauchApp = ({ reload }) => {
    const [session_account, setsession_account] = useState('');

    const getSessionAccount = () => {
        const account = sessionStorage.getItem('account');
        setsession_account(account);
        return account;
    }

    const globalVariable = useSelector((state) => state.globalVariable.value);
    const dispatch = useDispatch();

    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'white',          // White text
            backgroundColor: '#232323',
            border: '#333',
        }),
        singleValue: (base) => ({
            ...base,
            color: 'white',
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#333',  // Dark background for dropdown
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#666' : '#333',
            color: 'white',
            padding: 10,
        }),
    };

    const showSnackbar = useSnackbar();

    const [activeTrading, setActiveTrading] = useState('Long');
    const showTrading = (step, active, text) => () => {
        setActiveTrading(text);
        setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === text))
        setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === text))
    };

    const [opTrading, setOpTrading] = useState('Market');
    const changeOpTrading = (text) => () => {
        setOpTrading(text);
        if (text == 'Market') {
            setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === activeTrading));
            setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === activeTrading));
            setOpStopLossPrice(price);
            setOpTakeProfitPrice(price);
        } else {
            setListOpStopLoss(dataOptionsStopLoss?.filter(x => x.type === activeTrading));
            setListOpTakeProfit(dataOptionsTakeProfit?.filter(x => x.type === activeTrading));
            setOpStopLossPrice(price);
            setOpTakeProfitPrice(price);
        }
    };
    const dataOptionsStopLoss = [
        { id: 2, value: 'None', type: 'Long' },
        { id: 3, value: '-10%', type: 'Long' },
        { id: 4, value: '-25%', type: 'Long' },
        { id: 5, value: '-50%', type: 'Long' },
        { id: 6, value: '-75%', type: 'Long' },
        { id: 7, value: 'None', type: 'Short' },
        { id: 8, value: '10%', type: 'Short' },
        { id: 9, value: '25%', type: 'Short' },
        { id: 10, value: '50%', type: 'Short' },
        { id: 11, value: '75%', type: 'Short' },
    ];
    const [listOpStopLoss, setListOpStopLoss] = useState(dataOptionsStopLoss?.filter(x => x.type === "Long"));
    const [opStopLoss, setOpStopLoss] = useState(dataOptionsStopLoss[0].value);
    const [opStopLossPrice, setOpStopLossPrice] = useState(0);
    const changeOpStopLoss = (text) => () => {
        setOpStopLoss(text);
        switch (text) {
            case 'None': { setOpStopLossPrice(Number(price)); } break;
            case '-10%': { setOpStopLossPrice((Number(price) - Number(price * 10 / 100)).toFixed(2)); } break;
            case '-25%': { setOpStopLossPrice((Number(price) - Number(price * 25 / 100)).toFixed(2)); } break;
            case '-50%': { setOpStopLossPrice((Number(price) - Number(price * 50 / 100)).toFixed(2)); } break;
            case '-75%': { setOpStopLossPrice((Number(price) - Number(price * 75 / 100)).toFixed(2)); } break;
            case '10%': { setOpStopLossPrice((Number(price) + Number(price * 10 / 100)).toFixed(2)); } break;
            case '25%': { setOpStopLossPrice((Number(price) + Number(price * 25 / 100)).toFixed(2)); } break;
            case '50%': { setOpStopLossPrice((Number(price) + Number(price * 50 / 100)).toFixed(2)); } break;
            case '75%': { setOpStopLossPrice((Number(price) + Number(price * 75 / 100)).toFixed(2)); } break;
        }
    };

    const dataOptionsTakeProfit = [
        { id: 2, value: 'None', type: 'Long' },
        { id: 3, value: '10%', type: 'Long' },
        { id: 4, value: '25%', type: 'Long' },
        { id: 5, value: '50%', type: 'Long' },
        { id: 6, value: '75%', type: 'Long' },
        { id: 7, value: 'None', type: 'Short' },
        { id: 8, value: '-10%', type: 'Short' },
        { id: 9, value: '-25%', type: 'Short' },
        { id: 10, value: '-50%', type: 'Short' },
        { id: 11, value: '-75%', type: 'Short' },
    ];
    const [listOpTakeProfit, setListOpTakeProfit] = useState(dataOptionsTakeProfit?.filter(x => x.type === "Long"));
    const [opTakeProfit, setOpTakeProfit] = useState(dataOptionsTakeProfit[0].value);
    const [opTakeProfitPrice, setOpTakeProfitPrice] = useState(0);

    const changeOpTakeProfit = (text) => () => {
        setOpTakeProfit(text);
        switch (text) {
            case 'None': { setOpTakeProfitPrice(Number(price)); } break;
            case '10%': { setOpTakeProfitPrice((Number(price) + Number(price * 10 / 100)).toFixed(2)); } break;
            case '25%': { setOpTakeProfitPrice((Number(price) + Number(price * 25 / 100)).toFixed(2)); } break;
            case '50%': { setOpTakeProfitPrice((Number(price) + Number(price * 50 / 100)).toFixed(2)); } break;
            case '75%': { setOpTakeProfitPrice((Number(price) + Number(price * 75 / 100)).toFixed(2)); } break;
            case '-10%': { setOpTakeProfitPrice((Number(price) - Number(price * 10 / 100)).toFixed(2)); } break;
            case '-25%': { setOpTakeProfitPrice((Number(price) - Number(price * 25 / 100)).toFixed(2)); } break;
            case '-50%': { setOpTakeProfitPrice((Number(price) - Number(price * 50 / 100)).toFixed(2)); } break;
            case '-75%': { setOpTakeProfitPrice((Number(price) - Number(price * 75 / 100)).toFixed(2)); } break;
        }
    };

    //tranding

    const [payAmount, setPayAmount] = useState("0");
    const [trendPayAmount, setTrendPayAmount] = useState("0.00");
    const changePayAmount = (e) => {
        if (isNaN(e.target.value)) {
            setValue(1);
            return;
        }
        if (e.target.value > account?.current_balance) {
            setValue(account?.current_balance);
            return;
        }
        if (e.target.value < 0) {
            setValue(1);
            return;
        }
        setPayAmount(e.target.value);

        changeEnterPrice(e.target.value, value);
    };

    const changeEnterPrice = (pay, leverage) => {
        let b_volumne = (pay * leverage) ?? 0;
        let b_enterPrice = 0;
        if (b_volumne <= 1000) {
            b_enterPrice = ((b_volumne * 0.011) / 100)?.toFixed(4);
        } else if (b_volumne > 1000 && b_volumne <= 5000) {
            b_enterPrice = ((b_volumne * 0.015) / 100)?.toFixed(4);
        } else if (b_volumne > 5000 && b_volumne <= 10000) {
            b_enterPrice = ((b_volumne * 0.02) / 100)?.toFixed(4);
        } else if (b_volumne > 10000 && b_volumne <= 20000) {
            b_enterPrice = ((b_volumne * 0.025) / 100)?.toFixed(4);
        } else {
            b_enterPrice = ((b_volumne * 0.03) / 100)?.toFixed(4);
        }
        setEntryPrice(parseFloat(b_enterPrice));
    }

    const [markPrice, setMarkPrice] = useState("0");
    const handleMarkChange = (e) => {
        if (isNaN(e.target.value)) {
            setMarkPrice(1);
            return;
        }
        if (e.target.value < 1) {
            setMarkPrice(1);
            return;
        }
        setMarkPrice(e.target.value);
    };

    const [longAmount, setLongAmount] = useState(0);
    const [trendLongAmount, setTrendLongAmount] = useState("0.00");
    const changeLongAmount = (e) => {
        if (payAmount && payAmount > 0) {
            const leverage = (e.target.value * price / payAmount).toFixed(2);
            setValue(leverage);
        }
        setLongAmount(e.target.value);
    };

    const [priceAmount, setPriceAmount] = useState(0);
    const [trendPriceAmount, setTrendPriceAmount] = useState("0.00");
    const changePriceAmount = (e) => {
        if (payAmount && payAmount > 0) {
            const leverage = (e.target.value * price / payAmount).toFixed(2);
            setValue(leverage);
        }
        setPriceAmount(e.target.value);
    };



    const formatOptionLabel = ({ label, imageUrl }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={imageUrl} alt={label} style={{ width: 25, marginRight: 10 }} />
            {label}
        </div>
    );

    const [checked, setChecked] = React.useState(false);
    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked);
    };



    const [value, setValue] = useState(1);

    const handleSliderChange = (event, newValue) => {
        if (payAmount && payAmount > 0) {
            const long_amt = ((value * payAmount) / price).toFixed(2);
            setLongAmount(long_amt);
        }
        setValue(newValue);
        changeEnterPrice(payAmount, newValue);

    };

    const handleLeverageChange = (newValue) => {
        if (isNaN(newValue.target.value)) {
            setValue(1);
            return;
        }
        if (newValue.target.value > 100) {
            setValue(100);
            return;
        }
        if (newValue.target.value < 1) {
            setValue(1);
            return;
        }
        if (payAmount && payAmount > 0) {
            const long_amt = ((newValue.target.value * payAmount) / price).toFixed(2);
            setLongAmount(long_amt);
        }
        setValue(newValue.target.value);
        changeEnterPrice(payAmount, newValue.target.value);

    };


    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const [price, setPrice] = useState(0);
    const [gasPrice, setGasPrice] = useState(0);
    const [entryPrice, setEntryPrice] = useState(0);
    const [trade_tx, setTradeTx] = useState([]); //Tab Position
    const [trade_tx_order, setTradeTxOrder] = useState([]); //Tab Order
    const [trade_tx_history, setTradeTxHistory] = useState([]); //Tab History

    const [account, setAccount] = useState(null);
    useEffect(() => {
        console.log('globalVariable', globalVariable);
        fetchEthPrice();
        fetchGasPrice();
    }, []);

    useEffect(() => {
        const session_account_reload = sessionStorage.getItem('account');
        if (session_account_reload) {

            GetAccountById(session_account_reload);
            GetTradingTxByAccountId(session_account_reload);
        }
    }, [isOpen]);

    const GetAccountById = async (session_account_reload) => {
        const response = await api.get('/api/account/get_v1_account_by_id/' + session_account_reload);
        setEquity(response.data.current_balance);
        setAccount(response.data);
    }

    const GetTradingTxByAccountId = async (session_account_reload) => {
        GetTradingPosition(session_account_reload);
        GetTradingOrder(session_account_reload);
        GetTradingHistory(session_account_reload);
    }

    const GetTradingPosition = async (session_account_reload) => {
        const responsePosition = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/0');
        setTradeTx(responsePosition.data);
    }

    const GetTradingOrder = async (session_account_reload) => {
        const responseOrder = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/1');
        setTradeTxOrder(responseOrder.data);
    }

    const GetTradingHistory = async (session_account_reload) => {
        const responseHistory = await api.get('/api/trading_tx/get_v1_trading_tx_by_account_id/' + session_account_reload + '/2');
        setTradeTxHistory(responseHistory.data);
    }

    const handleChange = (selectedOption) => {
        console.log(`Option selected:`, selectedOption)
        if (selectedOption.value == "ETH") {
            fetchEthPrice();
        }
        // if (selectedOption.value == "BTC") {
        //     fetchBtcPrice();
        // }
    };

    // Function to fetch the gas price
    const fetchGasPrice = async () => {
        try {
            const settings = {
                apiKey: 'DsV1HF92uDr_nVZqSnhhQGdM3o6pTIGG', // Replace with your Alchemy API key
                network: Network.BASE_SEPOLIA,   // Specify the network (Sepolia in this case)
            };
            const alchemy = new Alchemy(settings);
            const currentGasPrice = await alchemy.core.getGasPrice();
            const etherGasPrice = formatUnits(currentGasPrice.toString(), 'ether');
            setGasPrice(etherGasPrice); // Convert BigNumber to string
            // fetchGasPrice1();
        } catch (error) {
            console.error('Error fetching gas price:', error);
        }
    };

    const fetchEthPrice = async () => {
        try {
            const responseBinance = await axios.get(
                'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
            );
            const ethPriceBinance = Number(responseBinance.data.price).toFixed(2);

            const responseOkx = await axios.get(
                'https://www.okx.com/api/v5/market/ticker?instId=ETH-USDT'
            );
            const ethPriceOkx = Number(responseOkx.data.data[0].last).toFixed(2);


            setPrice(((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2))

            setOpStopLossPrice(((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2));
            setOpTakeProfitPrice(((ethPriceBinance * 3 + ethPriceOkx * 2) / 5).toFixed(2));

        } catch (err) {

        }
    }

    const marks = [
        {
            value: 1.1,
            label: '1.1x',
        },
        {
            value: 10,
            label: '10x',
        },
        {
            value: 15,
            label: '15x',
        },
        {
            value: 25,
            label: '25x',
        },
        {
            value: 35,
            label: '35x',
        },
        {
            value: 50,
            label: '50x',
        },
        {
            value: 60,
            label: '60x',
        },
        {
            value: 70,
            label: '70x',
        },
        {
            value: 80,
            label: '80x',
        },
        {
            value: 90,
            label: '90x',
        },
        {
            value: 100,
            label: '100x',
        },
    ];

    const [isOn, setIsOn] = useState(false);
    const handleSwitchChange = (event) => {
        setIsOn(event.target.checked);
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        changeTabTxData(newValue);
    };

    const changeTabTxData = async (tabIndex) => {
        switch (tabIndex) {
            case 0: {
                GetTradingPosition(getSessionAccount());
            }; break;
            case 1: {
                GetTradingOrder(getSessionAccount());
            }; break;
            case 2: {
                GetTradingHistory(getSessionAccount());
            }; break;
        }
    }


    const handleCloseTx = async (item, type) => {

        const trading_fee_close_positon = (0.05 / 100 * item.size / price).toFixed(7);

        if (!window.ethereum) {
            alert('MetaMask is required!');
            return;
        }

        const tempProvider = new BrowserProvider(window.ethereum);
        const network = tempProvider && await tempProvider.getNetwork();
        const chainid = network.chainId.toString()
        if (network.name != 'base-sepolia' && chainid != "1301") {
            showSnackbar('Please switch to Base Sepolia or Unichain Sepolia', 'warning');
            return;
        }

        try {

            // Connect to MetaMask
            const provider = new ethers.BrowserProvider(window.ethereum);
            await provider.send('eth_requestAccounts', []); // Request account access
            const signer1 = await provider.getSigner();

            // Create transaction object
            const tx = {
                to: '0x16b303859738cfade46b00ff6457b923de75ce53',
                value: ethers.parseEther(trading_fee_close_positon.toString()), // Convert ETH to wei
            };

            // Send the transaction
            const transactionResponse = await signer1.sendTransaction(tx);
            console.log('Transaction sent:', transactionResponse);
            const session_account = sessionStorage.getItem('account');

            // Wait for transaction confirmation
            const receipt = await transactionResponse.wait();
            if (receipt.status == 1) {
                api.post('/api/trading_tx/post_v1_trading_transaction_close_posotion',
                    {
                        tx_id: item.id,
                        trading_type: type,
                        pnl: Number(GetPnl(item) ?? 0),
                        exit_price: price
                    })
                    .then(response => {
                        api.get(`/api/account/get_v1_account_by_id/${session_account}`)
                            .then(response1 => {
                                setAccount(response1.data);
                            })
                            .catch(error => console.error(error));
                        if (type === 0) {
                            GetTradingPosition(session_account);
                        } else if (type === 1) {
                            GetTradingOrder(session_account);
                        } else {
                            GetTradingHistory(session_account);
                        }

                    })
                    .catch(error => console.error(error));
            } else {
                showSnackbar('Error', 'warning');
            }
            console.log('Transaction confirmed:', receipt);
        } catch (error) {
            console.error('Error sending transaction:', error);

            if (error.action == 'estimateGas') {
                showSnackbar('The fee balance is insufficient to execute the order.', 'warning');
                return;
            }
        }
    }

    const location = useLocation();
    const container = useRef();
    let isEffectExecuted = false;
    useEffect(() => {
        if (!isEffectExecuted) {
            // Chạy logic ở đây
            showChart();

            isEffectExecuted = true;
        }
    }, [location.pathname]);
    const showChart = () => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
            {
              "width": "100%",
              "height": "610",
              "symbol": "BINANCE:ETHUSD",
              "interval": "1",
              "timezone": "Etc/UTC",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "allow_symbol_change": true,
              "calendar": false,
              "allow_symbol_change" : false,
              "support_host": "https://www.tradingview.com"
            }`;
        container.current.appendChild(script);
    }
    const handleStoplossInputChange = (event) => {
        setOpStopLossPrice(Number(event.target.value));
    };
    const handleTakeProfitInputChange = (event) => {
        setOpTakeProfitPrice(Number(event.target.value));
    };

    const [sltpTradingId, setSltpTradingId] = useState(null);
    const [sltpStoplossValue, setSltpStoplossValue] = useState(null);
    const [sltpTakeProfitValue, setSltpTakeProfitValue] = useState(null);
    const [sltpPopup, setSltpPopup] = useState(false);
    const handleEditPopup = (item) => {
        if (sltpPopup === false) {
            setSltpTradingId(item.id);
            setSltpStoplossValue(item.stop_loss);
            setSltpTakeProfitValue(item.take_profit);
            setSltpPopup(!sltpPopup);
        } else {
            setSltpTradingId(0);
            setSltpStoplossValue(0);
            setSltpTakeProfitValue(0);
            setSltpPopup(!sltpPopup);
            GetTradingPosition(getSessionAccount());
        }
    }

    useEffect(() => {
        // Kết nối tới WebSocket của Binance để lấy giá ETH/USDT
        const ws = new WebSocket('wss://stream.binance.com:9443/ws/ethusdt@trade');

        // Khi có dữ liệu mới từ WebSocket, cập nhật giá
        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setPrice(parseFloat(data.p).toFixed(2)); // Chuyển đổi giá thành số có 2 chữ số sau dấu phẩy
        };

        // Đóng WebSocket khi component bị hủy
        return () => ws.close();
    }, []);

    const [fundingRate, setFundingRate] = useState(null);
    const fetchFundingRate = async () => {
        try {
            const response = await fetch(
                'https://fapi.binance.com/fapi/v1/fundingRate?symbol=ETHUSDT'
            );
            const data = await response.json();
            if (data && data.length > 0) {
                setFundingRate((parseFloat(data[0].fundingRate) * 100).toFixed(5)); // Đổi thành %
            }
        } catch (error) {
            console.error('Lỗi khi lấy funding rate:', error);
        }
    };


    useEffect(() => {
        // Gọi hàm fetch lần đầu và sau đó cứ mỗi 1 giờ gọi lại
        fetchFundingRate();
        const interval = setInterval(fetchFundingRate, 360000);

        // Hủy interval khi component bị hủy
        return () => clearInterval(interval);
    }, []);

    const GetPnl = (item) => {
        const count_hour = calculateHoursBetweenDates(new Date(), parseDateString(item.tx_dt));
        if (item.transaction_type == 'Long') {
            const pnl_value = (Number(price) - item.entry_price) * (item.pay_amt / item.entry_price * item.leverage)
                - (item.pay_amt * item.leverage * 0.0002 * count_hour) - (item.pay_amt * item.leverage * Number(fundingRate / 100));
            return Number(pnl_value).toFixed(2);
        } else {
            const pnl_value = (item.entry_price - Number(price)) * (item.pay_amt / item.entry_price * item.leverage)
                - (item.pay_amt * item.leverage * 0.0002 * count_hour) - (item.pay_amt * item.leverage * Number(fundingRate / 100));
            return Number(pnl_value).toFixed(2);
        }
    }

    function calculateHoursBetweenDates(date1, date2) {
        // Chuyển đổi các đối tượng Date về mili giây
        const timeDiff = Math.abs(date2 - date1);

        // Tính số giờ từ mili giây
        const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

        return hoursDiff;
    }

    function parseDateString(dateString) {
        // Tách ngày, tháng, năm, giờ, phút, giây từ chuỗi định dạng dd/MM/yyyy HH:mm:ss
        const [datePart, timePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);

        // Tạo đối tượng Date, lưu ý tháng - 1 vì tháng bắt đầu từ 0 trong Date
        return new Date(year, month - 1, day, hours, minutes, seconds);
    }

    const [volume, setVolume] = useState(0);
    const [longOpenInterest, setLongOpenInterest] = useState(0);
    const [shortOpenInterest, setShortOpenInterest] = useState(0);
    const [longPercentOpenInterest, setLongPercentOpenInterest] = useState(0);
    const [shortPercentOpenInterest, setShortPercentOpenInterest] = useState(0);

    const GetAccLauchappInfo = async () => {
        const response = await api_no_auth.get('/api/account/get_v1_account_launchapp_info');
        if (response.data) {
            setVolume(response.data?.volume_24h);
            setLongOpenInterest(response.data?.long_open_interest);
            setShortOpenInterest(response.data?.short_open_interest);
            setLongPercentOpenInterest(response.data?.long_percent_open_interest);
            setShortPercentOpenInterest(response.data?.short_percent_open_interest);
        }
    }
    useEffect(() => {
        // Gọi hàm fetch lần đầu và sau đó cứ mỗi 1 giờ gọi lại
        GetAccLauchappInfo();
        const interval2 = setInterval(GetAccLauchappInfo, 360000);

        // Hủy interval khi component bị hủy
        return () => clearInterval(interval2);
    }, []);
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount).replace('$', '$').replace(/,/g, '.'); // Replace comma with dot
    };

    const [equity, setEquity] = useState(0);
    const [totalLossLeft, setTotalLossLeft] = useState(0);
    const [dailyLossLeft, setDailyLossLeft] = useState(0);

    useEffect(() => {
        if (getSessionAccount() != null && (trade_tx_order && trade_tx_order.length > 0)) {
            trade_tx_order.forEach(element => {
                // if ((element.transaction_type == 'Long' && element.mark_price <= Number(price))
                //     || (element.transaction_type == 'Short' && element.mark_price >= Number(price))) {
                //     MatchingOrder(element);
                // }

                if ((element.transaction_type == 'Long' && element.mark_price == Number(price))
                    || (element.transaction_type == 'Short' && element.mark_price == Number(price))) {
                    MatchingOrder(element);
                }
            });
        }
        if (getSessionAccount() != null && (trade_tx && trade_tx.length > 0)) {
            trade_tx.forEach(element => {
                if (element.transaction_type == "Long") {
                    if (element.stop_loss && element.stop_loss >= price) {
                        MatchingSltp(element, 0)
                    }
                    if (element.take_profit && element.take_profit <= price) {
                        MatchingSltp(element, 1)
                    }
                } else {
                    if (element.stop_loss && element.stop_loss <= price) {
                        MatchingSltp(element, 0)
                    }
                    if (element.take_profit && element.take_profit >= price) {
                        MatchingSltp(element, 1)
                    }
                }

            });
        }

        if (trade_tx && trade_tx.length > 0) {
            let sum_pnl = 0;
            let sum_pay = 0;
            trade_tx.forEach(element => {
                sum_pnl += Number(GetPnl(element));
                sum_pay += Number(GetPnl(element)) + element.pay_amt;
            });
            const equity_amt = Number(account.current_balance) + Number(sum_pnl)
            setEquity(equity_amt);
            // const totalLossLeft_amt = (Number(account.current_balance) - Number(account.balance) + Number(sum_pay)).toFixed(2);
            // if (totalLossLeft_amt < 0) {
            //     setTotalLossLeft((Number(account.balance) * 1/10) + Number(sum_pnl));
            // }
            if (sum_pnl < 0) {
                setDailyLossLeft((Number(sum_pnl) * -1).toFixed(2));
            }
            if ((sum_pay + account.current_balance - account.balance) < 0) {
                setTotalLossLeft((Number(sum_pay + account.current_balance - account.balance) * -1).toFixed(2));
            }

        }
    }, [price]);

    const MatchingOrder = async (item) => {
        const response = await api.post('/api/trading_tx/post_v1_trading_transaction_matching_order', {
            trading_tx_order_id: item.id
        });
        if (response && response.data && response.data.valid == 1) {
            showSnackbar(response.data.messages, 'success');
            GetTradingOrder(getSessionAccount());
        } else {
            showSnackbar(response.data.messages, 'error');
        }
    }

    const MatchingSltp = async (item, sltp) => {
        const response = await api.post('/api/trading_tx/post_v1_trading_transaction_matching_sltp', {
            tx_id: item.tx_id,
            pnl: GetPnl(item),
            sl_tp: sltp
        });
        if (response && response.data && response.data.valid == 1) {
            showSnackbar(response.data.messages, 'success');
            GetTradingPosition(getSessionAccount());
        } else {
            showSnackbar(response.data.messages, 'error');
        }
    }

    const [isOpenLogin, setIsOpenLogin] = useState(false);
    const togglePopupLogin = () => {
        setIsOpenLogin(!isOpenLogin);
    };

    const [changeChartTradeMobile, setChangeChartTradeMobile] = useState('Chart');
    const [isVisibleChart, setIsVisibleChart] = useState(true);
    const [isVisibleTrade, setIsVisibleTrade] = useState(true);
    const [isTradeShow, setIsTradeShow] = useState(true);
    const showChangeChartTradeMobile = (text) => () => {
        setChangeChartTradeMobile(text);
        if (text === "Chart") {
            setIsVisibleChart(true);
            setIsVisibleTrade(false);
            setIsTradeShow(true);
        }
        else {
            setIsVisibleChart(false);
            setIsVisibleTrade(true);
            setIsTradeShow(false);
        }
    };
    return (
        <Box component="main" sx={{ flexGrow: 1, width: '100%', backgroundColor: '#010002', minHeight: '70vh', height: 'auto' }}>
            <Box className="background-lauchapp">
                <Box className="content-upgrade version-web">
                    <Grid className="chart-trade-mobile" container spacing={0} sx={{ backgroundColor: '#2f2f2f', borderRadius: '10px', padding: '5px', marginBottom:'5px' }}>
                        <Grid size={{ xs: 6, md: 6 }} className={`button-visible-trading ${changeChartTradeMobile === 'Chart' ? 'active' : ''}`} onClick={showChangeChartTradeMobile('Chart')}>
                            Chart
                        </Grid>
                        <Grid size={{ xs: 6, md: 6 }} className={`button-visible-trading ${changeChartTradeMobile === 'Trade' ? 'active' : ''}`} onClick={showChangeChartTradeMobile('Trade')}>
                            Trade
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 8 }} className={` ${isVisibleChart ? 'visible' : 'invisible'} `}>
                            <div className='left-app text-white top-chart' style={{ marginBottom: '15px' }}>
                                <Grid className='text-dark-gray' container spacing={2} sx={{ fontSize: '12px' }}>
                                    <Grid size={{ xs: 1.7, md: 1.7, }}>Balance:</Grid>
                                    <Grid size={{ xs: 1.7, md: 1.7, }}>Equity:</Grid>
                                    {/* <Grid size={{ xs: 2, md: 2, }}>Available Liquidity:</Grid> */}
                                    <Grid size={{ xs: 1.7, md: 1.7, }}>Funding rate / 1h:</Grid>
                                    <Grid size={{ xs: 2.2, md: 2.2, }}>Open Interest (<span className='text-dark-green'>{longPercentOpenInterest}%</span>/<span className='text-red'>{shortPercentOpenInterest}%</span>)</Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>24h Volume</Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>Total loss left</Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>Daily loss left</Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ fontSize: '14px' }}>
                                    <Grid size={{ xs: 1.7, md: 1.7, }}>{formatCurrency(account?.current_balance ?? 0)} USDX</Grid>
                                    <Grid size={{ xs: 1.7, md: 1.7, }} style={{ color: equity < account?.current_balance ? 'red' : '#5bb974' }}>{formatCurrency(equity)} USDX</Grid>
                                    <Grid container spacing={1} size={{ xs: 1.7, md: 1.7, }}>
                                        <span className='text-dark-green'>
                                            <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative top-1 opacity-70"><path d="m529-488.59v5.67l-2.113-2.109-5.326 5.319-2.924-2.921-3.9 3.9-1.444-1.448 5.341-5.341 2.924 2.924 3.882-3.882-2.113-2.109z" fill="currentColor" transform="translate(-513.3 488.59)"></path></svg>
                                            &nbsp;{fundingRate}%
                                        </span>
                                    </Grid>
                                    <Grid container spacing={1} size={{ xs: 2.2, md: 2.2, }}>
                                        <span >
                                            <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative top-1 opacity-70"><path d="m529-488.59v5.67l-2.113-2.109-5.326 5.319-2.924-2.921-3.9 3.9-1.444-1.448 5.341-5.341 2.924 2.924 3.882-3.882-2.113-2.109z" fill="currentColor" transform="translate(-513.3 488.59)"></path></svg>
                                            {formatCurrency(longOpenInterest)}
                                        </span>
                                        <span >
                                            <svg height="9.856" viewBox="0 0 15.704 9.856" width="12" xmlns="http://www.w3.org/2000/svg" class="relative opacity-70"><path d="m0 0v5.67l2.113-2.11 5.326 5.32 2.924-2.921 3.9 3.9 1.437-1.451-5.337-5.341-2.924 2.924-3.882-3.882 2.113-2.109z" fill="currentColor" transform="matrix(-1 0 0 -1 15.704 9.856)"></path></svg>
                                            {formatCurrency(shortOpenInterest)}
                                        </span>
                                    </Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>{formatCurrency(volume)}</Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>{totalLossLeft} / {account ? (account?.balance * 1 / 10) : 0}</Grid>
                                    <Grid size={{ xs: 1.5, md: 1.5, }}>{dailyLossLeft}  / {account ? (account?.balance_ev_day * 5 / 100) : 0}</Grid>
                                </Grid>
                            </div>
                            <div className="App">
                                <div className="tradingview-widget-container" ref={container}>
                                    <div className="tradingview-widget-container__widget"></div>
                                </div>
                            </div>
                            <div className="App left-app" style={{ marginTop: '20px', padding: '0px' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="trading tab">
                                        <Tab sx={{ color: 'white' }} label="Position" {...a11yProps(0)} />
                                        <Tab sx={{ color: 'white' }} label="Order" {...a11yProps(1)} />
                                        <Tab sx={{ color: 'white' }} label="History" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={tabValue} index={0}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    {/* <TableCell align="right">Collateral</TableCell> */}
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    {/* <TableCell align="right">Liq. Price</TableCell> */}
                                                    <TableCell align="right">PnL</TableCell>
                                                    <TableCell align="center">Close Position</TableCell>
                                                    <TableCell align="center">SL/TP for position</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        {/* <TableCell align="right">{row.collateral}</TableCell> */}
                                                        <TableCell align="right">{row.entry_price}</TableCell>
                                                        <TableCell align="right">{row.mark_price}</TableCell>
                                                        {/* <TableCell align="right">{row.liquid_price}</TableCell> */}
                                                        <TableCell align="right"><span style={{ color: GetPnl(row) < 0 ? 'red' : 'green' }}>{GetPnl(row)}</span></TableCell>
                                                        <TableCell align="center">
                                                            <Button variant="outlined" onClick={() => { handleCloseTx(row, 0) }}>Market</Button>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                                                {(row.stop_loss || row.take_profit) && <span style={{ marginRight: '10%' }}>{row.stop_loss}/{row.take_profit}</span>}

                                                                {sessionStorage.getItem('account')
                                                                    && <EditIcon sx={{ fontSize: 15, cursor: 'pointer' }} onClick={() => { handleEditPopup(row) }} />}
                                                            </div>
                                                        </TableCell>
                                                        {sltpPopup && (<ChangeSltp
                                                            trading_id={sltpTradingId}
                                                            stop_loss={sltpStoplossValue}
                                                            take_profit={sltpTakeProfitValue}
                                                            handleClose={handleEditPopup}
                                                        />)}
                                                    </TableRow>

                                                ))}
                                                {(!trade_tx || trade_tx.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={1}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    {/* <TableCell align="right">Collateral</TableCell> */}
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    {/* <TableCell align="right">Liq. Price</TableCell> */}
                                                    <TableCell align="center">Close Position</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx_order.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        {/* <TableCell align="right">{row.collateral}</TableCell> */}
                                                        <TableCell align="right">{row.entry_price}</TableCell>
                                                        <TableCell align="right">{row.mark_price}</TableCell>
                                                        {/* <TableCell align="right">{row.liquid_price}</TableCell> */}
                                                        <TableCell align="center">
                                                            <Button variant="outlined" onClick={() => { handleCloseTx(row, 1) }}>Limit</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {(!trade_tx_order || trade_tx_order.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={2}>
                                    <TableContainer sx={{ padding: '0px', maxHeight: 500, overflow: 'auto' }} component={Paper}>
                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Order</TableCell>
                                                    <TableCell>Position</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell align="right">Net Value</TableCell>
                                                    <TableCell align="right">Volume</TableCell>
                                                    {/* <TableCell align="right">Collateral</TableCell> */}
                                                    <TableCell align="right">Entry Price</TableCell>
                                                    <TableCell align="right">Mark Price</TableCell>
                                                    {/* <TableCell align="right">Liq. Price</TableCell> */}
                                                    <TableCell align="right">PnL</TableCell>
                                                    <TableCell align="right">Exit Price</TableCell>
                                                    <TableCell align="right">Tx Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trade_tx_history.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell> {row.stt} </TableCell>
                                                        <TableCell> {row.transaction_type} </TableCell>
                                                        <TableCell> {row.trading_type} </TableCell>
                                                        <TableCell align="right">{row.pay_amt}</TableCell>
                                                        <TableCell align="right">{row.size}</TableCell>
                                                        {/* <TableCell align="right">{row.collateral}</TableCell> */}
                                                        <TableCell align="right">{row.entry_price}</TableCell>
                                                        <TableCell align="right">{row.mark_price}</TableCell>
                                                        {/* <TableCell align="right">{row.liquid_price}</TableCell> */}
                                                        <TableCell align="right"><span style={{ color: row.pnl_amt < 0 ? 'red' : 'green' }}>{row.pnl_amt}</span></TableCell>
                                                        <TableCell align="right">{row.exit_price}</TableCell>
                                                        <TableCell align="right">{row.tx_dt}</TableCell>
                                                    </TableRow>
                                                ))}
                                                {(!trade_tx_history || trade_tx_history.length == 0) &&
                                                    <TableRow
                                                        key='none'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10}> No data </TableCell>
                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CustomTabPanel>

                            </div>
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }} className={`${isTradeShow ? 'trade-mobile' : 'trade-mobile-show'} ${isVisibleTrade ? 'visible' : 'invisible'} `}>
                            <Box className='right-app'>
                                <Box>
                                    <Grid container spacing={0} sx={{ backgroundColor: '#2f2f2f', borderRadius: '10px', padding: '5px' }}>
                                        <Grid size={{ xs: 6, md: 6 }} className={`button-trading ${activeTrading === 'Long' ? 'active' : ''}`} onClick={showTrading(1, 1, 'Long')}>
                                            Long
                                        </Grid>
                                        <Grid size={{ xs: 6, md: 6 }} className={`button-trading ${activeTrading === 'Short' ? 'active' : ''}`} onClick={showTrading(2, 1, 'Short')}>
                                            Short
                                        </Grid>
                                        {/* <Grid size={{ xs: 6, md: 4 }} className={`button-trading ${activeTrading === 'Swap' ? 'active' : ''}`} onClick={showTrading(3, 1, 'Swap')}>
                                            Swap
                                        </Grid> */}
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid container spacing={2} sx={{ borderRadius: '10px', marginTop: '20px' }}>
                                        <Grid className={`button-market ${opTrading === 'Market' ? 'active' : ''}`} onClick={changeOpTrading('Market')}>
                                            Market
                                        </Grid>
                                        <Grid className={`button-market ${opTrading === 'Limit' ? 'active' : ''}`} onClick={changeOpTrading('Limit')}>
                                            Limit
                                        </Grid>
                                        {/* <Grid className={`button-market ${opTrading === 'Take' ? 'active' : ''}`} onClick={changeOpTrading('Take')}>
                                            Take profit- Stop loss
                                        </Grid> */}
                                    </Grid>
                                </Box>
                                <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={6}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>
                                                Pay: {payAmount && <span className='text-white'>{payAmount} USDX</span>}
                                            </Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Balance: <b style={{ color: 'white' }}>{account?.current_balance}</b> $USDX</Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input type='text' className='text-dark-gray input-market' value={payAmount} onChange={changePayAmount}></input>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <div className='usdx'><img style={{ width: '24px', height: '24px' }} src={`${process.env.PUBLIC_URL}/images/usdc-logo.png`} />
                                                &nbsp;USDX</div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '15px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={4}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>
                                                Leverage <b style={{ color: 'white' }}>{value}x</b>
                                            </Box>
                                        </Grid>
                                        {/* <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Price: <b style={{ color: 'white' }}>{price}$</b></Box>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input type='text' className='text-dark-gray input-market' value={value} onChange={handleLeverageChange}></input>

                                        </Grid>
                                    </Grid>
                                </Box>
                                {opTrading === "Limit" && <Box sx={{ backgroundColor: '#1c1c1c', padding: '20px', borderRadius: '20px', marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size={6}>
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Price</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Mark</Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="center" size={6}>
                                            <input type='text' className='text-dark-gray input-market' value={markPrice} onChange={handleMarkChange}></input>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <div className='usdx'><img style={{ width: '24px', height: '24px' }} src={`${process.env.PUBLIC_URL}/images/usdc-logo.png`} />
                                                &nbsp;USDX</div>
                                        </Grid>
                                    </Grid>
                                </Box>}
                                <Box>
                                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Laverage slider</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Switch
                                                checked={isOn}
                                                onChange={handleSwitchChange}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    {isOn && <Slider
                                        sx={{
                                            '& .MuiSlider-markLabel': {
                                                color: 'white',  // Color of the label text
                                            },
                                        }}
                                        size="medium"
                                        defaultValue={5}
                                        min={1}
                                        max={100}
                                        marks={marks}
                                        value={value}
                                        onChange={handleSliderChange} aria-label="Default" valueLabelDisplay="auto" />}
                                </Box>
                                <Box sx={{ marginTop: '20px' }}>
                                    <FormControlLabel
                                        sx={{ color: '#fff' }}
                                        label="TP/SL"
                                        control={<Checkbox checked={checked[0]} onChange={handleChangeCheckbox} />}
                                    />
                                </Box>
                                {checked &&
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Grid container spacing={2}>
                                            <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                                <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Stop Loss</Box>
                                            </Grid>
                                            <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                                <Box sx={{ fontSize: '20px', color: 'red' }} >{opStopLossPrice}</Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                            <Grid key={1} size={2} >
                                                <TextField id="outlined-basic" label="Price" variant="outlined" value={opStopLossPrice} onChange={handleStoplossInputChange}
                                                    sx={{
                                                        '& .MuiInputBase-root': {
                                                            color: 'white', // Text color
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: '#0091ff', // Border color
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: '#0091ff', // Hover border color
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#0091ff', // Focused border color
                                                            },
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#0091ff', // Label color
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#0091ff', // Focused label color
                                                        },
                                                    }} />
                                            </Grid>
                                            {listOpStopLoss.map(item => (
                                                <Grid key={item.id} size={2} className={`button-lost ${opStopLoss === item.value ? 'active' : ''}`} onClick={changeOpStopLoss(item.value)}>
                                                    {item.value}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                }

                                {checked && <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={2}>
                                        <Grid display="flex" justifyContent="left" alignItems="nt" size="auto">
                                            <Box className='text-dark-gray' sx={{ fontSize: '20px' }}>Take Profit</Box>
                                        </Grid>
                                        <Grid display="flex" justifyContent="right" alignItems="nt" size="grow">
                                            <Box className='text-dark-green' sx={{ fontSize: '20px' }} >{opTakeProfitPrice}</Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                                        <Grid key={1} size={2}>
                                            <TextField id="outlined-basic" label="Price" value={opTakeProfitPrice} variant="outlined" onChange={handleTakeProfitInputChange}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        color: 'white', // Text color
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: '#0091ff', // Border color
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: '#0091ff', // Hover border color
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: '#0091ff', // Focused border color
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: '#0091ff', // Label color
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#0091ff', // Focused label color
                                                    },
                                                }} />
                                        </Grid>
                                        {listOpTakeProfit.map(item => (
                                            <Grid key={item.id} size={2} className={`button-lost ${opTakeProfit === item.value ? 'active' : ''}`} onClick={changeOpTakeProfit(item.value)}>
                                                {item.value}
                                            </Grid>
                                        ))}
                                    </Grid>


                                </Box>}
                                <Box>
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px' }}>
                                            <Grid display="flex" justifyContent="center" alignItems="center" size="auto">
                                                <Box>
                                                    {/* <Box className='text-dark-gray' >Collateral In</Box> */}
                                                    <Box className='text-dark-gray' >Leverage</Box>
                                                    <Box className='text-dark-gray' >Entry Price</Box>
                                                    {/* <Box className='text-dark-gray' >Liq. Price</Box> */}
                                                    {/* <Box className='text-dark-gray' >Fees</Box> */}
                                                </Box>

                                            </Grid>
                                            <Grid container spacing={1.2} display="flex" justifyContent="right" alignItems="center" size="grow">
                                                <Box sx={{ textAlign: 'end' }}>
                                                    {/* <Box className='text-white'>USDX</Box> */}
                                                    <Box className='text-white'>{value}x</Box>
                                                    <Box className='text-white'>${entryPrice}</Box>
                                                    {/* <Box className='text-white'>${payAmount}</Box> */}
                                                    {/* <Box className='text-white'>$0.23</Box> */}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className="enter-amount pointer" >
                                        {session_account ?
                                            <Grid display="flex" justifyContent="center" alignItems="center" onClick={togglePopup}>
                                                <Box>
                                                    Enter an amount
                                                </Box>

                                            </Grid>
                                            :
                                            <Grid display="flex" justifyContent="center" alignItems="center" onClick={togglePopupLogin}>
                                                <Box>
                                                    Login
                                                </Box>


                                            </Grid>}
                                        {isOpenLogin && (
                                            <PopupLogin
                                                handleClose={togglePopupLogin}
                                                reload={reload}
                                            />
                                        )}
                                        {isOpen && (
                                            <Popup
                                                opTakeProfitPrice={opTakeProfitPrice}
                                                opStopLossPrice={opStopLossPrice}
                                                checked_sltp={checked}
                                                markPrice={Number(markPrice)}
                                                opTrading={opTrading}
                                                gasPrice={gasPrice}
                                                leverage={Number(value)}
                                                price={Number(price)}
                                                payAmount={Number(payAmount)}
                                                longAmount={Number(longAmount)}
                                                TradeLongAmount={Number((longAmount * price).toFixed(2))}
                                                activeTrading={activeTrading}
                                                entryPrice={entryPrice}
                                                handleClose={togglePopup}
                                            />
                                        )}

                                    </Box>
                                </Box>

                            </Box>

                            <Box className='right-app' sx={{ marginTop: '20px' }}>
                                <Box sx={{ borderBottom: '#a0a0a0 solid 1px', paddingBottom: '20px', color: '#fff', fontSize: '20px' }}>{activeTrading === "Long" ? "Long ETH" : "Short Link"}</Box>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px' }}>
                                        <Grid display="flex" justifyContent="center" alignItems="center" size="auto">
                                            <Box>
                                                <Box className='text-dark-gray' >Entry Price</Box>
                                                <Box className='text-dark-gray' >Exit Price</Box>
                                                {/* <Box className='text-dark-gray' >Borrow Fee</Box> */}
                                            </Box>

                                        </Grid>
                                        <Grid container spacing={1.2} display="flex" justifyContent="right" alignItems="center" size="grow">
                                            <Box sx={{ textAlign: 'end' }}>
                                                {/* <Box className='text-white'>${price}</Box> */}
                                                <Box className='text-white'>${entryPrice}</Box>
                                                <Box className='text-white'>0.0029% / 1h</Box>
                                                {/* <Box className='text-white'>$5,180,551.11</Box> */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box className='right-app' sx={{ marginTop: '20px' }}>
                                <Box sx={{ borderBottom: '#a0a0a0 solid 1px', paddingBottom: '20px', color: '#fff', fontSize: '20px' }}>Useful Links</Box>
                                <Box sx={{ marginTop: '20px' }}>
                                    <Grid container spacing={0} sx={{ fontSize: '20px', lineHeight: '35px' }}>
                                        <Grid display="flex" justifyContent="center" alignItems="center" size="auto">
                                            <Box>
                                                <Box className='text-white underline' >Trading guide</Box>
                                                <Box className='text-white underline' >Leaderboard</Box>
                                                <Box className='text-white underline' >Speed up page loading</Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="version-mobile">
                    Try desktop version on the PC
                    <Box className="laptop-icon" component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/computer-svg.png`}  />
                </Box>
            </Box>
            {/* <div className="overlay">
                <h2>Coming Soon ...</h2>
            </div> */}
        </Box>
    );
}
export default LauchApp