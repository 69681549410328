import {
    Box, Toolbar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Paper, Typography,
    Button
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./ref.style.css";
import BreadcrumbsNav from "../Breadcrumbs"
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

const Ref = () => {
    // function createData(transaction, metamask_ref, k5, k10, k20, k50, k100, sum) {
    //     return { transaction, metamask_ref, k5, k10, k20, k50, k100, sum };
    // }

    // const rows = [
    //     createData('15 % de participation aux bénéfices de la phase de défi', '5%', 1, 0, 0,0,0,'$2.5'),
    //     createData('15 % de participation aux bénéfices de la phase de défi', '5%', 1, 0, 0,0,0,'$2.5'),
    //     createData('15 % de participation aux bénéfices de la phase de défi', '5%', 1, 0, 0,0,0,'$2.5'),
    //     createData('15 % de participation aux bénéfices de la phase de défi', '5%', 1, 0, 0,0,0,'$2.5'),
    //     createData('15 % de participation aux bénéfices de la phase de défi', '5%', 1, 0, 0,0,0,'$2.5'),
    // ];
    const columns = [
        { field: 'transaction', headerName: 'Transaction Id in unichainscan', minWidth: 500 },
        { field: 'metamask_ref', headerName: 'Metamask wallet refed', minWidth: 200 },
        { field: 'k5', headerName: 'Package $5,000', minWidth: 150 },
        { field: 'k10', headerName: 'Package $10,000', minWidth: 150 },
        { field: 'k20', headerName: 'Package $20,000', minWidth: 150 },
        { field: 'k50', headerName: 'Package $50,000', minWidth: 150 },
        { field: 'k100', headerName: 'Package $100,000', minWidth: 150 },
        { field: 'sum', headerName: 'Total', minWidth: 150 },
     
    ];

    const rows = [
        {
            id: 1,
            transaction: '15 % de participation aux bénéfices de la phase de défi',
            metamask_ref: '5%',
            k5: '1',
            k10: '0',
            k20: '0',
            k50: '0',
            k100: '0',
            sum: '$2.5',
        },
        {
            id: 2,
            transaction: '15 % de participation aux bénéfices de la phase de défi',
            metamask_ref: '5%',
            k5: '1',
            k10: '0',
            k20: '0',
            k50: '0',
            k100: '0',
            sum: '$2.5',
        },
        {
            id: 3,
            transaction: '15 % de participation aux bénéfices de la phase de défi',
            metamask_ref: '5%',
            k5: '1',
            k10: '0',
            k20: '0',
            k50: '0',
            k100: '0',
            sum: '$2.5',
        },
        {
            id: 4,
            transaction: '15 % de participation aux bénéfices de la phase de défi',
            metamask_ref: '5%',
            k5: '1',
            k10: '0',
            k20: '0',
            k50: '0',
            k100: '0',
            sum: '$2.5',
        },
        {
            id: 5,
            transaction: '15 % de participation aux bénéfices de la phase de défi',
            metamask_ref: '5%',
            k5: '1',
            k10: '0',
            k20: '0',
            k50: '0',
            k100: '0',
            sum: '$2.5',
        },
    ];
    const paginationModel = { page: 0, pageSize: 5 };
    // State for managing the sort model

    const [dataTable, setDataTable] = useState([]);
    const [walletAddress, setWalletAddress] = useState("");
    useEffect(() => {
        const fullData = rows;
        setWalletAddress(localStorage.getItem('wallet_address'));
        setDataTable(fullData);
    }, []);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: '#F8F8F8' }}>
            <Toolbar />
            <BreadcrumbsNav />
            <br></br>
            <Grid container spacing={3}>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    <Typography variant="h5" gutterBottom>
                        Link Ref
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {walletAddress}
                    </Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 12, lg: 12 }}>
                    {/* <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>transaction id trên mạng basescan</TableCell>
                                    <TableCell align="right">Ví metamask đã ref</TableCell>
                                    <TableCell align="right">Gói $5,000</TableCell>
                                    <TableCell align="right">Gói $10,000</TableCell>
                                    <TableCell align="right">Gói $20,000</TableCell>
                                    <TableCell align="right">Gói $50,000</TableCell>
                                    <TableCell align="right">Gói $100,000</TableCell>
                                    <TableCell align="right">Tổng</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.transaction}
                                        </TableCell>
                                        <TableCell align="right">{row.metamask_ref}</TableCell>
                                        <TableCell align="right">{row.k5}</TableCell>
                                        <TableCell align="right">{row.k10}</TableCell>
                                        <TableCell align="right">{row.k20}</TableCell>
                                        <TableCell align="right">{row.k50}</TableCell>
                                        <TableCell align="right">{row.k100}</TableCell>
                                        <TableCell align="right">{row.sum}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                                <Paper sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={dataTable}
                            columns={columns}
                            initialState={{ pagination: { paginationModel } }}
                            pageSizeOptions={[5, 10, 20, 50, 100]}
                            sx={{ border: 0 }}
                        />
                    </Paper>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                </Grid>
                <Grid item size={{ xs: 12, md: 6, lg: 6 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                            <div className="ref-footer-item-left1">
                                <img style={{ width: '20px', height: '20px' }} src={`${process.env.PUBLIC_URL}/images/metamask-logo.png`} />
                            </div>
                            <div style={{ margin: '0px 5px' }}>MetaMask</div>
                            <div className="ref-footer-item-left2">{walletAddress}</div>
                        </div>
                        <div style={{ width: '30%' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '10px' }}>Total</div>
                                <div style={{ fontSize: '21px', fontWeight: 'bold' }}>(69.5 + 2.5) = $72</div>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%' }}></div>
                        <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                            <Button variant="contained"
                                sx={{ backgroundColor: '#1CA7EC', color: 'white', '&:hover': { backgroundColor: '#4cd3c2' }, width: '100%' }}>
                                Withdraw</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Ref
