import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { lazy } from 'react';
import Avatar from '@mui/material/Avatar';
import { BrowserProvider } from 'ethers';
import PopupLogin from "../component/login"; // Import the Popup component
import axios from 'axios';
import { useSnackbar } from './SnackbarProvider'; // Path to the provider
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid2';
import { baseTestnetChainId, baseTestnetChainParams } from "../constants/base_testnet_sepolia_chain_config"
import { baseChainId, baseChainParams } from "../constants/base_eth_chain_config"
import { sepoliaChainId, sepoliaChainParams } from "../constants/sepolia_eth_chain_config"
import { uniSepoliaChainId, uniSepoliaChainParams } from "../constants/unichain_sepolia_config"
import api from '../instance_api';
import api_no_auth from '../instance_api_no_authentication';

export default function PrimarySearchAppBar({ toogleReload }) {
  const showSnackbar = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [isLauchApp, setIsLauchApp] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userlogin, setUserlogin] = useState("");
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const LaunchProfile = () => {
    navigate("/profile");
  };

  // Function to connect wallet
  const connectWallet = async () => {
    if (window.ethereum) {
      const tempProvider = new BrowserProvider(window.ethereum);
      setProvider(tempProvider);

      // Request account access if needed
      const accounts = await tempProvider.send('eth_requestAccounts', []);
      setAccount(accounts[0]);
      localStorage.setItem('wallet_address', accounts[0]);

      api_no_auth.post('/api/wallet/post_v1_wallet', { wallet_address: accounts[0].toString() })
        .then(response => {
          if (response.data.valid) {
            showSnackbar('Connect wallet success', 'success');
            switchToBaseSepoliaChain();
          }
        })
        .catch(error => console.error(error));
      // Get the signer
      const tempSigner = tempProvider.getSigner();
      setSigner(tempSigner);

    } else {
      showSnackbar('MetaMask is not installed!', 'warning');
    }
  };

  // Function to disconnect wallet
  const disconnectWallet = () => {
    setProvider(null);
    setSigner(null);
    setAccount(null);
    if (localStorage.getItem('wallet_address')) {
      localStorage.removeItem('wallet_address');
    }
    showSnackbar('Wallet has been disconnected', 'info');
  };

  const setLogOut = async () => {
    await api.delete('/api/account/delete_v1_account_logout', {}, { withCredentials: true })
      .then(response => {
        AccountAction(0, sessionStorage.getItem('account'));
        sessionStorage.removeItem('account');
        window.location.reload();
        showSnackbar('Logout success', 'success');
      })
      .catch(error => console.error(error));
  }
  const AccountAction = (action, accountId) => {
    api_no_auth.post(`/api/account/post_v1_account_action`, { status: action, account_id: accountId })
      .then(response => {

      })
      .catch(error => console.error(error));
  };
  const setLogin = () => {
    const b_userLogin = sessionStorage.getItem('account');
    if (b_userLogin) {
      setIsLogin(true)
      setUserlogin(b_userLogin);
      AccountAction(1, b_userLogin);
    }
  }

  // Automatically connect if already connected
  useEffect(() => {
    checkIfWalletIsConnected();
    if (sessionStorage.getItem('current_chain')) {
      setCurrentChain(sessionStorage.getItem('current_chain'));
    }
  }, []);

  const checkIfWalletIsConnected = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    }

    let link = window.location.href;
    if (link.includes("lauchapp")) {
      setIsLauchApp(true);
    }
    setLogin();
  };

  const userId = 'primary-search-account-user';
  const menuId = 'primary-search-account-menu';
  const notiId = 'primary-search-account-noti';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      // id={menuId}
      // keepMounted
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right',
      // }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={LaunchProfile}>Profile</MenuItem>
      {userlogin && <MenuItem onClick={setLogOut}> Logout</MenuItem>}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const [isActive1, setIsActive1] = React.useState(false);
  const [isActive2, setIsActive2] = React.useState(false);
  const [isActive3, setIsActive3] = React.useState(false);
  const [isActive4, setIsActive4] = React.useState(false);
  const navigate = useNavigate();

  const CapitalUpgrade = (step) => () => {
    ActiveHref(step);
    navigate("capitalupgrade");
    setIsLauchApp(false);
  };
  const Evaluate = (step) => () => {
    ActiveHref(step);
    navigate("evaluationprocess");
    setIsLauchApp(false);
  };
  const Doc = (step) => () => {
    ActiveHref(step);
    navigate("doc");
    setIsLauchApp(false);
  };
  const ActiveHref = (step) => {
    setIsActive1(step === 1 ? true : false);
    setIsActive2(step === 2 ? true : false);
    setIsActive3(step === 3 ? true : false);
    setIsActive4(step === 4 ? true : false);
  }
  // const SomeComponent = lazy(ActiveHref(3))

  const Home = (step) => () => {
    navigate("/");
  };

  const LauchApp = (step) => () => {
    setIsLauchApp(true);
    checkIfWalletIsConnected();
    navigate("lauchapp");
  };

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const togglePopupLogin = () => {
    setIsOpenLogin(!isOpenLogin);
    setLogin();

  };



  async function switchToSepoliaChain() {
    handleClose();
    setCurrentChain('Sepolia Testnet');

    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Sepolia
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: sepoliaChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [sepoliaChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  async function switchToBaseChain() {
    handleClose();
    setCurrentChain('Base Mainnet');

    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Base
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: baseChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [baseChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  async function switchToBaseSepoliaChain() {
    handleClose();
    sessionStorage.setItem('current_chain', 'Base Sepolia Testnet');
    setCurrentChain('Base Sepolia Testnet');

    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Base
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: baseTestnetChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [baseTestnetChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  async function switchToUniSepoliaChain() {
    handleClose();
    setCurrentChain('Unichain Sepolia Testnet');

    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMask is required!');
      return;
    }

    try {
      // Request a chain switch to Base
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: uniSepoliaChainId }],
      });
    } catch (switchError) {
      // If the chain has not been added, try adding it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [uniSepoliaChainParams],
          });
        } catch (addError) {
          console.error('Error adding the chain:', addError);
        }
      } else {
        console.error('Error switching the chain:', switchError);
      }
    }
  }

  const [selectChain, setSelectChain] = React.useState(null);

  const handleClick = (event) => {
    setSelectChain(event.currentTarget);
  };

  const handleClose = () => {
    setSelectChain(null);
  };

  const open = Boolean(selectChain);
  const id = open ? 'simple-popover' : undefined;

  const [currentChain, setCurrentChain] = React.useState('Select chain');

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Để hiển thị cảnh báo khi đóng hoặc tải lại trang
      // event.preventDefault();
      // event.returnValue = '1231231'; // Đây là cách thiết lập thông báo trong một số trình duyệt
      AccountAction(0, sessionStorage.getItem('account'));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener khi component bị hủy
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMobileMenu((prev) => !prev);
  };

  return (
    <Box>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#020204', height: '80px', padding: '10px' }}>
        <Toolbar>
          <Box component="img" alt='X1k Image' src={`${process.env.PUBLIC_URL}/images/x1k_logo.png`} onClick={Home()} />
          <Box sx={{ display: { xs: "none", sm: "block" } }} className={`nav-header ${isActive1 ? 'active' : ''}`} onClick={Evaluate(1)}>
            <div>Evaluation process</div>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }} className={`nav-header ${isActive2 ? 'active' : ''}`} >
            <div>X1K Academy</div>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }} className={`nav-header ${isActive3 ? 'active' : ''}`} onClick={CapitalUpgrade(3)}>
            <div>Capital upgrade process</div>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }} className={`nav-header ${isActive4 ? 'active' : ''}`} onClick={Doc(4)}>
            <div>Docs</div>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box className="menu-mobile">
            <Button sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#0091ff', color: '#fff', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }} onClick={LauchApp()}>
              Launch app
            </Button>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#0091ff', color: '#fff', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }} onClick={LauchApp()}>
              Launch app
            </Button>
            {!account && <Button sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#ffffff', color: '#020204', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }}
              onClick={connectWallet}>
              Connect wallet
            </Button>}
            {account && !isLauchApp && <Button sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#ffffff', color: '#020204', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }}
              onClick={disconnectWallet}>
              Disconnect wallet
            </Button>}
            {account && isLauchApp && !isLogin && <Button sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#ffffff', color: '#020204', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }}
              onClick={togglePopupLogin}>
              Login
            </Button>}
            {isOpenLogin && (
              <PopupLogin
                handleClose={togglePopupLogin}
                reload={toogleReload}
              />
            )}
            <Button aria-describedby={id} variant="contained" sx={{ borderRadius: '30px', padding: '5px 20px', border: 'none', backgroundColor: '#0091ff', color: '#ffffff', cursor: 'pointer', fontWeight: 'Bold', marginRight: '20px' }}
              onClick={handleClick}>
              {currentChain}
            </Button>
            <Popover
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '10px', // Customize the border radius here
                },
              }}
              id={id}
              open={open}
              anchorEl={selectChain}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography sx={{ p: 2, cursor: 'pointer' }} onClick={switchToBaseSepoliaChain} className='chain'>Switch to Base Sepolia Chain</Typography>
              {/* <Typography sx={{ p: 2, cursor: 'pointer' }} onClick={switchToUniSepoliaChain} className='chain'>Switch to Unichain Sepolia Chain</Typography> */}
            </Popover>
            <IconButton
              size="large"
              edge="end"
              aria-label="notifications"
              aria-controls={notiId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              sx={{ borderRadius: '50px', padding: '10px 15px', border: 'none', backgroundColor: '#101012', color: '#ededed', marginRight: '20px' }}
            >
              <NotificationsIcon />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="menu"
              aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              sx={{ borderRadius: '50px', padding: '10px 15px', border: 'none', backgroundColor: '#101012', color: '#ededed', marginRight: '20px' }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={userId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ borderRadius: '50px', padding: '10px 15px', border: 'none', backgroundColor: '#101012', color: '#ededed' }}
            >
              {!account && <AccountCircle sx={{}} />}
              {account && <Avatar alt="Metamask wallet" src={`${process.env.PUBLIC_URL}/images/avatar.png`} sx={{ width: '20px', height: '20px' }} />}
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={toggleMenu}
              sx={{ color: '#6e6e6e' }}
            >
              <DensityMediumIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Slide direction="down" in={openMobileMenu} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "300px", // Chiều cao menu
            backgroundColor: "rgba(0, 0, 0, 0.9)", // Nền màu tối
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <Grid container spacing={2} sx={{marginTop:'20%'}}>
            <Grid size={12}>
              <Box className={`nav-header ${isActive1 ? 'active' : ''}`} onClick={Evaluate(1)}>
                <div>Evaluation process</div>
              </Box>
            </Grid>
            <Grid size={12}>
              <Box className={`nav-header ${isActive2 ? 'active' : ''}`} >
                <div>X1K Academy</div>
              </Box>
            </Grid>
            <Grid size={12}>
              <Box className={`nav-header ${isActive3 ? 'active' : ''}`} onClick={CapitalUpgrade(3)}>
                <div>Capital upgrade process</div>
              </Box>
            </Grid>
            <Grid size={12}>
              <Box className={`nav-header ${isActive4 ? 'active' : ''}`} onClick={Doc(4)}>
                <div>Docs</div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Slide>
      {renderMenu}
    </Box>
  );
}

